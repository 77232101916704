import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiEndpoints } from "../../utils/ApiEndPoints";

const TransportQuotesThunk = createAsyncThunk("TransportQuotes/TransportQuotesThunk", async (_, { rejectWithValue }) => {
    try {
        const response = await axios.get(ApiEndpoints.Quotes.TransportQuotes,
            {
                headers: {
                    "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
                }
            });
        return response.data?.data;
    } catch (error) {
        console.log("error", error);
        return rejectWithValue(error.response.data);
    }
});

const TransportQuotesSlice = createSlice({
    name: "TransportQuotes",
    initialState: {
        transportQuotesData: [],
        success: false,
        error: false,
        loading: false,
        errorMessage: "",
    },
    reducers: {
        ResetTransportQuotesData: (state, action) => {
            state.transportQuotesData = [];
            state.success = false;
            state.error = false;
            state.loading = false;
            state.errorMessage = "";
        },
    },
    extraReducers: {
        [TransportQuotesThunk.pending]: (state, action) => {
            state.loading = true;
            state.success = false;
            state.error = false;
        },
        [TransportQuotesThunk.fulfilled]: (state, action) => {
            state.transportQuotesData = action.payload;
            state.success = true;
            state.loading = false;
        },
        [TransportQuotesThunk.rejected]: (state, action) => {
            state.errorMessage = action.payload.message;
            state.error = true;
            state.loading = false;
            state.transportQuotesData = [];
        },
    },
});

export default TransportQuotesSlice.reducer;

export const { ResetTransportQuotesData } = TransportQuotesSlice.actions;

export { TransportQuotesThunk };
