const menu = [
  {
    heading: "Dashboards",
  },
  {
    icon: "list-index-fill",
    text: "Listings",
    link: "/all-listing",
  },
  {
    icon: "list-index",
    text: "Rental Products",
    link: "/rental_products",
  },
  {
    icon: "users-fill",
    text: "Users",
    link: "/users",
  },
  {
    icon: "package-fill",
    text: "Packages",
    link: "/package",
  },
  {
    icon: "package-fill",
    text: "Buying Category",
    link: "/buying_category",
  },
  {
    icon: "package",
    text: "Rental Category",
    link: "/rental_category",
  },

  {
    icon: "package-fill",
    text: "Advertisement",
    link: "/advertisement",
  },

  // {
  //   icon: "package-fill",
  //   text: "Version Controller",
  //   link: "/version-control",
  // },

  {
    icon: "file-fill",
    text: "Transport Quotes",
    link: "/transport-quote",
  },
  {
    icon: "file-text-fill",
    text: "Insurance Quotes",
    link: "/insurance-quote",
  },
  {
    icon: "money",
    text: "Finance Quotes",
    link: "/finance-quote",
  },

  {
    icon: "mail-fill",
    text: "Subscribed Email",
    link: "/subscribed-email",
  },
  {
    icon: "contact-fill",
    text: "Contact Us",
    link: "/contact-us",
  }
];
export default menu;
