import React, { useState, useEffect } from "react";

import { Card, Spinner } from "reactstrap";
import ReactPaginate from "react-paginate";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import { Button, Block, BlockBetween, BlockDes, BlockHead, BlockHeadContent, BlockTitle, Icon } from "../../components/Component";
import { SubscribedEmailThunk } from "../../redux/SubscribedEmail/GetAllSubscribedEmailSlice";

const SubscribedEmail = () => {
    const dispatch = useDispatch();
    const [onSearch, setonSearch] = useState(true);
    const [onSearchText, setSearchText] = useState("");

    const { loading, error, subscribedEmailData } = useSelector((state) => state.subscribedEmail.getAllSubscribedEmail)

    // ********************************** Search and Pagination start ***********************************//

    const subscriptionListPerPage = 10;
    const [allSubscriptionList, setAllSubscriptionList] = useState([]);
    const [pageNumber, setPageNumber] = useState(0);
    const pagesVisited = pageNumber * subscriptionListPerPage;
    const pageCount = Math.ceil(
        allSubscriptionList.filter((subscriptionList) => {
            if (onSearchText === "") {
                return subscriptionList;
            } else if (
                subscriptionList.email.toLowerCase().includes(onSearchText.toLowerCase())
            ) {
                return subscriptionList;
            }
            return false;
        }).length / subscriptionListPerPage
    );

    const displaySubscriptionList = allSubscriptionList
        .filter((subscriptionList) => {
            if (onSearchText === "") {
                return subscriptionList;
            } else if (
                subscriptionList.email.toLowerCase().includes(onSearchText.toLowerCase())
            ) {
                return subscriptionList;
            }
            return false;
        })
        .slice(pagesVisited, pagesVisited + subscriptionListPerPage);

    useEffect(() => {
        setAllSubscriptionList(subscribedEmailData);
    }, [subscribedEmailData]);
    const paginate = ({ selected }) => setPageNumber(selected);

    useEffect(() => {
        setPageNumber(0);
    }, [onSearchText]);

    // ********************************** Pagination End ***********************************//

    useEffect(() => {
        dispatch(SubscribedEmailThunk());
    }, [dispatch]);

    const toggle = () => setonSearch(!onSearch);

    return (
        <React.Fragment>
            <Head title="Finance Quote - Yellowish Admin"></Head>
            {loading ? <div className="d-flex justify-content-center align-items-center w-100" style={{ height: "80vh" }}>
                <Spinner />
            </div>
                : error ?
                    <div className="text-center">
                        <span className="text-silent">No data found</span>
                    </div>
                    : (

                        <Content>
                            <BlockHead size="sm">
                                <BlockBetween>
                                    <BlockHeadContent>
                                        <BlockTitle page>Subscribed Email</BlockTitle>
                                        <BlockDes className="text-soft">
                                            <p>You have total {subscribedEmailData?.length} subscribed emails.</p>
                                        </BlockDes>
                                    </BlockHeadContent>
                                </BlockBetween>
                            </BlockHead>

                            <Block>
                                <Card className="card-bordered card-stretch w-50">
                                    <div className="card-inner-group">
                                        <div className="card-inner">
                                            <div className="card-title-group">
                                                <div className="card-title">
                                                    <h5 className="title">Subscribed Emails List</h5>
                                                </div>
                                                <div className="card-tools mr-n1">
                                                    <ul className="btn-toolbar">
                                                        <li>
                                                            <Button onClick={toggle} className="btn-icon search-toggle toggle-search">
                                                                <Icon name="search"></Icon>
                                                            </Button>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className={`card-search search-wrap ${!onSearch ? "active" : ""}`}>
                                                    <div className="search-content">
                                                        <Button className="search-back btn-icon toggle-search" onClick={() => { setSearchText(""); toggle(); }}>
                                                            <Icon name="arrow-left"></Icon>
                                                        </Button>
                                                        <input type="text" className="form-control border-transparent form-focus-none" placeholder="Search" value={onSearchText}
                                                        />
                                                        <Button className="search-submit btn-icon">
                                                            <Icon name="search"></Icon>
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-inner p-0">
                                            <table className="table table-tranx">
                                                <thead>
                                                    <tr className="tb-tnx-head">
                                                        <th className="tb-tnx-id">
                                                            <span className="">#</span>
                                                        </th>
                                                        <th className="tb-tnx-info">
                                                            <span className="tb-tnx-desc d-none d-sm-inline-block">
                                                                <span>email</span>
                                                            </span>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {displaySubscriptionList?.map((subscriptionList, index) => {
                                                        return (
                                                            <tr className="tb-tnx-item">
                                                                <td className="tb-tnx-id" style={{ width: "200px" }}>
                                                                    <div >
                                                                        <span>{index + 1}</span>
                                                                    </div>
                                                                </td>
                                                                <td className="tb-tnx-info">
                                                                    <div className="tb-tnx-date" style={{ width: "300px" }}>
                                                                        <span className="date">{subscriptionList?.email}</span>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="card-inner">
                                            <ReactPaginate
                                                breakLabel="..."
                                                breakClassName="pagination-btns"
                                                onPageChange={(number) => paginate(number)}
                                                // onPageChange={handlePageClick}
                                                pageRangeDisplayed={3}
                                                pageCount={pageCount}
                                                previousLabel="<<  Prev "
                                                renderOnZeroPageCount={null}
                                                nextLabel="Next >>"
                                                previousClassName="pagination-next"
                                                nextClassName="pagination-next"
                                                activeClassName="pagination-active"
                                                pageClassName="pagination mt-0"
                                                pageLinkClassName="pagination-btns"
                                                containerClassName="pagination justify-content-start"
                                            />
                                        </div>
                                    </div>
                                </Card>
                            </Block>
                        </Content>
                    )
            }
        </React.Fragment>
    );
}

export default SubscribedEmail;
