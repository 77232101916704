import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiEndpoints } from "../../utils/ApiEndPoints";

const InsuranceQuotesThunk = createAsyncThunk("InsuranceQuotes/InsuranceQuotesThunk", async (_, { rejectWithValue }) => {
    try {
        const response = await axios.get(ApiEndpoints.Quotes.InsuranceQuotes,
            {
                headers: {
                    "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
                }
            });
        return response.data?.data;
    } catch (error) {
        console.log("error", error);
        return rejectWithValue(error.response.data);
    }
});

const InsuranceQuotesSlice = createSlice({
    name: "InsuranceQuotes",
    initialState: {
        insuranceQuotesData: [],
        success: false,
        error: false,
        loading: false,
        errorMessage: "",
    },
    reducers: {
        ResetInsuranceQuotesData: (state, action) => {
            state.insuranceQuotesData = [];
            state.success = false;
            state.error = false;
            state.loading = false;
            state.errorMessage = "";
        },
    },
    extraReducers: {
        [InsuranceQuotesThunk.pending]: (state, action) => {
            state.loading = true;
            state.success = false;
            state.error = false;
        },
        [InsuranceQuotesThunk.fulfilled]: (state, action) => {
            state.insuranceQuotesData = action.payload;
            state.success = true;
            state.loading = false;
        },
        [InsuranceQuotesThunk.rejected]: (state, action) => {
            state.errorMessage = action.payload.message;
            state.error = true;
            state.loading = false;
            state.insuranceQuotesData = [];
        },
    },
});

export default InsuranceQuotesSlice.reducer;

export const { ResetInsuranceQuotesData } = InsuranceQuotesSlice.actions;

export { InsuranceQuotesThunk };
