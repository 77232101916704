const baseUrl = "https://api.yellowish.co.za/api/admin";
// const baseUrl = "http://192.168.100.12:3000/api/admin";

// const baseUrl = process.env.REACT_APP_BASE_URL;

export const ApiEndpoints = {
    Login: `${baseUrl}/login`,

    Listing: {
        AddListing: `${baseUrl}/product/add`,
        GetAllListing: `${baseUrl}/products`,
        ListingDetail: `${baseUrl}/product/detail`,
        DeleteListing: `${baseUrl}/product/delete`,
        UpdateListingStatus: `${baseUrl}/product/change-product-status`,
        UpdateListing: `${baseUrl}/product/update`,
        productImageReOrder: `${baseUrl}/product/image-reorder`
    },
    User: {
        GetAllUser: `${baseUrl}/user/list`,
        AddUser: `${baseUrl}/user/add`,
        ChangeUserPassword: `${baseUrl}/user/change-password`,
        GetUserListing: `${baseUrl}/user/product/list`,

        GetUserPackage: `${baseUrl}/user/package/list`,
        AddUserPackage: `${baseUrl}/user/package/add`,
        DeleteUserPackage: `${baseUrl}/user/package/delete`,
        ResubscribeUserPackage: `${baseUrl}/user/package/renew`,
        UpdateUserPackageStatus: `${baseUrl}/user/package/update`,

        AddUserListing: `${baseUrl}/user/product/add`,

        GetUserRentalProduct: `${baseUrl}/user/product/renting/list`,
        AddUserRentalProduct: `${baseUrl}/user/product/renting/add`
    },
    Category: {
        GetAllCategory: `${baseUrl}/category/list`,
        AddCategory: `${baseUrl}/category/add`,
        UpdateCategory: `${baseUrl}/category/update`
    },
    RentalProduct: {
        GetAllRentalProducts: `${baseUrl}/products/renting`,
        AddRentalProduct: `${baseUrl}/product/renting/add`,
        UpdateRentalProductDetail: `${baseUrl}/product/renting/update`,
        RentalProductDetail: `${baseUrl}/product/renting/detail`,
        UpdateRentalProductStatus: `${baseUrl}/product/renting/change-product-status`,
        DeleteRentalProduct: `${baseUrl}/product/renting/delete`,
    },
    RentalCategory: {
        GetAllRentalCategory: `${baseUrl}/category/renting/list`,
        AddRentalCategory: `${baseUrl}/category/renting/add`,
        UpdateRentalCategory: `${baseUrl}/category/renting/update`,
    },
    Package: {
        GetAllPackage: `${baseUrl}/package/list`,
        AddPackage: `${baseUrl}/package/add`,
        UpdatePackageDetail: `${baseUrl}/package/update`
    },
    Quotes: {
        FinanceQuotes: `${baseUrl}/quotes/finance`,
        TransportQuotes: `${baseUrl}/quotes/transportation`,
        InsuranceQuotes: `${baseUrl}/quotes/insurance`
    },
    ContactUs: {
        GetContactUsForm: `${baseUrl}/contact-us/list`,
    },
    SubscribedEmail: {
        GetAllSubscribedEmail: `${baseUrl}/news-letter/list`
    },
    Advertisement: {
        GetWebPages: `${baseUrl}/webpage/list`,
        GetAllAdvertisement: `${baseUrl}/advertisement/list`,
        AddAdvertisement: `${baseUrl}/advertisement/add`,
        DeleteAdvertisement: `${baseUrl}/advertisement/delete`,
        UpdateAdvertisement: `${baseUrl}/advertisement/update`,
        AdsImagesReOrder: `${baseUrl}/advertisement/reorder`
    }
};
