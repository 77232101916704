import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiEndpoints } from "../../utils/ApiEndPoints";

const UpdateUserPackageStatusThunk = createAsyncThunk("UpdateUserPackageStatus/UpdateUserPackageStatusThunk", async ({ user_package_id }, { rejectWithValue }) => {
    try {
        const response = await axios.put(`${ApiEndpoints.User.UpdateUserPackageStatus}`,
            { package_id: user_package_id },
            {
                headers: {
                    "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
                }
            });
        return response.data;
    } catch (error) {
        console.log("error", error);
        const errorMessage = error?.response?.data?.message || "Something went wrong!";
        return rejectWithValue(errorMessage);
    }
});

const UpdateUserPackageStatusSlice = createSlice({
    name: "UpdateUserPackageStatus",
    initialState: {
        UpdateUserPackageStatusData: {},
        success: false,
        error: false,
        loading: false,
        errorMessage: "",
    },
    reducers: {
        ResetUpdateUserPackageStatusData: (state, action) => {
            state.UpdateUserPackageStatusData = {};
            state.success = false;
            state.error = false;
            state.loading = false;
            state.errorMessage = "";
        },
    },
    extraReducers: {
        [UpdateUserPackageStatusThunk.pending]: (state, action) => {
            state.loading = true;
            state.success = false;
            state.error = false;
        },
        [UpdateUserPackageStatusThunk.fulfilled]: (state, action) => {
            state.UpdateUserPackageStatusData = action.payload;
            state.success = true;
            state.loading = false;
        },
        [UpdateUserPackageStatusThunk.rejected]: (state, action) => {
            state.errorMessage = action.payload;
            state.error = true;
            state.loading = false;
            state.UpdateUserPackageStatusData = {};
        },
    },
});

export default UpdateUserPackageStatusSlice.reducer;

export const { ResetUpdateUserPackageStatusData } = UpdateUserPackageStatusSlice.actions;

export { UpdateUserPackageStatusThunk };
