import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiEndpoints } from "../../utils/ApiEndPoints";

const AddPackageThunk = createAsyncThunk("AddPackage/AddPackageThunk", async ({ name, listings, validity, price }, { rejectWithValue }) => {
    try {
        const response = await axios.post(ApiEndpoints.Package.AddPackage,
            { name, listings, validity, price },
            {
                headers: {
                    "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
                }
            });
        return response.data;
    } catch (error) {
        console.log("error", error);
        return rejectWithValue(error.response.data);
    }
});

const AddPackageSlice = createSlice({
    name: "AddPackage",
    initialState: {
        addPackageData: {},
        success: false,
        error: false,
        loading: false,
        errorMessage: "",
    },
    reducers: {
        ResetAddPackageData: (state, action) => {
            state.addPackageData = {};
            state.success = false;
            state.error = false;
            state.loading = false;
            state.errorMessage = "";
        },
    },
    extraReducers: {
        [AddPackageThunk.pending]: (state, action) => {
            state.loading = true;
            state.success = false;
            state.error = false;
        },
        [AddPackageThunk.fulfilled]: (state, action) => {
            state.addPackageData = action.payload;
            state.success = true;
            state.loading = false;
        },
        [AddPackageThunk.rejected]: (state, action) => {
            state.errorMessage = action.payload.message;
            state.error = true;
            state.loading = false;
            state.addPackageData = {};
        },
    },
});

export default AddPackageSlice.reducer;

export const { ResetAddPackageData } = AddPackageSlice.actions;

export { AddPackageThunk };
