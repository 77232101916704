import React from 'react'
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { Col, Modal, ModalBody, ModalHeader, Row, Spinner } from 'reactstrap';
import { RentalProductDetailThunk } from '../../../../redux/RentalProduct/RentalProductDetailSlice';

const ViewUserRentalDetailModal = ({ openModal, setOpenModal, product_id }) => {
    const dispatch = useDispatch();

    const { loading, rentalProductData } = useSelector((state) => state.rentalProducts.rentalProductDetail);

    useEffect(() => {
        dispatch(RentalProductDetailThunk({ rentalProductId: product_id }));
    }, [dispatch, product_id])

    return (
        <React.Fragment>
            <Modal
                isOpen={openModal}
                toggle={() => setOpenModal({ LessonDetail: false })}
                className="modal-md"
            >
                {loading ? (
                    <div className="d-flex justify-content-center align-items-center" style={{ height: "70vh" }}>
                        <Spinner />
                    </div>
                ) : (
                    <>
                        <ModalHeader
                            className={`${rentalProductData?.data?.status &&
                                (rentalProductData?.data?.approved !== true
                                    ? "fc-event-success"
                                    : "fc-event-primary")
                                }`}
                            toggle={() => setOpenModal({ LessonDetail: false })}
                        >
                            Listing Detail
                        </ModalHeader>
                        <ModalBody>
                            <Row className="gy-3 py-1">
                                {rentalProductData?.data?.product_id &&
                                    <Col sm="6">
                                        <h6 className="overline-title">Product Id</h6>
                                        <p id="preview-event-start">
                                            {rentalProductData?.data?.product_id}
                                        </p>
                                    </Col>
                                }
                                {rentalProductData?.data?.content &&
                                    <Col sm="6" id="preview-event-end-check">
                                        <h6 className="overline-title">Content</h6>
                                        <p id="preview-event-end">
                                            {rentalProductData?.data?.content}
                                        </p>
                                    </Col>
                                }
                                {rentalProductData?.data?.make &&
                                    <Col sm="6" id="preview-event-description-check">
                                        <h6 className="overline-title">Make</h6>
                                        <p id="preview-event-description">
                                            {rentalProductData?.data?.make}
                                        </p>
                                    </Col>
                                }
                                {rentalProductData?.data?.Location &&
                                    <Col sm="6" id="preview-event-description-check">
                                        <h6 className="overline-title">Machine Location</h6>
                                        <p id="preview-event-description">
                                            {rentalProductData?.data?.Location?.name}
                                        </p>
                                    </Col>
                                }
                                {rentalProductData?.data?.model &&
                                    <Col sm="6" id="preview-event-description-check">
                                        <h6 className="overline-title">model</h6>
                                        <p id="preview-event-description">
                                            {rentalProductData?.data?.model}
                                        </p>
                                    </Col>
                                }
                                {rentalProductData?.data?.price &&
                                    <Col sm="6" id="preview-event-description-check">
                                        <h6 className="overline-title">price</h6>
                                        <p id="preview-event-description">
                                            {rentalProductData?.data?.price}
                                        </p>
                                    </Col>
                                }
                                {/* {listingData?.data?.engine_type &&
                                    <Col sm="6" id="preview-event-description-check">
                                        <h6 className="overline-title">engine type</h6>
                                        <p id="preview-event-description">
                                            {listingData?.data?.engine_type}
                                        </p>
                                    </Col>
                                } */}

                                {rentalProductData?.data?.mileage &&
                                    <Col sm="6" id="preview-event-description-check">
                                        <h6 className="overline-title">mileage</h6>
                                        <p id="preview-event-description">
                                            {rentalProductData?.data?.mileage}
                                        </p>
                                    </Col>
                                }
                                {rentalProductData?.data?.listing_type &&
                                    <Col sm="6" id="preview-event-description-check">
                                        <h6 className="overline-title">listing type</h6>
                                        <p id="preview-event-description">
                                            {rentalProductData?.data?.listing_type}
                                        </p>
                                    </Col>
                                }
                                {rentalProductData?.data?.Category &&
                                    <Col sm="6" id="preview-event-description-check">
                                        <h6 className="overline-title">Category Name</h6>
                                        <p id="preview-event-description">
                                            {rentalProductData?.data?.Category?.name}
                                        </p>
                                    </Col>
                                }
                                {/* {listingData?.data?.fuel_type &&
                                    <Col sm="6" id="preview-event-description-check">
                                        <h6 className="overline-title">fuel type</h6>
                                        <p id="preview-event-description">
                                            {listingData?.data?.fuel_type}
                                        </p>
                                    </Col>
                                } */}
                                {rentalProductData?.data?.year &&
                                    <Col sm="6" id="preview-event-description-check">
                                        <h6 className="overline-title">year</h6>
                                        <p id="preview-event-description">
                                            {rentalProductData?.data?.year}
                                        </p>
                                    </Col>
                                }
                                {rentalProductData?.data?.createdAt &&
                                    <Col sm="6" id="preview-event-description-check">
                                        <h6 className="overline-title">createdAt</h6>
                                        <p id="preview-event-description">
                                            {rentalProductData?.data?.createdAt}
                                        </p>
                                    </Col>
                                }
                                {rentalProductData?.data?.description &&
                                    <Col sm="12" id="preview-event-description-check">
                                        <h6 className="overline-title">description</h6>
                                        <p id="preview-event-description">
                                            {rentalProductData?.data?.description}
                                        </p>
                                    </Col>
                                }

                            </Row>
                        </ModalBody>
                    </>
                )}
            </Modal>

        </React.Fragment>
    )
}

export default ViewUserRentalDetailModal;