import React, { Suspense, useLayoutEffect } from "react";
import { Switch, Route } from "react-router-dom";
import { RedirectAs404 } from "../utils/Utils";

import AllListing from "../pages/Listing";
import Users from "../pages/Users";
import UserDetail from "../pages/Users/UserDetail";
import FinanceQuote from "../pages/FinanceQuote";
import InsuranceQuote from "../pages/InsuranceQuote";
import TransportQuote from "../pages/TransportQuote";
import Packages from "../pages/Packages";
import Category from "../pages/BuyingCategory";
import ContactUs from "../pages/ContactUs";
import SubscribedEmail from "../pages/SubscribedEmail";
import Advertisement from "../pages/Advertisement";
import RentalCategory from "../pages/RentalCategory";
import RentalProducts from "../pages/RentalProducts";

const Pages = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <Suspense fallback={<div />}>
      <Switch>
        {/*Panel */}

        { /*  *****************************  Listing Routes *****************************************  */}
        <Route exact path={`/all-listing`} component={AllListing} />
        <Route exact path={`/`} component={AllListing}></Route>

        { /*  *****************************  Rental Products Routes *****************************************  */}
        <Route exact path={`/rental_products`} component={RentalProducts} />

        { /*  *****************************  User Routes *****************************************  */}
        <Route exact path={`/users`} component={Users} />
        <Route exact path={`/users/:id`} component={UserDetail} />

        { /*  *****************************  Quote Routes *****************************************  */}
        <Route exact path={`/finance-quote`} component={FinanceQuote} />
        <Route exact path={`/insurance-quote`} component={InsuranceQuote} />
        <Route exact path={`/transport-quote`} component={TransportQuote} />

        { /*  *****************************  Package Routes *****************************************  */}
        <Route exact path={`/package`} component={Packages} />

        { /*  *****************************  Buying Category Routes *****************************************  */}
        <Route exact path={`/buying_category`} component={Category} />

        { /*  *****************************  Rental Category Routes *****************************************  */}
        <Route exact path={`/rental_category`} component={RentalCategory} />

        { /*  *****************************  ContactUS Routes *****************************************  */}
        <Route exact path={`/contact-us`} component={ContactUs} />

        { /*  *****************************  Advertisement Routes *****************************************  */}
        <Route exact path={`/advertisement`} component={Advertisement} />

        { /*  *****************************  Subscribed Emails Routes *****************************************  */}
        <Route exact path={`/subscribed-email`} component={SubscribedEmail} />

        <Route component={RedirectAs404}></Route>
      </Switch>
    </Suspense>
  );
};
export default Pages;
