import React, { useState, useEffect } from "react";

import { UncontrolledDropdown, DropdownMenu, DropdownToggle, Card, DropdownItem, Spinner } from "reactstrap";
import ReactPaginate from "react-paginate";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import { Button, Block, BlockBetween, BlockDes, BlockHead, BlockHeadContent, BlockTitle, Icon } from "../../components/Component";
import { GetAllListingThunk } from "../../redux/listing/GetAllListingSlice";
import { FinanceQuotesThunk } from "../../redux/quotes/FinanceQuotesSlice";

const FinanceQuote = () => {
    const dispatch = useDispatch();
    const [onSearch, setonSearch] = useState(true);
    const [onSearchText, setSearchText] = useState("");
    const [modal, setModal] = useState({
        add: false,
        updateUserPassword: false,
        deleteUser: false,
    });

    const { loading, success, error, errorMessage, financeQuotesData } = useSelector((state) => state.quote.financeQuotes)

    // ********************************** Search and Pagination start ***********************************//

    const listingsPerPage = 10;
    const [allFinanceQuotes, setFinanceQuotes] = useState([]);
    const [pageNumber, setPageNumber] = useState(0);
    const pagesVisited = pageNumber * listingsPerPage;
    const pageCount = Math.ceil(
        allFinanceQuotes.filter((listing) => {
            if (onSearchText === "") {
                return listing;
            } else if (
                listing.fullName.toLowerCase().includes(onSearchText.toLowerCase()) ||
                listing.phoneNumber.toLowerCase().includes(onSearchText.toLowerCase()) ||
                listing.type.toLowerCase().includes(onSearchText.toLowerCase())
            ) {
                return listing;
            }
            return false;
        }).length / listingsPerPage
    );

    const displayListing = allFinanceQuotes
        .filter((listing) => {
            if (onSearchText === "") {
                return listing;
            } else if (
                listing.fullName.toLowerCase().includes(onSearchText.toLowerCase()) ||
                listing.phoneNumber.toLowerCase().includes(onSearchText.toLowerCase()) ||
                listing.type.toLowerCase().includes(onSearchText.toLowerCase())
            ) {
                return listing;
            }
            return false;
        })
        .slice(pagesVisited, pagesVisited + listingsPerPage);

    useEffect(() => {
        setFinanceQuotes(financeQuotesData);
    }, [financeQuotesData]);
    const paginate = ({ selected }) => setPageNumber(selected);

    useEffect(() => {
        setPageNumber(0);
    }, [onSearchText]);

    // ********************************** Pagination End ***********************************//

    useEffect(() => {
        dispatch(FinanceQuotesThunk());
    }, [dispatch]);

    const toggle = () => setonSearch(!onSearch);

    return (
        <React.Fragment>
            <Head title="Finance Quote - Yellowish Admin"></Head>
            {loading ? <div className="d-flex justify-content-center align-items-center w-100" style={{ height: "80vh" }}>
                <Spinner />
            </div>
                : error ?
                    <div className="text-center">
                        <span className="text-silent">No data found</span>
                    </div>
                    : (

                        <Content>
                            <BlockHead size="sm">
                                <BlockBetween>
                                    <BlockHeadContent>
                                        <BlockTitle page>Finance Quote</BlockTitle>
                                        <BlockDes className="text-soft">
                                            <p>You have total { } quotes.</p>
                                        </BlockDes>
                                    </BlockHeadContent>
                                </BlockBetween>
                            </BlockHead>

                            <Block>
                                <Card className="card-bordered card-stretch">
                                    <div className="card-inner-group">
                                        <div className="card-inner">
                                            <div className="card-title-group">
                                                <div className="card-title">
                                                    <h5 className="title">All Finance Quotes</h5>
                                                </div>
                                                <div className="card-tools mr-n1">
                                                    <ul className="btn-toolbar">
                                                        <li>
                                                            <Button onClick={toggle} className="btn-icon search-toggle toggle-search">
                                                                <Icon name="search"></Icon>
                                                            </Button>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className={`card-search search-wrap ${!onSearch ? "active" : ""}`}>
                                                    <div className="search-content">
                                                        <Button className="search-back btn-icon toggle-search" onClick={() => { setSearchText(""); toggle(); }}>
                                                            <Icon name="arrow-left"></Icon>
                                                        </Button>
                                                        <input type="text" className="form-control border-transparent form-focus-none" placeholder="Search" value={onSearchText}
                                                        />
                                                        <Button className="search-submit btn-icon">
                                                            <Icon name="search"></Icon>
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-inner p-0">
                                            <table className="table table-tranx">
                                                <thead>
                                                    <tr className="tb-tnx-head">
                                                        <th className="tb-tnx-id">
                                                            <span className="">#</span>
                                                        </th>
                                                        <th className="tb-tnx-info">
                                                            <span className="tb-tnx-desc d-none d-sm-inline-block">
                                                                <span>Name</span>
                                                            </span>
                                                            <span className="tb-tnx-date d-md-inline-block d-none">
                                                                <span className="d-none d-md-block">
                                                                    <span>Email</span>
                                                                    <span>Manufacturer</span>
                                                                </span>
                                                            </span>
                                                        </th>
                                                        <th className="tb-tnx-amount is-alt">
                                                            <span className="tb-tnx-total">Model</span>
                                                            <span className="tb-tnx-status d-none d-md-inline-block">Year</span>
                                                        </th>
                                                        {/* <th className="tb-tnx-action">
                                                            <span>Action</span>
                                                        </th> */}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {displayListing?.map((financeQuoteData, index) => {
                                                        return (
                                                            <tr className="tb-tnx-item">
                                                                <td className="tb-tnx-id">
                                                                    <div >
                                                                        <span>{index + 1}</span>
                                                                    </div>
                                                                </td>
                                                                <td className="tb-tnx-info">
                                                                    <div className="tb-tnx-desc">
                                                                        <span className="title">{financeQuoteData?.name}</span>
                                                                    </div>
                                                                    <div className="tb-tnx-date">
                                                                        <span className="date">{financeQuoteData?.email}</span>
                                                                        <span className="date">{financeQuoteData?.manufacturer}</span>
                                                                    </div>
                                                                </td>
                                                                <td className="tb-tnx-amount is-alt">
                                                                    <div className="tb-tnx-total">
                                                                        <span className="amount">{financeQuoteData?.model}</span>
                                                                    </div>
                                                                    <div className="tb-tnx-status">
                                                                        {financeQuoteData?.year}
                                                                    </div>
                                                                </td>
                                                                {/* <td className="tb-tnx-action">
                                                                    <UncontrolledDropdown>
                                                                        <DropdownToggle tag="a" className="text-soft dropdown-toggle btn btn-icon btn-trigger">
                                                                            <Icon name="more-h"></Icon>
                                                                        </DropdownToggle>
                                                                        <DropdownMenu right>
                                                                            <ul className="link-list-plain">
                                                                               
                                                                            </ul>
                                                                        </DropdownMenu>
                                                                    </UncontrolledDropdown>
                                                                </td> */}
                                                            </tr>
                                                        )
                                                    })}

                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="card-inner">
                                            <ReactPaginate
                                                breakLabel="..."
                                                breakClassName="pagination-btns"
                                                onPageChange={(number) => paginate(number)}
                                                // onPageChange={handlePageClick}
                                                pageRangeDisplayed={3}
                                                pageCount={pageCount}
                                                previousLabel="<<  Prev "
                                                renderOnZeroPageCount={null}
                                                nextLabel="Next >>"
                                                previousClassName="pagination-next"
                                                nextClassName="pagination-next"
                                                activeClassName="pagination-active"
                                                pageClassName="pagination mt-0"
                                                pageLinkClassName="pagination-btns"
                                                containerClassName="pagination justify-content-start"
                                            />
                                            {/* <div className="text-center">
                                    <span className="text-silent">No data found</span>
                                </div> */}
                                        </div>
                                    </div>
                                </Card>
                            </Block>
                        </Content>
                    )
            }
        </React.Fragment>
    );
}

export default FinanceQuote