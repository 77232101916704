import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiEndpoints } from "../../utils/ApiEndPoints";

const AddUserRentalProductThunk = createAsyncThunk("AddUserRentalProduct/AddUserRentalProductThunk", async ({ user_id, package_id, title, machine_location, content, price, images, year, make, model, machine_type, hours, mileage, service_history, serial_number }, { rejectWithValue }) => {
    const formData = new FormData();
    formData.append("title", title);
    formData.append("user_id", user_id);
    formData.append("description", content);
    formData.append("price", price ? price : "POA");
    formData.append("year", year);
    formData.append("make", make);
    formData.append("model", model);
    formData.append("machine_type", machine_type);
    formData.append("machine_location", machine_location);
    formData.append("hours", hours);
    formData.append("mileage", mileage);
    formData.append("service_history", service_history);
    formData.append("serial_number", serial_number);
    formData.append("package_id", package_id);
    for (let i = 0; i < images.length; i++) {
        formData.append("products", images[i].file);
    }

    try {
        const response = await axios.post(ApiEndpoints.User.AddUserRentalProduct, formData,
            {
                headers: {
                    "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
                }
            });
        return response.data;
    } catch (error) {
        console.log("error", error);
        const errorMessage = error.response.data.message ? error.response.data.message : "Something Went Wrong!";
        return rejectWithValue(errorMessage);
    }
});

const AddUserRentalProductSlice = createSlice({
    name: "AddUserRentalProduct",
    initialState: {
        addUserRentalProductData: {},
        success: false,
        error: false,
        loading: false,
        errorMessage: "",
    },
    reducers: {
        ResetAddUserRentalProductData: (state, action) => {
            state.addUserRentalProductData = {};
            state.success = false;
            state.error = false;
            state.loading = false;
            state.errorMessage = "";
        },
    },
    extraReducers: {
        [AddUserRentalProductThunk.pending]: (state, action) => {
            state.loading = true;
            state.success = false;
            state.error = false;
        },
        [AddUserRentalProductThunk.fulfilled]: (state, action) => {
            state.addUserRentalProductData = action.payload;
            state.success = true;
            state.loading = false;
        },
        [AddUserRentalProductThunk.rejected]: (state, action) => {
            state.errorMessage = action.payload;
            state.error = true;
            state.loading = false;
            state.addUserRentalProductData = {};
        },
    },
});

export default AddUserRentalProductSlice.reducer;

export const { ResetAddUserRentalProductData } = AddUserRentalProductSlice.actions;

export { AddUserRentalProductThunk };
