import React, { useState, useEffect } from "react";

import { UncontrolledDropdown, DropdownMenu, DropdownToggle, Card, DropdownItem, Spinner } from "reactstrap";
import ReactPaginate from "react-paginate";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import { Button, Block, BlockBetween, BlockDes, BlockHead, BlockHeadContent, BlockTitle, Icon } from "../../components/Component";
import { GetAllListingThunk } from "../../redux/listing/GetAllListingSlice";
import AddUserModal from "./AddUserModal";
import UpdateUserPasswordModal from "./UpdateUserPasswordModal";
import { GetAllUserThunk } from "../../redux/user/GetAllUsersSlice";
import { GetUserProfileThunk } from "../../redux/user/UserProfileSlice";
import DeleteUserModal from "./DeleteUserModal";


const Users = () => {
    const dispatch = useDispatch();
    const [onSearch, setonSearch] = useState(true);
    const [onSearchText, setSearchText] = useState("");
    const [modal, setModal] = useState({
        add: false,
        updateUserPassword: false,
        deleteUser: false,
    });

    const { loading, success, error, errorMessage, allUserData } = useSelector((state) => state.user.GetAllUsers)

    // ********************************** Search and Pagination start ***********************************//

    const UsersPerPage = 10;
    const [allUser, setListing] = useState([]);
    const [pageNumber, setPageNumber] = useState(0);
    const [userEmail, setUserEmail] = useState("");
    const pagesVisited = pageNumber * UsersPerPage;
    const pageCount = Math.ceil(
        allUser.filter((userData) => {
            if (onSearchText === "") {
                return userData;
            } else if (
                userData.username.toLowerCase().includes(onSearchText.toLowerCase()) ||
                userData.email.toLowerCase().includes(onSearchText.toLowerCase())
            ) {
                return userData;
            }
            return false;
        }).length / UsersPerPage
    );

    const displayListing = allUser
        .filter((userData) => {
            if (onSearchText === "") {
                return userData;
            } else if (
                userData.username.toLowerCase().includes(onSearchText.toLowerCase()) ||
                userData.email.toLowerCase().includes(onSearchText.toLowerCase())
            ) {
                return userData;
            }
            return false;
        })
        .slice(pagesVisited, pagesVisited + UsersPerPage);

    useEffect(() => {
        setListing(allUserData);
    }, [allUserData]);
    const paginate = ({ selected }) => setPageNumber(selected);

    useEffect(() => {
        setPageNumber(0);
    }, [onSearchText]);

    // ********************************** Pagination End ***********************************//

    useEffect(() => {
        dispatch(GetAllUserThunk());
    }, [dispatch]);

    const toggle = () => setonSearch(!onSearch);

    return (
        <React.Fragment>
            <Head title="Users - Yellowish Admin"></Head>
            <Content>
                <BlockHead size="sm">
                    <BlockBetween>
                        <BlockHeadContent>
                            <BlockTitle page>Users</BlockTitle>
                            <BlockDes className="text-soft">
                                <p>You have total {allUserData?.length} users.</p>
                            </BlockDes>
                        </BlockHeadContent>
                        <BlockHeadContent>
                            <ul className="nk-block-tools g-3">
                                <li>
                                    <Button color="primary" onClick={() => setModal({ add: true })}>
                                        <Icon name="plus"></Icon>
                                        <span>Add User</span>
                                    </Button>
                                </li>
                            </ul>
                        </BlockHeadContent>
                    </BlockBetween>
                </BlockHead>

                <Block>
                    {loading ?
                        <div className="d-flex justify-content-center align-items-center w-100" style={{ height: "80vh" }}>
                            <Spinner />
                        </div>
                        : (
                            <Card className="card-bordered card-stretch">
                                <div className="card-inner-group">
                                    <div className="card-inner">
                                        <div className="card-title-group">
                                            <div className="card-title">
                                                <h5 className="title">All Users</h5>
                                            </div>
                                            <div className="card-tools mr-n1">
                                                <ul className="btn-toolbar">
                                                    <li>
                                                        <Button onClick={toggle} className="btn-icon search-toggle toggle-search">
                                                            <Icon name="search"></Icon>
                                                        </Button>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className={`card-search search-wrap ${!onSearch ? "active" : ""}`}>
                                                <div className="search-content">
                                                    <Button className="search-back btn-icon toggle-search" onClick={() => { setSearchText(""); toggle(); }}>
                                                        <Icon name="arrow-left"></Icon>
                                                    </Button>
                                                    <input type="text" className="form-control border-transparent form-focus-none" onChange={(e) => setSearchText(e.target.value)} placeholder="Search" value={onSearchText}
                                                    />
                                                    <Button className="search-submit btn-icon">
                                                        <Icon name="search"></Icon>
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-inner p-0">
                                        <table className="table table-tranx">
                                            <thead>
                                                <tr className="tb-tnx-head">
                                                    <th className="tb-tnx-id">
                                                        <span className="">#</span>
                                                    </th>
                                                    <th className="tb-tnx-info">
                                                        <span className="tb-tnx-desc d-none d-sm-inline-block">
                                                            <span>Name</span>
                                                        </span>
                                                        <span className="tb-tnx-date d-md-inline-block d-none">
                                                            {/* <span className="d-md-none">Make</span> */}
                                                            <span className="d-none d-md-block">
                                                                <span>Email</span>
                                                                <span>Password</span>
                                                            </span>
                                                        </span>
                                                    </th>
                                                    <th className="tb-tnx-amount is-alt">
													<span className="tb-tnx-total">Phone Number</span>
													{/* <span className="tb-tnx-status d-none d-md-inline-block">Status</span> */}
												</th>

                                                    <th className="tb-tnx-action">
                                                        <span>Action</span>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {displayListing?.length === 0 ?
                                                    <div>
                                                        <span>
                                                            No data Found
                                                        </span>
                                                    </div>
                                                    : (
                                                        displayListing?.map((userData, index) => {
                                                            return (
                                                                <tr className="tb-tnx-item">
                                                                    <td className="tb-tnx-id">
                                                                        <div >
                                                                            <span>{pageNumber * UsersPerPage + index + 1}</span>
                                                                        </div>
                                                                    </td>
                                                                    <td className="tb-tnx-info">
                                                                        <div className="tb-tnx-desc">
                                                                            <span className="title">{userData?.username}</span>
                                                                        </div>
                                                                        <div className="tb-tnx-date">
                                                                            <span className="date">{userData?.email}</span>
                                                                            <span className="date">{userData?.plain_password}</span>
                                                                        </div>
                                                                    </td>
                                                                    <td className="tb-tnx-amount is-alt">
																		<div className="tb-tnx-total">
																			<span className="amount">{userData?.phone_number}</span>
																		</div>
																	</td>
                                                                    <td className="tb-tnx-action">
                                                                        <UncontrolledDropdown>
                                                                            <DropdownToggle tag="a" className="text-soft dropdown-toggle btn btn-icon btn-trigger">
                                                                                <Icon name="more-h"></Icon>
                                                                            </DropdownToggle>
                                                                            <DropdownMenu right>
                                                                                <ul className="link-list-plain">
                                                                                    <li>
                                                                                        <DropdownItem tag="a" href={`/users/${userData?.id}`} >
                                                                                            View Detail
                                                                                        </DropdownItem>
                                                                                    </li>
                                                                                    <li>
                                                                                        <DropdownItem tag="a" href="#print" onClick={(ev) => { ev.preventDefault(); setModal({ updateUserPassword: true }); setUserEmail(userData?.email) }} >
                                                                                            Change Password
                                                                                        </DropdownItem>
                                                                                    </li>
                                                                                    {/* <li>
                                                                                        <DropdownItem tag="a" href="#print" onClick={(ev) => { ev.preventDefault(); setModal({ deleteUser: true }) }}>
                                                                                            Delete User
                                                                                        </DropdownItem>
                                                                                    </li> */}
                                                                                </ul>
                                                                            </DropdownMenu>
                                                                        </UncontrolledDropdown>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                    )
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="card-inner">
                                        <ReactPaginate
                                            breakLabel="..."
                                            breakClassName="pagination-btns"
                                            onPageChange={(number) => paginate(number)}
                                            // onPageChange={handlePageClick}
                                            pageRangeDisplayed={3}
                                            pageCount={pageCount}
                                            previousLabel="<<  Prev "
                                            renderOnZeroPageCount={null}
                                            nextLabel="Next >>"
                                            previousClassName="pagination-next"
                                            nextClassName="pagination-next"
                                            activeClassName="pagination-active"
                                            pageClassName="pagination mt-0"
                                            pageLinkClassName="pagination-btns"
                                            containerClassName="pagination justify-content-start"
                                        />
                                    </div>
                                </div>
                            </Card>
                        )}
                </Block>
            </Content>
            <AddUserModal modal={modal} setModal={() => setModal({ add: false })} />
            <UpdateUserPasswordModal modal={modal} setModal={() => setModal({ updateUserPassword: false })} email={userEmail} />
            <DeleteUserModal modal={modal} setModal={() => setModal({ deleteUser: false })} />
        </React.Fragment>
    );
};

export default Users;
