import React, { useEffect, useState } from 'react'

import { useForm } from 'react-hook-form';
import ReactImageUploading from 'react-images-uploading';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { toast } from 'react-hot-toast';
import { Button, Col, Form, FormGroup, Modal, ModalBody, Row, Spinner } from 'reactstrap';

import { Icon, RSelect } from '../../components/Component';
import { GetWebPagesThunk } from '../../redux/advertisement/GetWebPagesSlice';
import { GetAllAdvertisementThunk } from '../../redux/advertisement/AdvertisementSlice';
import { AddAdvertisementThunk, ResetAddAdvertisementData } from '../../redux/advertisement/AddAdvertisementSlice';

const AddAdsModal = ({ modal, setModal }) => {
    const dispatch = useDispatch();

    const [image, setImage] = useState([]);
    const [webPagesOption, setwebPagesOption] = useState([])
    const [selectedWebPageId, setSelectedWebPageId] = useState()
    const { loading: webPagesLoading, webPagesData } = useSelector((state) => state.advertisement.webPages);

    useEffect(() => {
        dispatch(GetWebPagesThunk());
    }, [dispatch]);

    useEffect(() => {
        const list = webPagesData?.data?.map((webPage) => {
            return { label: webPage?.name, value: webPage?.id };
        })
        setwebPagesOption(list);
    }, [webPagesData])

    const { loading, success, error, errorMessage } = useSelector((state) => state.advertisement.addAdvertisement);

    const onChange = (imageList) => {
        setImage(imageList);
    };

    const handleAddAdForm = ({ ad_link }) => {
        dispatch(AddAdvertisementThunk({ ad_link, page_id: selectedWebPageId, order: 3, image }))
    }

    if (success) {
        toast.success("Ad has been added successfully.");
        dispatch(ResetAddAdvertisementData());
        dispatch(GetAllAdvertisementThunk());
        setModal(false);
    }

    if (error) {
        toast.error(errorMessage);
        dispatch(ResetAddAdvertisementData());
        dispatch(GetAllAdvertisementThunk());
        setModal(false);
    }

    const { errors, register, handleSubmit } = useForm();

    return (
        <React.Fragment>
            <Modal isOpen={modal.add} toggle={() => setModal(false)} className="modal-dialog-centered" size="lg">
                <ModalBody>
                    <div onClick={() => setModal(false)} className="close cursor-pointer">
                        <Icon name="cross-sm"></Icon>
                    </div>
                    <div className="p-2">
                        <h5 className="title">Add Ad</h5>
                        <div className="mt-4">
                            {webPagesLoading ?
                                <div className="d-flex justify-content-center align-items-center w-100" style={{ height: "60vh" }}>
                                    <Spinner />
                                </div>
                                : (
                                    <Form className="row gy-4 mt-4" onSubmit={handleSubmit(handleAddAdForm)}>
                                        <Col md="12">
                                            <FormGroup>
                                                <ReactImageUploading className="form-control" multiple value={image} onChange={onChange} maxNumber={1} dataURLKey="data_url">
                                                    {({ imageList, onImageUpload, onImageRemove, isDragging, dragProps }) => (
                                                        <div className="upload__image-wrapper">
                                                            {image.length === 0 &&
                                                                <Button style={isDragging ? { color: "red" } : undefined} onClick={onImageUpload} className="btn-primary" {...dragProps}>
                                                                    Select Ad Image
                                                                </Button>
                                                            }
                                                            &nbsp;
                                                            <div className="row image-item">
                                                                {imageList.map((image, index) => (
                                                                    <div>
                                                                        <img src={image["data_url"]} alt="" width="120" height="100" />
                                                                        <div className="image-item__btn-wrapper">
                                                                            <Button className="btn-primary mt-1" onClick={() => onImageRemove(index)}>
                                                                                Remove
                                                                            </Button>
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    )}
                                                </ReactImageUploading>
                                            </FormGroup>
                                        </Col>
                                        <Col md="12">
                                            <FormGroup>
                                                <label className="form-label">Ad Link</label>
                                                <input className="form-control" ref={register({ required: "This field is required" })} type="text" name="ad_link"
                                                />
                                                {errors.ad_link && <span className="invalid">{errors.ad_link.message}</span>}
                                            </FormGroup>
                                        </Col>
                                        <Col md="12">
                                            <FormGroup>
                                                <label className="form-label">Select WebPage</label>
                                                <div className="form-control-wrap">
                                                    <RSelect options={webPagesOption} onChange={(e) => setSelectedWebPageId(e.value)} />
                                                </div>
                                            </FormGroup>
                                        </Col>
                                        <Col md="12">
                                            <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                                                <li>
                                                    <Button disabled={loading} color="primary" size="lg" type="submit">
                                                        <span>{loading ? <Spinner size="sm" /> : "Add Ad"} </span>
                                                    </Button>
                                                </li>
                                                <li>
                                                    <div onClick={() => setModal(false)} className="link link-light cursor-pointer">
                                                        Cancel
                                                    </div>
                                                </li>
                                            </ul>
                                        </Col>
                                    </Form>
                                )
                            }
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </React.Fragment>
    )
}

export default AddAdsModal;