import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiEndpoints } from "../../utils/ApiEndPoints";

const GetUserListingThunk = createAsyncThunk("GetUserListing/GetUserListingThunk", async ({ id }, { rejectWithValue }) => {
    try {
        const response = await axios.get(`${ApiEndpoints.User.GetUserListing}?user_id=${id}`,
            {
                headers: {
                    "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
                }
            });
        return response.data?.data;
    } catch (error) {
        console.log("error", error);
        const errorMessage = error.response.data.message ? error.response.data.message : "Something Went Wrong!";
        return rejectWithValue(errorMessage);
    }
});

const GetUserListingSlice = createSlice({
    name: "GetUserListing",
    initialState: {
        userListingData: [],
        success: false,
        error: false,
        loading: false,
        errorMessage: "",
    },
    reducers: {
        ResetGetUserListingData: (state, action) => {
            state.userListingData = [];
            state.success = false;
            state.error = false;
            state.loading = false;
            state.errorMessage = "";
        },
    },
    extraReducers: {
        [GetUserListingThunk.pending]: (state, action) => {
            state.loading = true;
            state.success = false;
            state.error = false;
        },
        [GetUserListingThunk.fulfilled]: (state, action) => {
            state.userListingData = action.payload;
            state.success = true;
            state.loading = false;
        },
        [GetUserListingThunk.rejected]: (state, action) => {
            state.errorMessage = action.payload;
            state.error = true;
            state.loading = false;
            state.userListingData = [];
        },
    },
});

export default GetUserListingSlice.reducer;

export const { ResetGetUserListingData } = GetUserListingSlice.actions;

export { GetUserListingThunk };
