

import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import ReactImageUploading from 'react-images-uploading';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { toast } from 'react-hot-toast';
import { Button, Col, Form, FormGroup, Modal, ModalBody, Row, Spinner } from 'reactstrap';
import { Icon, RSelect } from '../../../components/Component';
import { GetAllCategoryThunk } from '../../../redux/Category/GetAllCategorySlice';
import { AddUserListingThunk, ResetAddUserListingData } from '../../../redux/user/AddUserListingSlice';
import { GetUserListingThunk, ResetGetUserListingData } from '../../../redux/user/GetUserListingSlice';
import { GetUserPackageThunk } from '../../../redux/user/GetUserPackageSlice';

const AddUserListingModal = ({ modal, setModal, user_id }) => {
    const dispatch = useDispatch();


    const [images, setImages] = useState();
    const [categoryOption, setCategoryOption] = useState([])
    const [packageOption, setPackageOption] = useState([])
    const [selectedCategoryId, setSelectedCategoryId] = useState();
    const [selectedPackageId, setSelectedPackageId] = useState();
    const [formData, setFormData] = useState({
        title: "",
        content: "",
        price: "",
        year: "",
        make: '',
        model: '',
        machine_type: "",
        hours: "",
        mileage: "",
        service_history: "",
        serial_number: "",
        fuel_type: "",
        engine_type: "",
        engine_max_power: "",
        engine_max_torque: "",
    });
    const {
        loading: CategoryLoading,
        allCategoryData
    } = useSelector((state) => state.category.getAllCategory)

    const {
        loading: PackageLoading,
        userPackageData
    } = useSelector((state) => state.user.GetUserPackage)

    useEffect(() => {
        dispatch(GetAllCategoryThunk());
        dispatch(GetUserPackageThunk({ id: user_id }));
    }, [dispatch, user_id]);

    useEffect(() => {
        const list = allCategoryData?.data?.map((category) => {
            return { label: category?.name, value: category?.id };
        })
        setCategoryOption(list);
        const packageList = userPackageData?.packages?.map((packageData) => {
            return { label: packageData?.Package?.name, value: packageData?.id };
        });
        setPackageOption(packageList);
    }, [allCategoryData, userPackageData]);

    const { loading, success, error, errorMessage } = useSelector((state) => state.user.AddUserListing);

    const onChange = (imageList) => {
        setImages(imageList);
    };

    const onInputChange = (e) => {
        e.preventDefault();
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    const handleAddUserListingForm = () => {
        dispatch(AddUserListingThunk({
            ...formData,
            user_id, package_id: selectedPackageId, category: selectedCategoryId, images
        }))
    }

    if (success) {
        toast.success("User's Listing has been added successfully.");
        dispatch(ResetAddUserListingData());
        dispatch(GetUserListingThunk({ id: user_id }));
        setModal(false);
    }

    if (error) {
        toast.error(errorMessage);
        dispatch(ResetAddUserListingData());
        // dispatch(GetUserListingThunk({ id: user_id }));
        setModal(false);
    }

    const { errors, register, handleSubmit } = useForm();

    return (
        <React.Fragment>
            <Modal isOpen={modal} toggle={() => setModal(false)} className="modal-dialog-centered" size="lg">
                <ModalBody>
                    <a
                        href="#cancel"
                        onClick={() => setModal(false)}
                        className="close"
                    >
                        <Icon name="cross-sm"></Icon>
                    </a>
                    <div className="p-2">
                        <h5 className="title">Add Listing</h5>
                        <div className="mt-4">
                            {CategoryLoading || PackageLoading ?
                                <div className="d-flex justify-content-center align-items-center w-100" style={{ height: "60vh" }}>
                                    <Spinner />
                                </div>
                                : (
                                    <Form className="row gy-4 mt-4" onSubmit={handleSubmit(handleAddUserListingForm)}>
                                        <Col md="6">
                                            <FormGroup>
                                                <label className="form-label">Title <span className='cl-red'>*</span></label>
                                                <input className="form-control" ref={register({ required: "This field is required" })} value={formData?.title} onChange={onInputChange} type="text" name="title"
                                                />
                                                {errors.title && <span className="invalid">{errors.title.message}</span>}
                                            </FormGroup>
                                        </Col>
                                        <Col md="6">
                                            <FormGroup>
                                                <label className="form-label">Content <span className='cl-red'>*</span></label>
                                                <input className="form-control" ref={register({ required: "This field is required" })} value={formData?.content} onChange={onInputChange} type="text" name="content"
                                                />
                                                {errors.content && <span className="invalid">{errors.content.message}</span>}
                                            </FormGroup>
                                        </Col>
                                        <Col md="6">
                                            <FormGroup>
                                                <label className="form-label">Price <span className='cl-red'>*</span></label>
                                                <input className="form-control" ref={register({ required: "This field is required" })} value={formData?.price} onChange={onInputChange} type="text" name="price"
                                                />
                                                {errors.price && <span className="invalid">{errors.price.message}</span>}
                                            </FormGroup>
                                        </Col>
                                        <Col md="12">
                                            <FormGroup>
                                                <label className="form-label">Category <span className='cl-red'>*</span></label>
                                                <div className="form-control-wrap">
                                                    <RSelect
                                                        options={categoryOption}
                                                        // defaultValue={{ value: "Paid", label: "Paid" }}
                                                        onChange={(e) => setSelectedCategoryId(e.value)}
                                                    />
                                                </div>
                                            </FormGroup>
                                        </Col>
                                        <Col md="12">
                                            <FormGroup>
                                                <label className="form-label">Listing Pacakge <span className='cl-red'>*</span></label>
                                                <div className="form-control-wrap">
                                                    <RSelect
                                                        options={packageOption}
                                                        // defaultValue={{ value: "Paid", label: "Paid" }}
                                                        onChange={(e) => setSelectedPackageId(e.value)}
                                                    />
                                                </div>
                                            </FormGroup>
                                        </Col>
                                        <Col md="12">
                                            <FormGroup>
                                                <label className="form-label">Image Gallery <span className='cl-red'>*</span></label>
                                                <ReactImageUploading
                                                    className="form-control"
                                                    multiple
                                                    value={images}
                                                    onChange={onChange}
                                                    maxNumber={10}
                                                    dataURLKey="data_url"
                                                >
                                                    {({ imageList, onImageUpload, onImageRemove, isDragging, dragProps }) => (
                                                        <div className="upload__image-wrapper">
                                                            <Button
                                                                style={isDragging ? { color: "red" } : undefined}
                                                                onClick={onImageUpload}
                                                                color="primary"
                                                                className="form-bt2 color-black900"
                                                                {...dragProps}
                                                            >
                                                                Click or Drop here
                                                            </Button>
                                                            &nbsp;
                                                            <div className="row image-item">
                                                                <Row>
                                                                    {imageList.map((image, index) => (
                                                                        <Col className="m-2" md="3">
                                                                            <img src={image["data_url"]} alt="" width="120" height="100" />
                                                                            <Button
                                                                                className="mt-1"
                                                                                color='primary'
                                                                                onClick={() => onImageRemove(index)}
                                                                            >
                                                                                Remove
                                                                            </Button>
                                                                        </Col>
                                                                    ))}
                                                                </Row>
                                                            </div>
                                                        </div>
                                                    )}
                                                </ReactImageUploading>
                                                {/* <input className="form-control" ref={register({ required: "This field is required" })} type="number" name="image_gallery" required/> */}
                                                {errors.image_gallery && <span className="invalid">{errors.image_gallery.message}</span>}
                                            </FormGroup>
                                        </Col>
                                        <Col md="6">
                                            <FormGroup>
                                                <label className="form-label">Make <span className='cl-red'>*</span></label>
                                                <input className="form-control" ref={register({ required: "This field is required" })} value={formData?.make} onChange={onInputChange} type="text" name="make" required
                                                />
                                                {errors.make && <span className="invalid">{errors.make.message}</span>}
                                            </FormGroup>
                                        </Col>
                                        <Col md="6">
                                            <FormGroup>
                                                <label className="form-label">Model <span className='cl-red'>*</span></label>
                                                <input className="form-control" value={formData?.model} onChange={onInputChange} ref={register({ required: "This field is required" })} type="text" name="model" required
                                                />
                                                {errors.model && <span className="invalid">{errors.model.message}</span>}
                                            </FormGroup>
                                        </Col>
                                        <Col md="6">
                                            <FormGroup>
                                                <label className="form-label">Machine Type <span className='cl-red'>*</span></label>
                                                <input className="form-control" value={formData?.machine_type} onChange={onInputChange} ref={register({ required: "This field is required" })} type="text" name="machine_type" required
                                                />
                                                {errors.machine_type && <span className="invalid">{errors.machine_type.message}</span>}
                                            </FormGroup>
                                        </Col>
                                        <Col md="6">
                                            <FormGroup>
                                                <label className="form-label">Year</label>
                                                <input className="form-control" value={formData?.year} onChange={onInputChange} type="text" name="year" />
                                                {errors.year && <span className="invalid">{errors.year.message}</span>}
                                            </FormGroup>
                                        </Col>
                                        <Col md="6">
                                            <FormGroup>
                                                <label className="form-label">Hours</label>
                                                <input className="form-control" value={formData?.hours} onChange={onInputChange} type="number" name="hours"
                                                />
                                                {errors.hours && <span className="invalid">{errors.hours.message}</span>}
                                            </FormGroup>
                                        </Col>
                                        <Col md="6">
                                            <FormGroup>
                                                <label className="form-label">Mileage</label>
                                                <input className="form-control" value={formData?.mileage} onChange={onInputChange} type="number" name="mileage"
                                                />
                                                {errors.mileage && <span className="invalid">{errors.mileage.message}</span>}
                                            </FormGroup>
                                        </Col>
                                        <Col md="6">
                                            <FormGroup>
                                                <label className="form-label">Service History</label>
                                                <input className="form-control" value={formData?.service_history} onChange={onInputChange} type="text" name="service_history"
                                                />
                                                {errors.service_history && <span className="invalid">{errors.service_history.message}</span>}
                                            </FormGroup>
                                        </Col>
                                        <Col md="6">
                                            <FormGroup>
                                                <label className="form-label">Serial Number</label>
                                                <input className="form-control" value={formData?.serial_number} onChange={onInputChange} type="text" name="serial_number"
                                                />
                                                {errors.serial_number && <span className="invalid">{errors.serial_number.message}</span>}
                                            </FormGroup>
                                        </Col>
                                        {/* <Col md="6">
                                            <FormGroup>
                                                <label className="form-label">Fuel Type</label>
                                                <input className="form-control" ref={register({ required: "This field is required" })} type="text" name="fuel_type"
                                                />
                                                {errors.fuel_type && <span className="invalid">{errors.fuel_type.message}</span>}
                                            </FormGroup>
                                        </Col>
                                        <Col md="6">
                                            <FormGroup>
                                                <label className="form-label">Engine Type</label>
                                                <input className="form-control" ref={register({ required: "This field is required" })} type="text" name="engine_type"
                                                />
                                                {errors.engine_type && <span className="invalid">{errors.engine_type.message}</span>}
                                            </FormGroup>
                                        </Col>
                                        <Col md="6">
                                            <FormGroup>
                                                <label className="form-label">Engine Max Power (km)</label>
                                                <input className="form-control" ref={register({ required: "This field is required" })} type="text" name="engine_max_power"
                                                />
                                                {errors.engine_max_power && <span className="invalid">{errors.engine_max_power.message}</span>}
                                            </FormGroup>
                                        </Col>
                                        <Col md="6">
                                            <FormGroup>
                                                <label className="form-label">Engine Max Torgue (NM)</label>
                                                <input className="form-control" ref={register({ required: "This field is required" })} type="text" name="engine_max_torque"
                                                />
                                                {errors.engine_max_torque && <span className="invalid">{errors.engine_max_torque.message}</span>}
                                            </FormGroup>
                                        </Col> */}
                                        <Col md="12">
                                            <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                                                <li>
                                                    <Button disabled={loading} color="primary" size="md" type="submit">
                                                        {loading ? <Spinner size="sm" /> : "Add"}
                                                    </Button>
                                                </li>
                                                <li>
                                                    <a
                                                        href="#cancel"
                                                        onClick={() => setModal(false)}
                                                        className="link link-light"
                                                    >
                                                        Cancel
                                                    </a>
                                                </li>
                                            </ul>
                                        </Col>
                                    </Form>
                                )
                            }
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </React.Fragment>
    )
}

export default AddUserListingModal;