import { combineReducers } from "@reduxjs/toolkit";
import AddPackageSlice from "./AddPackageSlice";
import GetAllPackageSlice from "./GetAllPackageSlice";
import UpdatePackageDetailSlice from "./UpdatePackageDetailSlice";


export const packageSlice = combineReducers({
    getAllPackage: GetAllPackageSlice,
    addPackage: AddPackageSlice,
    updatePackageDetail: UpdatePackageDetailSlice
})