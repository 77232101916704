import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router'
import Head from '../../../layout/head/Head'
import { GetUserProfileThunk } from '../../../redux/user/UserProfileSlice'
import UserListings from './UserListings'
import UserPackages from './UserPackages'
import UserRentalProduct from './RentalTable'

const UserDetail = () => {

    return (
        <React.Fragment>
            <Head title="User Detail - Yellowish Admin" />
            <UserListings />
            <UserRentalProduct/>
            <UserPackages />
        </React.Fragment>
    )
}

export default UserDetail;