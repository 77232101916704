import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiEndpoints } from "../../utils/ApiEndPoints";

const LoginThunk = createAsyncThunk("Login/LoginThunk", async ({ email, password }, { rejectWithValue }) => {
    try {
        const response = await axios.post(ApiEndpoints.Login, {
            email,
            password,
        });
        return response.data.data;
    } catch (error) {
        console.log("error", error);
        return rejectWithValue(error.response.data);
    }
});


const authInitialState = {
    success: false,
    error: false,
    loading: false,
    errorMessage: "",
    adminData: {},
}


const LoginSlice = createSlice({
    name: "Login",
    initialState: authInitialState,
    reducers: {
        ResetLoginData: (state, action) => {
            state.adminData = {};
            state.success = false;
            state.error = false;
            state.loading = false;
            state.errorMessage = "";
        },
    },
    extraReducers: {
        [LoginThunk.pending]: (state, action) => {
            state.loading = true;
            state.success = false;
            state.error = false;
        },
        [LoginThunk.fulfilled]: (state, action) => {
            state.adminData = action.payload;
            state.success = true;
            state.loading = false;
        },
        [LoginThunk.rejected]: (state, action) => {
            state.errorMessage = action.payload.message;
            state.error = true;
            state.loading = false;
            state.adminData = {};
        },
    },
});

export default LoginSlice.reducer;

export const { ResetLoginData } = LoginSlice.actions;

export { LoginThunk };
