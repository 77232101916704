import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiEndpoints } from "../../utils/ApiEndPoints";

const GetAdminDetailThunk = createAsyncThunk("GetAdminDetail/GetAdminDetailThunk", async ({ email, password }, { rejectWithValue }) => {
    try {
        const response = await axios.post(ApiEndpoints.GetAdminDetail, {
            email,
            password,
        });

        return response.data;
    } catch (error) {
        console.log("error", error);
        return rejectWithValue(error.response.data);
    }
});

const GetAdminDetailSlice = createSlice({
    name: "GetAdminDetail",
    initialState: {
        success: false,
        error: false,
        loading: false,
        errorMessage: "",
        adminData: {},
    },
    reducers: {
        ResetGetAdminData: (state, action) => {
            state.adminData = {};
            state.success = false;
            state.error = false;
            state.loading = false;
            state.errorMessage = "";
        },
    },
    extraReducers: {
        [GetAdminDetailThunk.pending]: (state, action) => {
            state.loading = true;
            state.success = false;
            state.error = false;
        },
        [GetAdminDetailThunk.fulfilled]: (state, action) => {
            state.adminData = action.payload;
            state.success = true;
            state.loading = false;
        },
        [GetAdminDetailThunk.rejected]: (state, action) => {
            state.errorMessage = action.payload.message;
            state.error = true;
            state.loading = false;
            state.adminData = {};
        },
    },
});

export default GetAdminDetailSlice.reducer;

export const { ResetGetAdminData } = GetAdminDetailSlice.actions;

export { GetAdminDetailThunk };
