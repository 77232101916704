import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import ReactImageUploading from 'react-images-uploading';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { toast } from 'react-hot-toast';
import { Button, Col, Form, FormGroup, Modal, ModalBody, Row, Spinner } from 'reactstrap';
import { Icon, RSelect } from '../../components/Component';
import { RentalProductDetailThunk } from '../../redux/RentalProduct/RentalProductDetailSlice';
import { GetAllRentalProductsThunk } from '../../redux/RentalProduct/GetAllRentalProductsSlice';
import { ResetUpdateRentalProductDetailData, UpdateRentalProductDetailThunk } from '../../redux/RentalProduct/UpdateRentalProductDetailSlice';

const UpdateRentalProductModal = ({ modal, setModal, rentalProductId }) => {
    const dispatch = useDispatch();

    const [formData, setFormData] = useState({});
    const [rentalProductImages, setRentalProductImages] = useState([]);
    const [oldrentalProductImages, setOldRentalProductImages] = useState([]);
    const [dealerLogo, setDealerLogo] = useState([]);
    const [oldDealerLogo, setOldDealerLogo] = useState([]);
    const [isDealerLogoRemove, setIsDealerLogoRemove] = useState(0);
    const [selectedRentalLocation, setSelectedRentalLocation] = useState({});
    const [locationOption, setLocationOption] =  useState([
		{  label:"Western cape",   value: 1},
		{  label:"Eastern cape",  value: 2},
		{  label:"Kwa Zulu Natal",  value: 3},
		{  label:"Mpumala",  value: 4},
		{  label:"Free State",  value: 5},
		{  label:"Gauteng",  value: 6},
		{  label:"Limpopo",  value: 7},
		{  label:"North West",  value: 8},
		{  label:"Northern cape",  value: 9}
	]);

    const { loading, success, error, errorMessage } = useSelector((state) => state.rentalProducts.updateRentalProductDetail);
    const {
        loading: rentalProductDetailLoading,
        rentalProductData
    } = useSelector((state) => state.rentalProducts.rentalProductDetail);

    useEffect(() => {
        dispatch(RentalProductDetailThunk({ rentalProductId }));
    }, [dispatch, rentalProductId]);

    useEffect(() => {
        setFormData({
            title: rentalProductData?.data?.title ? rentalProductData?.data?.title : "",
            content: rentalProductData?.data?.description ? rentalProductData?.data?.description : "",
            renting_price: rentalProductData?.data?.renting_price ? rentalProductData?.data?.renting_price : "",
            categoryLabel: rentalProductData?.data?.Category ? rentalProductData?.data?.Category?.name : "",
            categoryId: rentalProductData?.data?.Category ? rentalProductData?.data?.Category?.id : "",
            year: rentalProductData?.data?.year ? rentalProductData?.data?.year : "",
            make: rentalProductData?.data?.make ? rentalProductData?.data?.make : "",
            model: rentalProductData?.data?.model ? rentalProductData?.data?.model : "",
            machine_type: rentalProductData?.data?.machine_type ? rentalProductData?.data?.machine_type : "",
            machine_location: rentalProductData?.data?.machine_location ? rentalProductData?.data?.machine_location : "",
            hours: rentalProductData?.data?.hours ? rentalProductData?.data?.hours : "",
            mileage: rentalProductData?.data?.mileage ? rentalProductData?.data?.mileage : "",
            service_history: rentalProductData?.data?.service_history ? rentalProductData?.data?.service_history : "",
            serial_number: rentalProductData?.data?.serial_number ? rentalProductData?.data?.serial_number : "",
            // engine_max_torque: listingData?.data?.engine_max_torque ? listingData?.data?.engine_max_torque : "",
            // engine_max_power: listingData?.data?.engine_max_power ? listingData?.data?.engine_max_power : "",
            // engine_type: listingData?.data?.engine_type ? listingData?.data?.engine_type : "",
            // fuel_type: listingData?.data?.fuel_type ? listingData?.data?.fuel_type : "",
        });
        setSelectedRentalLocation({label:rentalProductData?.data?.Lcoation?.name, value: rentalProductData?.data?.Location.id })
        setOldRentalProductImages(rentalProductData?.data?.ProductImages);
        setOldDealerLogo(rentalProductData?.data?.seller_logo ? [{ image: rentalProductData?.data?.seller_logo }] : []);
    }, [rentalProductData?.data]);

    const onRentalProductImageChange = (imageList) => {
        setRentalProductImages(imageList);
    };

    const onOldRentalProductImageChange = (imageList) => {
        setOldRentalProductImages(imageList);
    }

    const onDealerLogoChange = (imageList) => {
        imageList.length !== 0 && setIsDealerLogoRemove(0)
        setDealerLogo(imageList);
    };

    const onOldDealerLogoChange = (imageList) => {
        setIsDealerLogoRemove(1);
        setOldDealerLogo(imageList);
    }

    const onInputChange = (e) => {
        e.preventDefault();
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    const handleUpdateRentalProductForm = () => {
        dispatch(UpdateRentalProductDetailThunk({ seller_logo: dealerLogo, oldImages: oldrentalProductImages, seller_logo_remove: isDealerLogoRemove, images: rentalProductImages, product_id: rentalProductData?.data?.product_id, title: formData?.title, description: formData?.content, price: formData?.price, category: formData?.categoryId, year: formData?.year, make: formData?.make, model: formData?.model, machine_type: formData?.machine_type, hours: formData?.hours, mileage: formData?.mileage, service_history: formData?.service_history, serial_number: formData?.serial_number, machine_location: formData?.machine_location }))
    }

    if (success) {
        toast.success("Rental Product has been updated successfully.");
        dispatch(GetAllRentalProductsThunk());
        dispatch(ResetUpdateRentalProductDetailData());
        setModal(false);
    }

    if (error) {
        toast.error(errorMessage);
        dispatch(GetAllRentalProductsThunk());
        dispatch(ResetUpdateRentalProductDetailData());
        setModal(false);
    }

    const { errors, register, handleSubmit } = useForm();

    return (
        <React.Fragment>
            <Modal isOpen={modal.updateRentalProductDetail} toggle={() => setModal(false)} className="modal-dialog-centered" size="lg">
                <ModalBody>
                    <div onClick={() => setModal(false)} className="close cursor-pointer">
                        <Icon name="cross-sm"></Icon>
                    </div>
                    <div className="p-2">
                        <h5 className="title">Edit Listing</h5>
                        <div className="mt-4">
                            {rentalProductDetailLoading ?
                                <div className="d-flex justify-content-center align-items-center w-100" style={{ height: "60vh" }}>
                                    <Spinner />
                                </div>
                                : (
                                    <Form className="row gy-4 mt-4" onSubmit={handleSubmit(handleUpdateRentalProductForm)}>
                                        <Col md="12">
                                            <FormGroup>
                                                <label className="form-label">Dealer Logo <span className='cl-red'>*</span></label>
                                                {
                                                    <ReactImageUploading className="form-control" multiple value={dealerLogo} onChange={onDealerLogoChange} maxNumber={1} dataURLKey="data_url">
                                                        {({ imageList, onImageUpload, onImageRemove, isDragging, dragProps }) => (
                                                            <div className="upload__image-wrapper">
                                                                {(oldDealerLogo.length === 0 && dealerLogo.length === 0) &&
                                                                    <Button style={isDragging ? { color: "red" } : undefined} onClick={onImageUpload} color="primary" className="form-bt2 color-black900" {...dragProps}>
                                                                        Click or Drop here
                                                                    </Button>
                                                                }
                                                                &nbsp;
                                                                <div className="row image-item">
                                                                    <Row>
                                                                        {imageList.map((image, index) => (
                                                                            <Col className="m-2" sm="4">
                                                                                <img src={image["data_url"]} alt="" width="120" height="100" />
                                                                                <Button className="mt-1" color='primary' onClick={() => onImageRemove(index)}>
                                                                                    Remove
                                                                                </Button>
                                                                            </Col>
                                                                        ))}
                                                                    </Row>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </ReactImageUploading>
                                                }
                                                {errors.image_gallery && <span className="invalid">{errors.image_gallery.message}</span>}
                                            </FormGroup>
                                        </Col>
                                        <Col md="12">
                                            <FormGroup>
                                                <ReactImageUploading className="form-control" multiple value={oldDealerLogo} onChange={onOldDealerLogoChange} maxNumber={15} dataURLKey="data_url">
                                                    {({ imageList, onImageUpload, onImageRemove, isDragging, dragProps }) => (
                                                        <div className="upload__image-wrapper">
                                                            <div className="row image-item">
                                                                <Row>
                                                                    {imageList.map((image, index) => {
                                                                        return (
                                                                            <Col className="m-2" md="6">
                                                                                <img src={`${image.image}`} alt="" width="120" height="100" />
                                                                                <Button className="mt-1" color='primary' onClick={() => onImageRemove(index)}>
                                                                                    Remove
                                                                                </Button>
                                                                            </Col>
                                                                        )
                                                                    }
                                                                    )}
                                                                </Row>
                                                            </div>
                                                        </div>
                                                    )}
                                                </ReactImageUploading>

                                                {errors.image_gallery && <span className="invalid">{errors.image_gallery.message}</span>}
                                            </FormGroup>
                                        </Col>

                                        <Col md="6">
                                            <FormGroup>
                                                <label className="form-label">Title <span className='cl-red'>*</span></label>
                                                <input className="form-control" ref={register({ required: "This field is required" })} value={formData?.title} onChange={onInputChange} type="text" name="title"/>
                                                {errors.title && <span className="invalid">{errors.title.message}</span>}
                                            </FormGroup>
                                        </Col>
                                        <Col md="6">
                                            <FormGroup>
                                                <label className="form-label">Content <span className='cl-red'>*</span></label>
                                                <input className="form-control" ref={register({ required: "This field is required" })} value={formData?.content} onChange={onInputChange} type="text" name="content"/>
                                                {errors.content && <span className="invalid">{errors.content.message}</span>}
                                            </FormGroup>
                                        </Col>
                                        <Col md="6">
                                            <FormGroup>
                                                <label className="form-label">Rental Price<span className='cl-red'>*</span></label>
                                                <input className="form-control" ref={register({ required: "This field is required" })} value={formData?.renting_price} onChange={onInputChange} type="text" name="renting_price"/>
                                                {errors.renting_price && <span className="invalid">{errors.renting_price.message}</span>}
                                            </FormGroup>
                                        </Col>
                                        <Col md="12">
                                            <FormGroup>
                                                <label className="form-label">Image Gallery <span className='cl-red'>*</span></label>
                                                <ReactImageUploading className="form-control" multiple value={rentalProductImages} onChange={onRentalProductImageChange} maxNumber={15} dataURLKey="data_url">
                                                    {({ imageList, onImageUpload, onImageRemove, isDragging, dragProps }) => (
                                                        <div className="upload__image-wrapper">
                                                            <Button style={isDragging ? { color: "red" } : undefined} onClick={onImageUpload} color="primary" className="form-bt2 color-black900" {...dragProps}>
                                                                Click or Drop here
                                                            </Button>
                                                            &nbsp;
                                                            <div className="row image-item">
                                                                <Row>
                                                                    {imageList.map((image, index) => (
                                                                        <Col className="m-2" sm="4">
                                                                            <img src={image["data_url"]} alt="" width="120" height="100" />
                                                                            <Button className="mt-1" color='primary' onClick={() => onImageRemove(index)}>
                                                                                Remove
                                                                            </Button>
                                                                        </Col>
                                                                    ))}
                                                                </Row>
                                                            </div>
                                                        </div>
                                                    )}
                                                </ReactImageUploading>

                                                {errors.image_gallery && <span className="invalid">{errors.image_gallery.message}</span>}
                                            </FormGroup>
                                        </Col>
                                        <Col md="12">
                                            <FormGroup>
                                                <ReactImageUploading className="form-control" multiple value={oldrentalProductImages} onChange={onOldRentalProductImageChange} maxNumber={15} dataURLKey="data_url">
                                                    {({ imageList, onImageUpload, onImageRemove, isDragging, dragProps }) => (
                                                        <div className="upload__image-wrapper">
                                                            <div className="row image-item">
                                                                <Row>
                                                                    {imageList.map((image, index) => {
                                                                        return (
                                                                            <Col className="m-2" md="3">
                                                                                <img src={`${image.image}`} alt="" width="120" height="100" />
                                                                                <Button className="mt-1" color='primary' onClick={() => onImageRemove(index)}>
                                                                                    Remove
                                                                                </Button>
                                                                            </Col>
                                                                        )
                                                                    }
                                                                    )}
                                                                </Row>
                                                            </div>
                                                        </div>
                                                    )}
                                                </ReactImageUploading>

                                                {errors.image_gallery && <span className="invalid">{errors.image_gallery.message}</span>}
                                            </FormGroup>
                                        </Col>

                                        <Col md="6">
                                            <FormGroup>
                                                <label className="form-label">Make <span className='cl-red'>*</span></label>
                                                <input className="form-control" ref={register({ required: "This field is required" })} value={formData?.make} onChange={onInputChange} type="text" name="make" required />
                                                {errors.make && <span className="invalid">{errors.make.message}</span>}
                                            </FormGroup>
                                        </Col>
                                        <Col md="6">
                                            <FormGroup>
                                                <label className="form-label">Model <span className='cl-red'>*</span></label>
                                                <input className="form-control" ref={register({ required: "This field is required" })} value={formData?.model} onChange={onInputChange} type="text" name="model" required />
                                                {errors.model && <span className="invalid">{errors.model.message}</span>}
                                            </FormGroup>
                                        </Col>
                                        <Col md="12">
                                            <FormGroup>
                                                <label className="form-label">Rental Location <span className='cl-red'>*</span></label>
                                                <div className="form-control-wrap">
                                                    <RSelect
                                                        options={locationOption}
                                                        defaultValue={{ value: selectedRentalLocation.value, label: selectedRentalLocation.label }}
                                                        onChange={(e) => setFormData({...formData, machine_location: e.value })}
                                                    />
                                                </div>
                                            </FormGroup>
                                        </Col>
                                        <Col md="6">
                                            <FormGroup>
                                                <label className="form-label">Machine Type <span className='cl-red'>*</span></label>
                                                <input className="form-control" ref={register({ required: "This field is required" })} value={formData?.machine_type} onChange={onInputChange} type="text" name="machine_type" required />
                                                {errors.machine_type && <span className="invalid">{errors.machine_type.message}</span>}
                                            </FormGroup>
                                        </Col>
                                        <Col md="6">
                                            <FormGroup>
                                                <label className="form-label">Year</label>
                                                <input className="form-control" value={formData?.year} onChange={onInputChange} type="text" name="year" />
                                                {errors.year && <span className="invalid">{errors.year.message}</span>}
                                            </FormGroup>
                                        </Col>

                                        <Col md="6">
                                            <FormGroup>
                                                <label className="form-label">Hours</label>
                                                <input className="form-control" value={formData?.hours} onChange={onInputChange} type="text" name="hours" />
                                                {errors.hours && <span className="invalid">{errors.hours.message}</span>}
                                            </FormGroup>
                                        </Col>
                                        <Col md="6">
                                            <FormGroup>
                                                <label className="form-label">Mileage</label>
                                                <input className="form-control" value={formData?.mileage} onChange={onInputChange} type="text" name="mileage" />
                                                {errors.mileage && <span className="invalid">{errors.mileage.message}</span>}
                                            </FormGroup>
                                        </Col>
                                        <Col md="6">
                                            <FormGroup>
                                                <label className="form-label">Service History</label>
                                                <input className="form-control" value={formData?.service_history} onChange={onInputChange} type="text" name="service_history" />
                                                {errors.service_history && <span className="invalid">{errors.service_history.message}</span>}
                                            </FormGroup>
                                        </Col>
                                        <Col md="6">
                                            <FormGroup>
                                                <label className="form-label">Serial Number</label>
                                                <input className="form-control" value={formData?.serial_number} onChange={onInputChange} type="text" name="serial_number" />
                                                {errors.serial_number && <span className="invalid">{errors.serial_number.message}</span>}
                                            </FormGroup>
                                        </Col>
                                        {/* <Col md="6">
                                            <FormGroup>
                                                <label className="form-label">Fuel Type</label>
                                                <input className="form-control" value={formData?.fuel_type} onChange={onInputChange} type="text" name="fuel_type" />
                                                {errors.fuel_type && <span className="invalid">{errors.fuel_type.message}</span>}
                                            </FormGroup>
                                        </Col>
                                        <Col md="6">
                                            <FormGroup>
                                                <label className="form-label">Engine Type</label>
                                                <input className="form-control" value={formData?.engine_type} onChange={onInputChange} type="text" name="engine_type" />
                                                {errors.engine_type && <span className="invalid">{errors.engine_type.message}</span>}
                                            </FormGroup>
                                        </Col>
                                        <Col md="6">
                                            <FormGroup>
                                                <label className="form-label">Engine Max Power (km)</label>
                                                <input className="form-control" value={formData?.engine_max_power} onChange={onInputChange} type="text" name="engine_max_power" />
                                                {errors.engine_max_power && <span className="invalid">{errors.engine_max_power.message}</span>}
                                            </FormGroup>
                                        </Col>
                                        <Col md="6">
                                            <FormGroup>
                                                <label className="form-label">Engine Max Torgue (NM)</label>
                                                <input className="form-control" value={formData?.engine_max_torque} onChange={onInputChange} type="text" name="engine_max_torque" />
                                                {errors.engine_max_torque && <span className="invalid">{errors.engine_max_torque.message}</span>}
                                            </FormGroup>
                                        </Col> */}
                                        <Col size="12">
                                            <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                                                <li>
                                                    <Button disabled={loading} color="primary" size="md" type="submit">
                                                    <span>{loading ? <Spinner size="sm" /> : "Update"} </span>
                                                    </Button>
                                                </li>
                                                <li>
                                                    <div onClick={() => setModal(false)} className="link link-light">
                                                        Cancel
                                                    </div>
                                                </li>
                                            </ul>
                                        </Col>
                                    </Form>
                                )
                            }
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </React.Fragment>
    )
}

export default UpdateRentalProductModal;