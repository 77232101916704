import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';

import { UncontrolledDropdown, DropdownMenu, DropdownToggle, Card, DropdownItem, Spinner } from "reactstrap";

import Content from "../../../layout/content/Content";
import { Button, Block, BlockBetween, BlockDes, BlockHead, BlockHeadContent, BlockTitle, Icon } from "../../../components/Component";
import { GetUserPackageThunk } from '../../../redux/user/GetUserPackageSlice';
import AddUserPackageModal from './AddUserPackageModal';
import DeleteUserPackageModal from './DeleteUserPackageModal';
import ResubscribeUserPackageModal from './ReSubscribeUserPackageModal';
import UpdateUserPackageStatusModal from './UpdateUserPackageStatusModal';
import ViewUserPackageDetailModal from './ViewUserPackageDetailModal';

const UserPackages = () => {
    const dispatch = useDispatch();
    const { id } = useParams();

    const [packageId, setPackageId] = useState('');
    const [userPackageId, setUserPackageId] = useState('');
    const [modal, setModal] = useState({
        add: false,
        viewDetail: false,
        delete: false,
        updateStatus: false,
        resubscribe: false,
    });

    const { loading, userPackageData } = useSelector((state) => state.user.GetUserPackage)

    useEffect(() => {
        dispatch(GetUserPackageThunk({ id }));
    }, [dispatch, id])

    return (
        <React.Fragment>
            <Content>
                <BlockHead size="sm">
                    <BlockBetween>
                        <BlockHeadContent>
                            <BlockTitle page>User Package</BlockTitle>
                            <BlockDes className="text-soft">
                                <p>You have total {userPackageData?.packages_no} packages.</p>
                            </BlockDes>
                        </BlockHeadContent>
                        <BlockHeadContent>
                            <ul className="nk-block-tools g-3">
                                <li>
                                    <Button color="primary" onClick={() => setModal({ add: true })}>
                                        <Icon name="plus"></Icon>
                                        <span>Add Package</span>
                                    </Button>
                                </li>
                            </ul>
                        </BlockHeadContent>
                    </BlockBetween>
                </BlockHead>

                <Block>
                    {loading ?
                        <div className="d-flex justify-content-center align-items-center w-100" style={{ height: "40vh" }}>
                            <Spinner />
                        </div>
                        : (
                            <Card className="card-bordered card-stretch">
                                <div className="card-inner-group">
                                    <div className="card-inner">
                                        <div className="card-title-group">
                                            <div className="card-title">
                                                <h5 className="title">All User Package</h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-inner p-0">
                                        <table className="table table-tranx">
                                            <thead>
                                                <tr className="tb-tnx-head">
                                                    <th className="tb-tnx-id">
                                                        <span className="">#</span>
                                                    </th>
                                                    <th className="tb-tnx-info">
                                                        <span className="tb-tnx-desc d-none d-sm-inline-block">
                                                            <span>Package Id</span>
                                                        </span>
                                                        <span className="tb-tnx-date d-md-inline-block d-none">
                                                            {/* <span className="d-md-none">Make</span> */}
                                                            <span className="d-none d-md-block">
                                                                <span>Payment Id</span>
                                                                <span>created At</span>
                                                            </span>
                                                        </span>
                                                    </th>
                                                    <th className="tb-tnx-amount is-alt">
                                                        <span className="tb-tnx-total">Remaining Listing</span>
                                                        <span className="tb-tnx-status d-none d-md-inline-block">Status</span>
                                                    </th>
                                                    <th className="tb-tnx-action">
                                                        <span>Action</span>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {userPackageData?.packages?.map((userPackage, index) => {
                                                    return (
                                                        <tr className="tb-tnx-item">
                                                            <td className="tb-tnx-id">
                                                                <a href="#ref" onClick={(ev) => { ev.preventDefault(); }} >
                                                                    {index + 1}
                                                                </a>
                                                            </td>
                                                            <td className="tb-tnx-info">
                                                                <div className="tb-tnx-desc">
                                                                    <span className="title">{userPackage?.package_id}</span>
                                                                </div>
                                                                <div className="tb-tnx-date">
                                                                    <span className="date">{userPackage?.payment_id}</span>
                                                                    <span className="date">{userPackage?.createdAt}</span>
                                                                </div>
                                                            </td>
                                                            <td className="tb-tnx-amount is-alt">
                                                                <div className="tb-tnx-total">
                                                                    <span className="amount">{userPackage?.remaining_listings > 1000 ? "Unlimited" : userPackage?.remaining_listings}</span>
                                                                </div>
                                                                <div className="tb-tnx-status">
                                                                    <span className={`badge badge-dot badge-${userPackage?.active === true ? "success" : "warning"}`}>
                                                                        {userPackage?.active === true ? "Active" : "In-Active"}
                                                                    </span>
                                                                </div>
                                                            </td>
                                                            <td className="tb-tnx-action">
                                                                <UncontrolledDropdown>
                                                                    <DropdownToggle tag="a" className="text-soft dropdown-toggle btn btn-icon btn-trigger">
                                                                        <Icon name="more-h"></Icon>
                                                                    </DropdownToggle>
                                                                    <DropdownMenu right>
                                                                        <ul className="link-list-plain">
                                                                            {/* <li>
                                                                                <DropdownItem tag="a" href="#view" onClick={(ev) => { ev.preventDefault(); setModal({ viewDetail: true }); }}>
                                                                                    View Detail
                                                                                </DropdownItem>
                                                                            </li> */}
                                                                            <li>
                                                                                <DropdownItem tag="a" href="#view" onClick={(ev) => { ev.preventDefault(); setModal({ updateStatus: true }); setUserPackageId(userPackage?.id) }}>
                                                                                    Update Status
                                                                                </DropdownItem>
                                                                            </li>
                                                                            <li>
                                                                                <DropdownItem tag="a" href="#view" onClick={(ev) => { ev.preventDefault(); setModal({ resubscribe: true }); setUserPackageId(userPackage?.id) }}>
                                                                                    Re-Subscribe
                                                                                </DropdownItem>
                                                                            </li>
                                                                            <li>
                                                                                <DropdownItem tag="a" href="#print" onClick={(ev) => { ev.preventDefault(); setModal({ delete: true }); setUserPackageId(userPackage?.id) }}>
                                                                                    Delete User Package
                                                                                </DropdownItem>
                                                                            </li>
                                                                        </ul>
                                                                    </DropdownMenu>
                                                                </UncontrolledDropdown>
                                                            </td>
                                                        </tr>)
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </Card>
                        )}
                </Block>
            </Content>
            {modal.add && <AddUserPackageModal modal={modal} setModal={() => setModal({ add: false })} user_id={id} />}
            {modal.viewDetail && <ViewUserPackageDetailModal modal={modal.viewDetail} setModal={() => setModal({ viewDetail: false })} user_id={id} />}
            {modal.resubscribe && <ResubscribeUserPackageModal modal={modal.resubscribe} setModal={() => setModal({ resubscribe: false })} user_id={id} user_package_id={userPackageId} />}
            {modal.updateStatus && <UpdateUserPackageStatusModal modal={modal.updateStatus} setModal={() => setModal({ updateStatus: false })} user_package_id={userPackageId} user_id={id} />}
            {modal.delete && <DeleteUserPackageModal modal={modal.delete} setModal={() => setModal({ delete: false })} user_id={id} user_package_id={userPackageId} />}
        </React.Fragment>
    )
}

export default UserPackages;
