

import React from 'react'
import { useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { Button, Col, Form, FormGroup, Modal, ModalBody, Spinner } from 'reactstrap';
import { Icon } from '../../components/Component';
import { ChangeUserPasswordThunk, ResetChangeUserPasswordData } from '../../redux/user/changeUserPasswordSlice';
import { GetAllUserThunk } from '../../redux/user/GetAllUsersSlice';

const UpdateUserPasswordModal = ({ modal, setModal, email }) => {
    const dispatch = useDispatch();

    const { loading, success, error, errorMessage } = useSelector((state) => state.user.ChangeUserPassword);


    const handleUpdatePasswordForm = ({ newPassword }) => {
        dispatch(ChangeUserPasswordThunk({ password: newPassword, email }))
    }

    if (success) {
        toast.success("User's password has been updated successfully.");
        dispatch(ResetChangeUserPasswordData());
        dispatch(GetAllUserThunk());
        setModal(false);
    }

    if (error) {
        toast.error(errorMessage);
        dispatch(ResetChangeUserPasswordData());
        dispatch(GetAllUserThunk());
        setModal(false);
    }

    const { errors, register, handleSubmit } = useForm();

    return (
        <React.Fragment>
            <Modal isOpen={modal.updateUserPassword} toggle={() => setModal(false)} className="modal-dialog-centered" size="lg">
                <ModalBody>
                    <a
                        href="#cancel"
                        onClick={() => setModal(false)}
                        className="close"
                    >
                        <Icon name="cross-sm" />
                    </a>
                    <div className="p-2">
                        <h5 className="title">Update User Password</h5>
                        <div className="mt-4">
                            <Form className="row gy-4 mt-4" onSubmit={handleSubmit(handleUpdatePasswordForm)}>
                                <Col md="6">
                                    <FormGroup>
                                        <label className="form-label">New Password</label>
                                        <input className="form-control" ref={register({ required: "This field is required" })} type="password" name="newPassword" required />
                                        {errors.newPassword && <span className="invalid">{errors.newPassword.message}</span>}
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup>
                                        <label className="form-label">Confirm Password</label>
                                        <input className="form-control" ref={register({ required: "This field is required" })} type="password" name="confirmPassword" required />
                                        {errors.confirmPassword && <span className="invalid">{errors.confirmPassword.message}</span>}
                                    </FormGroup>
                                </Col>
                                <Col size="12">
                                    <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                                        <li>
                                            <Button disabled={loading} color="primary" size="md" type="submit">
                                                {loading ? <Spinner /> : "Update User Password"}
                                            </Button>
                                        </li>
                                        <li>
                                            <div onClick={() => setModal(false)} className="link link-light cursor-pointer">Cancel</div>
                                        </li>
                                    </ul>
                                </Col>
                            </Form>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </React.Fragment>
    )
}

export default UpdateUserPasswordModal;