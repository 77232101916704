import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { ApiEndpoints } from "../../utils/ApiEndPoints";

const ListingDetailThunk = createAsyncThunk("ListingDetail/ListingDetailThunk", async ({ listingId }, { rejectWithValue }) => {
    try {
        const response = await axios.get(`${ApiEndpoints.Listing.ListingDetail}?product_id=${listingId}`,
            {
                headers: {
                    "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
                }
            });
        return response.data;
    } catch (error) {
        console.log("error", error);
        const errorMessage = error?.response?.data?.message || "Something went wrong!";
        return rejectWithValue(errorMessage);
    }
});

const ListingDetailSlice = createSlice({
    name: "ListingDetail",
    initialState: {
        listingData: {},
        success: false,
        error: false,
        loading: false,
        errorMessage: "",
    },
    reducers: {
        ResetlistingData: (state, action) => {
            state.listingData = {};
            state.success = false;
            state.error = false;
            state.loading = false;
            state.errorMessage = "";
        },
    },
    extraReducers: {
        [ListingDetailThunk.pending]: (state, action) => {
            state.loading = true;
            state.success = false;
            state.error = false;
        },
        [ListingDetailThunk.fulfilled]: (state, action) => {
            state.listingData = action.payload;
            state.success = true;
            state.loading = false;
        },
        [ListingDetailThunk.rejected]: (state, action) => {
            state.errorMessage = action.payload;
            state.error = true;
            state.loading = false;
            state.listingData = {};
        },
    },
});

export default ListingDetailSlice.reducer;

export const { ResetlistingData } = ListingDetailSlice.actions;

export { ListingDetailThunk };
