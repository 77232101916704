import React from 'react'

import { useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Form, Modal, ModalBody, Spinner } from 'reactstrap';

import { Icon } from '../../../components/Component';
import { ResetUpdateUserPackageStatusData, UpdateUserPackageStatusThunk } from '../../../redux/user/UpdateUserPackageStatusSlice';
import { GetUserPackageThunk } from '../../../redux/user/GetUserPackageSlice';

const UpdateUserPackageStatusModal = ({ modal, setModal, user_package_id, user_id }) => {
    const dispatch = useDispatch();

    const { loading, success, error, errorMessage } = useSelector((state) => state.user.UpdateUserPackageStatus)
    const handleUpdateUserPackageStatus = (e) => {
        dispatch(UpdateUserPackageStatusThunk({ user_package_id }))
    }

    if (success) {
        toast.success("Package status has been updated successfullly.");
        dispatch(ResetUpdateUserPackageStatusData());
        dispatch(GetUserPackageThunk({ id: user_id }))
        setModal(false);
    }

    if (error) {
        toast.error(errorMessage);
        dispatch(ResetUpdateUserPackageStatusData());
        dispatch(GetUserPackageThunk({ id: user_id }))
        setModal(false);
    }

    const { handleSubmit } = useForm();

    return (
        <React.Fragment>
            <Modal
                isOpen={modal}
                toggle={() => setModal(false)}
                className="modal-dialog-centered"
                size="md"
            >
                <ModalBody>
                    <a href="#cancel" className="close" onClick={(ev) => { ev.preventDefault(); setModal(false); }}>
                        <Icon name="cross-sm"></Icon>
                    </a>
                    <div className="p-2">
                        <h4 className="title">Update User's Package Status</h4>
                        <div className="mt-4">
                            <Form className="row gy-4 mt-4 mb-5" onSubmit={handleSubmit(handleUpdateUserPackageStatus)} >
                                <Col md="12">
                                    <div>
                                        <p>Are you sure you want to update package status?</p>
                                    </div>
                                </Col>
                                <Col md="12">
                                    <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                                        <li>
                                            <Button disabled={loading} color="primary" size="md" type="submit">
                                                {loading ? <Spinner /> : "Update User Package Status"}
                                            </Button>
                                        </li>
                                        <li>
                                            <a
                                                href="#cancel"
                                                onClick={() => setModal(false)}
                                                className="link link-light"
                                            >
                                                Cancel
                                            </a>
                                        </li>
                                    </ul>
                                </Col>

                            </Form>
                        </div>
                    </div>
                </ModalBody>
            </Modal>

        </React.Fragment>
    )
}

export default UpdateUserPackageStatusModal;