import { combineReducers } from "@reduxjs/toolkit";
import DeleteListingSlice from "../listing/DeleteListingSlice";
import AddUserListingSlice from "./AddUserListingSlice";
import AddUserPackageSlice from "./AddUserPackageSlice";
import AddUserSlice from "./AddUserSlice";
import changeUserPasswordSlice from "./changeUserPasswordSlice";
import DeleteUserPackageSlice from "./DeleteUserPackageSlice";
import GetAllUsersSlice from "./GetAllUsersSlice";
import GetUserListingSlice from "./GetUserListingSlice";
import GetUserPackageSlice from "./GetUserPackageSlice";
import ResubscribeUserPackageSlice from "./ResubscribeUserPackageSlice";
import UpdateUserPackageStatusSlice from "./UpdateUserPackageStatusSlice";
import UserProfileSlice from "./UserProfileSlice";
import GetUserRentalProductSlice from "./GetUserRentalProductSlice";
import AddUserRentalProductSlice from "./AddUserRentalProductSlice";


export const userSlice = combineReducers({
    DeleteUser: DeleteListingSlice,
    GetAllUsers: GetAllUsersSlice,
    UserProfile: UserProfileSlice,
    AddUser: AddUserSlice,
    ChangeUserPassword: changeUserPasswordSlice,
    GetUserListing: GetUserListingSlice,
    GetUserPackage: GetUserPackageSlice,
    AddUserPackage: AddUserPackageSlice,
    UpdateUserPackageStatus: UpdateUserPackageStatusSlice,
    ResubscribeUserPackage: ResubscribeUserPackageSlice,
    DeleteUserPackage: DeleteUserPackageSlice,
    AddUserListing: AddUserListingSlice,
    GetUserRentalProduct: GetUserRentalProductSlice,
    AddUserRentalProduct: AddUserRentalProductSlice
});