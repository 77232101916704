import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiEndpoints } from "../../utils/ApiEndPoints";

const UpdateRentalCategoryDetailThunk = createAsyncThunk("UpdateRentalCategoryDetail/UpdateRentalCategoryDetailThunk", async ({ name, description, images, id }, { rejectWithValue }) => {
    const formData = new FormData();
    formData.append("name", name);
    formData.append("id", id);
    formData.append("description", description);
    if (images?.length !== 0)
        for (let i = 0; i < images.length; i++) {
            formData.append("category", images[i].file);
        }

    try {
        const response = await axios.put(ApiEndpoints.RentalCategory.UpdateRentalCategory,
            formData,
            {
                headers: {
                    "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
                }
            });
        return response.data;
    } catch (error) {
        console.log("error", error);
        return rejectWithValue(error.response.data);
    }
});

const UpdateRentalCategoryDetailSlice = createSlice({
    name: "UpdateRentalCategoryDetail",
    initialState: {
        UpdateRentalCategoryDetailData: {},
        success: false,
        error: false,
        loading: false,
        errorMessage: "",
    },
    reducers: {
        ResetUpdateRentalCategoryDetailData: (state, action) => {
            state.UpdateRentalCategoryDetailData = {};
            state.success = false;
            state.error = false;
            state.loading = false;
            state.errorMessage = "";
        },
    },
    extraReducers: {
        [UpdateRentalCategoryDetailThunk.pending]: (state, action) => {
            state.loading = true;
            state.success = false;
            state.error = false;
        },
        [UpdateRentalCategoryDetailThunk.fulfilled]: (state, action) => {
            state.UpdateRentalCategoryDetailData = action.payload;
            state.success = true;
            state.loading = false;
        },
        [UpdateRentalCategoryDetailThunk.rejected]: (state, action) => {
            state.errorMessage = action.payload.message;
            state.error = true;
            state.loading = false;
            state.UpdateRentalCategoryDetailData = {};
        },
    },
});

export default UpdateRentalCategoryDetailSlice.reducer;

export const { ResetUpdateRentalCategoryDetailData } = UpdateRentalCategoryDetailSlice.actions;

export { UpdateRentalCategoryDetailThunk };
