

import React from 'react'
import { useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { Button, Col, Form, FormGroup, Modal, ModalBody, Spinner } from 'reactstrap';

import { Icon } from '../../components/Component';
import { AddUserThunk, ResetAddUserData } from '../../redux/user/AddUserSlice';
import { GetAllUserThunk } from '../../redux/user/GetAllUsersSlice';

const AddUserModal = ({ modal, setModal }) => {
    const dispatch = useDispatch();

    const { loading, success, error, errorMessage, addUserData } = useSelector((state) => state.user.AddUser);


    const handleAddUserForm = ({ username, email, password, phoneNumber }) => {
        dispatch(AddUserThunk({
            username, email, password, phoneNumber
        }))
    }

    if (success) {
        toast.success("User has been added successfully.");
        dispatch(ResetAddUserData());
        dispatch(GetAllUserThunk());
        setModal(false);
    }

    if (error) {
        toast.error(errorMessage);
        dispatch(ResetAddUserData());
        dispatch(GetAllUserThunk());
        setModal(false);
    }

    const { errors, register, handleSubmit } = useForm();

    return (
        <React.Fragment>
            <Modal isOpen={modal.add} toggle={() => setModal(false)} className="modal-dialog-centered" size="lg">
                <ModalBody>
                    <a
                        href="#cancel"
                        onClick={() => setModal(false)}
                        className="close"
                    >
                        <Icon name="cross-sm"></Icon>
                    </a>
                    <div className="p-2">
                        <h5 className="title">Add User</h5>
                        <div className="mt-4">
                            <Form className="row gy-4 mt-4" onSubmit={handleSubmit(handleAddUserForm)}>
                                <Col md="6">
                                    <FormGroup>
                                        <label className="form-label">User Name</label>
                                        <input className="form-control" ref={register({ required: "This field is required" })} type="text" name="username" required />
                                        {errors.username && <span className="invalid">{errors.username.message}</span>}
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup>
                                        <label className="form-label">Email</label>
                                        <input className="form-control" ref={register({ required: "This field is required" })} type="text" name="email" required />
                                        {errors.email && <span className="invalid">{errors.email.message}</span>}
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup>
                                        <label className="form-label">Phone Number</label>
                                        <input className="form-control" ref={register({ required: "This field is required" })} type="text" name="phoneNumber" required />
                                        {errors.phoneNumber && <span className="invalid">{errors.phoneNumber.message}</span>}
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup>
                                        <label className="form-label">Password</label>
                                        <input className="form-control" ref={register({ required: "This field is required" })} type="password" name="password" required />
                                        {errors.password && <span className="invalid">{errors.password.message}</span>}
                                    </FormGroup>
                                </Col>

                                <Col md="12">
                                    <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                                        <li>
                                            <Button disabled={loading} color="primary" size="md" type="submit">
                                                {loading ? <Spinner /> : "Add User"}
                                            </Button>
                                        </li>
                                        <li>
                                            <a
                                                href="#cancel"
                                                onClick={() => setModal(false)}
                                                className="link link-light"
                                            >
                                                Cancel
                                            </a>
                                        </li>
                                    </ul>
                                </Col>
                            </Form>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </React.Fragment>
    )
}

export default AddUserModal;