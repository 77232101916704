import React, { useState, useEffect } from "react";

import { UncontrolledDropdown, DropdownMenu, DropdownToggle, Card, DropdownItem, Spinner } from "reactstrap";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import { Button, Block, BlockBetween, BlockDes, BlockHead, BlockHeadContent, BlockTitle, Icon } from "../../components/Component";
import AddPackageModal from "./AddPackageModal";
import { GetAllPackageThunk } from "../../redux/package/GetAllPackageSlice";
import UpdatePackageDetailModal from "./UpdatePackageDetailModal";
import UpdatePackageStatusModal from "./UpdatePackageStatusModal";

const Packages = () => {
    const dispatch = useDispatch();
    const [isPackageActive, setIsPackageActive] = useState();
    const [selectedPackageDetail, setSelectedPackageDetail] = useState({});
    const [modal, setModal] = useState({
        add: false,
        updatePackageDetail: false,
        updatePackageStatus: false
    });

    const { loading, error, allPackageData } = useSelector((state) => state.package.getAllPackage)

    useEffect(() => {
        dispatch(GetAllPackageThunk());
    }, [dispatch]);

    return (

        <React.Fragment>
            <Head title="Packages - Yellowish Admin"></Head>
            {loading ?
                <div className="d-flex justify-content-center align-items-center w-100" style={{ height: "80vh" }}>
                    <Spinner />
                </div>
                : error ?
                    <div className="text-center">
                        <span className="text-silent">No data found</span>
                    </div>
                    : (
                        <Content>
                            <BlockHead size="sm">
                                <BlockBetween>
                                    <BlockHeadContent>
                                        <BlockTitle page>Packages</BlockTitle>
                                        <BlockDes className="text-soft">
                                            <p>You have total {allPackageData?.data?.length} packages.</p>
                                        </BlockDes>
                                    </BlockHeadContent>
                                    <BlockHeadContent>
                                        <ul className="nk-block-tools g-3">
                                            <li>
                                                <Button color="primary" onClick={() => setModal({ add: true })}>
                                                    <Icon name="plus"></Icon>
                                                    <span>Add Package</span>
                                                </Button>
                                            </li>
                                        </ul>
                                    </BlockHeadContent>
                                </BlockBetween>
                            </BlockHead>

                            <Block>
                                <Card className="card-bordered card-stretch">
                                    <div className="card-inner-group">
                                        {/* <div className="card-inner">
                                            <div className="card-title-group">
                                                <div className="card-title">
                                                    <h5 className="title">All Packages</h5>
                                                </div>
                                            </div>
                                        </div> */}
                                        <div className="card-inner p-0">
                                            <table className="table table-tranx">
                                                <thead>
                                                    <tr className="tb-tnx-head">
                                                        <th className="tb-tnx-id">
                                                            <span className="">#</span>
                                                        </th>
                                                        <th className="tb-tnx-info">
                                                            <span className="tb-tnx-desc d-none d-sm-inline-block">
                                                                <span>Package Name</span>
                                                            </span>
                                                            <span className="tb-tnx-date d-md-inline-block d-none">
                                                                <span className="d-md-none">Date</span>
                                                                <span className="d-none d-md-block">
                                                                    <span>Validity in days</span>
                                                                </span>
                                                            </span>
                                                        </th>
                                                        <th className="tb-tnx-amount is-alt">
                                                            <span className="tb-tnx-total">price</span>
                                                            <span className="tb-tnx-status d-none d-md-inline-block">Status</span>
                                                        </th>
                                                        <th className="tb-tnx-action">
                                                            <span>Action</span>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {allPackageData?.data?.map((packageData, index) => {
                                                        return (
                                                            <tr className="tb-tnx-item">
                                                                <td className="tb-tnx-id">
                                                                    <a href="#ref" onClick={(ev) => { ev.preventDefault(); }} >
                                                                        <span>{index + 1}</span>
                                                                    </a>
                                                                </td>
                                                                <td className="tb-tnx-info">
                                                                    <div className="tb-tnx-desc">
                                                                        <span className="title">{packageData?.name}</span>
                                                                    </div>
                                                                    <div className="tb-tnx-date">
                                                                        <span className="date">{packageData?.validity}</span>
                                                                        {/* <span className="date">due</span> */}
                                                                    </div>
                                                                </td>
                                                                <td className="tb-tnx-amount is-alt">
                                                                    <div className="tb-tnx-total">
                                                                        <span className="amount">R {parseFloat(packageData?.price).toFixed(2)}</span>
                                                                    </div>
                                                                    <div className="tb-tnx-status">
                                                                        <span className={`badge badge-dot badge-${packageData?.active ? "success" : "warning"}`}>
                                                                            {packageData?.active ? "Active" : "Inactive"}
                                                                        </span>
                                                                    </div>
                                                                </td>
                                                                <td className="tb-tnx-action">
                                                                    <UncontrolledDropdown>
                                                                        <DropdownToggle tag="a" className="text-soft dropdown-toggle btn btn-icon btn-trigger">
                                                                            <Icon name="more-h"></Icon>
                                                                        </DropdownToggle>
                                                                        <DropdownMenu right>
                                                                            <ul className="link-list-plain">
                                                                                <li>
                                                                                    <DropdownItem tag="a" href="#view" onClick={(ev) => { ev.preventDefault(); setModal({ updatePackageDetail: true }); setSelectedPackageDetail(packageData) }}>
                                                                                        Update Package Detail
                                                                                    </DropdownItem>
                                                                                </li>
                                                                                <li>
                                                                                    <DropdownItem tag="a" href="#print" onClick={(ev) => { ev.preventDefault(); setModal({ updatePackageStatus: true }); setIsPackageActive(packageData?.active); setSelectedPackageDetail(packageData) }}>
                                                                                        Update Package Status
                                                                                    </DropdownItem>
                                                                                </li>
                                                                            </ul>
                                                                        </DropdownMenu>
                                                                    </UncontrolledDropdown>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </Card>
                            </Block>
                        </Content>
                    )}

            {modal.add && <AddPackageModal modal={modal} setModal={() => setModal({ add: false })} />}
            {modal.updatePackageDetail && <UpdatePackageDetailModal modal={modal} setModal={() => setModal({ updatePackageDetail: false })} packageDetail={selectedPackageDetail} />}
            {modal.updatePackageStatus && <UpdatePackageStatusModal modal={modal} setModal={() => setModal({ updatePackageStatus: false })} isActive={isPackageActive} id={selectedPackageDetail?.id} />}

        </React.Fragment>
    );
};

export default Packages;
