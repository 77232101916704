import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiEndpoints } from "../../utils/ApiEndPoints";

const ChangeUserPasswordThunk = createAsyncThunk("ChangeUserPassword/ChangeUserPasswordThunk", async ({ email, password }, { rejectWithValue }) => {
    try {
        const response = await axios.put(ApiEndpoints.User.ChangeUserPassword,
            { email, password },
            {
                headers: {
                    "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
                }
            });
        return response.data;
    } catch (error) {
        console.log("error", error);
        const errorMessage = error?.response?.data?.message || "Something went wrong!";
        return rejectWithValue(errorMessage);
    }
});

const ChangeUserPasswordSlice = createSlice({
    name: "ChangeUserPassword",
    initialState: {
        ChangeUserPasswordData: {},
        success: false,
        error: false,
        loading: false,
        errorMessage: "",
    },
    reducers: {
        ResetChangeUserPasswordData: (state, action) => {
            state.ChangeUserPasswordData = {};
            state.success = false;
            state.error = false;
            state.loading = false;
            state.errorMessage = "";
        },
    },
    extraReducers: {
        [ChangeUserPasswordThunk.pending]: (state, action) => {
            state.loading = true;
            state.success = false;
            state.error = false;
        },
        [ChangeUserPasswordThunk.fulfilled]: (state, action) => {
            state.ChangeUserPasswordData = action.payload;
            state.success = true;
            state.loading = false;
        },
        [ChangeUserPasswordThunk.rejected]: (state, action) => {
            state.errorMessage = action.payload;
            state.error = true;
            state.loading = false;
            state.ChangeUserPasswordData = {};
        },
    },
});

export default ChangeUserPasswordSlice.reducer;

export const { ResetChangeUserPasswordData } = ChangeUserPasswordSlice.actions;

export { ChangeUserPasswordThunk };
