import React, { useState, useEffect } from "react";

import { UncontrolledDropdown, DropdownMenu, DropdownToggle, Card, DropdownItem, Spinner } from "reactstrap";
import ReactPaginate from "react-paginate";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import { Button, Block, BlockBetween, BlockDes, BlockHead, BlockHeadContent, BlockTitle, Icon } from "../../components/Component";
import { FinanceQuotesThunk } from "../../redux/quotes/FinanceQuotesSlice";
import { ContactUsThunk } from "../../redux/ContactUs/GetAllContactUsFormSlice";

const ContactUs = () => {
    const dispatch = useDispatch();
    const [onSearch, setonSearch] = useState(true);
    const [onSearchText, setSearchText] = useState("");

    const { loading, success, error, errorMessage, ContactUsData } = useSelector((state) => state.contactUs.getAllContactUsList)

    // ********************************** Search and Pagination start ***********************************//

    const contactListPerPage = 10;
    const [allContactList, setAllContactList] = useState([]);
    const [pageNumber, setPageNumber] = useState(0);
    const pagesVisited = pageNumber * contactListPerPage;
    const pageCount = Math.ceil(
        allContactList.filter((contactList) => {
            if (onSearchText === "") {
                return contactList;
            } else if (
                contactList.fullName.toLowerCase().includes(onSearchText.toLowerCase()) ||
                contactList.phoneNumber.toLowerCase().includes(onSearchText.toLowerCase()) ||
                contactList.type.toLowerCase().includes(onSearchText.toLowerCase())
            ) {
                return contactList;
            }
            return false;
        }).length / contactListPerPage
    );

    const displayContactUsList = allContactList
        .filter((contactList) => {
            if (onSearchText === "") {
                return contactList;
            } else if (
                contactList.fullName.toLowerCase().includes(onSearchText.toLowerCase()) ||
                contactList.phoneNumber.toLowerCase().includes(onSearchText.toLowerCase()) ||
                contactList.type.toLowerCase().includes(onSearchText.toLowerCase())
            ) {
                return contactList;
            }
            return false;
        })
        .slice(pagesVisited, pagesVisited + contactListPerPage);

    useEffect(() => {
        setAllContactList(ContactUsData);
    }, [ContactUsData]);
    const paginate = ({ selected }) => setPageNumber(selected);

    useEffect(() => {
        setPageNumber(0);
    }, [onSearchText]);

    // ********************************** Pagination End ***********************************//

    useEffect(() => {
        dispatch(ContactUsThunk());
    }, [dispatch]);

    const toggle = () => setonSearch(!onSearch);

    return (
        <React.Fragment>
            <Head title="Finance Quote - Yellowish Admin"></Head>
            {loading ? <div className="d-flex justify-content-center align-items-center w-100" style={{ height: "80vh" }}>
                <Spinner />
            </div>
                : error ?
                    <div className="text-center">
                        <span className="text-silent">No data found</span>
                    </div>
                    : (

                        <Content>
                            <BlockHead size="sm">
                                <BlockBetween>
                                    <BlockHeadContent>
                                        <BlockTitle page>ContactUs</BlockTitle>
                                        <BlockDes className="text-soft">
                                            <p>You have total {ContactUsData?.length} forms.</p>
                                        </BlockDes>
                                    </BlockHeadContent>
                                </BlockBetween>
                            </BlockHead>

                            <Block>
                                <Card className="card-bordered card-stretch">
                                    <div className="card-inner-group">
                                        <div className="card-inner">
                                            <div className="card-title-group">
                                                <div className="card-title">
                                                    <h5 className="title">Contact Us Form</h5>
                                                </div>
                                                <div className="card-tools mr-n1">
                                                    <ul className="btn-toolbar">
                                                        <li>
                                                            <Button onClick={toggle} className="btn-icon search-toggle toggle-search">
                                                                <Icon name="search"></Icon>
                                                            </Button>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className={`card-search search-wrap ${!onSearch ? "active" : ""}`}>
                                                    <div className="search-content">
                                                        <Button className="search-back btn-icon toggle-search" onClick={() => { setSearchText(""); toggle(); }}>
                                                            <Icon name="arrow-left"></Icon>
                                                        </Button>
                                                        <input type="text" className="form-control border-transparent form-focus-none" placeholder="Search" value={onSearchText}
                                                        />
                                                        <Button className="search-submit btn-icon">
                                                            <Icon name="search"></Icon>
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-inner p-0">
                                            <table className="table table-tranx">
                                                <thead>
                                                    <tr className="tb-tnx-head">
                                                        <th className="tb-tnx-id">
                                                            <span className="">#</span>
                                                        </th>
                                                        <th className="tb-tnx-info">
                                                            <span className="tb-tnx-desc d-none d-sm-inline-block">
                                                                <span>Name</span>
                                                            </span>
                                                            <span className="tb-tnx-date d-md-inline-block d-none">
                                                                <span className="d-none d-md-block">
                                                                    <span>Email</span>
                                                                    <span>PhoneNumber</span>
                                                                </span>
                                                            </span>
                                                        </th>
                                                        <th className="tb-tnx-amount is-alt">
                                                            <span className="tb-tnx-total">Description</span>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {displayContactUsList?.map((financeQuoteData, index) => {
                                                        return (
                                                            <tr className="tb-tnx-item">
                                                                <td className="tb-tnx-id">
                                                                    <div >
                                                                        <span>{index + 1}</span>
                                                                    </div>
                                                                </td>
                                                                <td className="tb-tnx-info">
                                                                    <div className="tb-tnx-desc">
                                                                        <span className="title">{financeQuoteData?.first_name}</span>
                                                                    </div>
                                                                    <div className="tb-tnx-date">
                                                                        <span className="date">{financeQuoteData?.email}</span>
                                                                        <span className="date">{financeQuoteData?.phone}</span>
                                                                    </div>
                                                                </td>
                                                                <td className="tb-tnx-amount is-alt">
                                                                    <div className="tb-tnx-total" style={{ width: "450px" }}>
                                                                        <span className="amount">{financeQuoteData?.description}</span>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}

                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="card-inner">
                                            <ReactPaginate
                                                breakLabel="..."
                                                breakClassName="pagination-btns"
                                                onPageChange={(number) => paginate(number)}
                                                // onPageChange={handlePageClick}
                                                pageRangeDisplayed={3}
                                                pageCount={pageCount}
                                                previousLabel="<<  Prev "
                                                renderOnZeroPageCount={null}
                                                nextLabel="Next >>"
                                                previousClassName="pagination-next"
                                                nextClassName="pagination-next"
                                                activeClassName="pagination-active"
                                                pageClassName="pagination mt-0"
                                                pageLinkClassName="pagination-btns"
                                                containerClassName="pagination justify-content-start"
                                            />
                                            {/* <div className="text-center">
                                    <span className="text-silent">No data found</span>
                                </div> */}
                                        </div>
                                    </div>
                                </Card>
                            </Block>
                        </Content>
                    )
            }
        </React.Fragment>
    );
}

export default ContactUs;
