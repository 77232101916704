import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiEndpoints } from "../../utils/ApiEndPoints";

const ResubscribeUserPackageThunk = createAsyncThunk("ResubscribeUserPackage/ResubscribeUserPackageThunk", async ({ package_id }, { rejectWithValue }) => {
    try {
        const response = await axios.put(`${ApiEndpoints.User.ResubscribeUserPackage}?package_id=${package_id}`, {},
            {
                headers: {
                    "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
                }
            });
        return response.data;
    } catch (error) {
        console.log("error", error);
        const errorMessage = error?.response?.data?.message || "Something went wrong!";
        return rejectWithValue(errorMessage);
    }
});

const ResubscribeUserPackageSlice = createSlice({
    name: "ResubscribeUserPackage",
    initialState: {
        ResubscribeUserPackageData: {},
        success: false,
        error: false,
        loading: false,
        errorMessage: "",
    },
    reducers: {
        ResetResubscribeUserPackageData: (state, action) => {
            state.ResubscribeUserPackageData = {};
            state.success = false;
            state.error = false;
            state.loading = false;
            state.errorMessage = "";
        },
    },
    extraReducers: {
        [ResubscribeUserPackageThunk.pending]: (state, action) => {
            state.loading = true;
            state.success = false;
            state.error = false;
        },
        [ResubscribeUserPackageThunk.fulfilled]: (state, action) => {
            state.ResubscribeUserPackageData = action.payload;
            state.success = true;
            state.loading = false;
        },
        [ResubscribeUserPackageThunk.rejected]: (state, action) => {
            state.errorMessage = action.payload;
            state.error = true;
            state.loading = false;
            state.ResubscribeUserPackageData = {};
        },
    },
});

export default ResubscribeUserPackageSlice.reducer;

export const { ResetResubscribeUserPackageData } = ResubscribeUserPackageSlice.actions;

export { ResubscribeUserPackageThunk };
