import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiEndpoints } from "../../utils/ApiEndPoints";

const GetUserPackageThunk = createAsyncThunk("GetUserPackage/GetUserPackageThunk", async ({ id }, { rejectWithValue }) => {
    try {
        const response = await axios.get(`${ApiEndpoints.User.GetUserPackage}?user_id=${id}`,
            {
                headers: {
                    "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
                }
            });
        return response.data?.data;
    } catch (error) {
        console.log("error", error);
        const errorMessage = error.response.data.message ? error.response.data.message : "Something Went Wrong!";
        return rejectWithValue(errorMessage);
    }
});

const GetUserPackageSlice = createSlice({
    name: "GetUserPackage",
    initialState: {
        userPackageData: [],
        success: false,
        error: false,
        loading: false,
        errorMessage: "",
    },
    reducers: {
        ResetGetUserPackageData: (state, action) => {
            state.userPackageData = [];
            state.success = false;
            state.error = false;
            state.loading = false;
            state.errorMessage = "";
        },
    },
    extraReducers: {
        [GetUserPackageThunk.pending]: (state, action) => {
            state.loading = true;
            state.success = false;
            state.error = false;
        },
        [GetUserPackageThunk.fulfilled]: (state, action) => {
            state.userPackageData = action.payload;
            state.success = true;
            state.loading = false;
        },
        [GetUserPackageThunk.rejected]: (state, action) => {
            state.errorMessage = action.payload;
            state.error = true;
            state.loading = false;
            state.userPackageData = [];
        },
    },
});

export default GetUserPackageSlice.reducer;

export const { ResetGetUserPackageData } = GetUserPackageSlice.actions;

export { GetUserPackageThunk };
