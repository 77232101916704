import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiEndpoints } from "../../utils/ApiEndPoints";

const GetUserRentalProductThunk = createAsyncThunk("GetUserRentalProduct/GetUserRentalProductThunk", async ({ id }, { rejectWithValue }) => {
    try {
        const response = await axios.get(`${ApiEndpoints.User.GetUserRentalProduct}?user_id=${id}`,
            {
                headers: {
                    "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
                }
            });
        return response.data?.data;
    } catch (error) {
        console.log("error", error);
        const errorMessage = error.response.data.message ? error.response.data.message : "Something Went Wrong!";
        return rejectWithValue(errorMessage);
    }
});

const GetUserRentalProductSlice = createSlice({
    name: "GetUserRentalProduct",
    initialState: {
        userRentalProductData: [],
        success: false,
        error: false,
        loading: false,
        errorMessage: "",
    },
    reducers: {
        ResetGetUserRentalProductData: (state, action) => {
            state.userRentalProductData = [];
            state.success = false;
            state.error = false;
            state.loading = false;
            state.errorMessage = "";
        },
    },
    extraReducers: {
        [GetUserRentalProductThunk.pending]: (state, action) => {
            state.loading = true;
            state.success = false;
            state.error = false;
        },
        [GetUserRentalProductThunk.fulfilled]: (state, action) => {
            state.userRentalProductData = action.payload;
            state.success = true;
            state.loading = false;
        },
        [GetUserRentalProductThunk.rejected]: (state, action) => {
            state.errorMessage = action.payload;
            state.error = true;
            state.loading = false;
            state.userRentalProductData = [];
        },
    },
});

export default GetUserRentalProductSlice.reducer;

export const { ResetGetUserRentalProductData } = GetUserRentalProductSlice.actions;

export { GetUserRentalProductThunk };
