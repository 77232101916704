import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiEndpoints } from "../../utils/ApiEndPoints";

const DeleteRentalProductThunk = createAsyncThunk("DeleteRentalProduct/DeleteRentalProductThunk", async ({ rentalProductId }, { rejectWithValue }) => {
    try {
        const response = await axios.delete(`${ApiEndpoints.RentalProduct.DeleteRentalProduct}?product_id=${rentalProductId}`,
            {
                headers: {
                    "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
                }
            });
        return response.data;
    } catch (error) {
        console.log("error", error);
        const errorMessage = error?.response?.data?.message || "Something went wrong!";
        return rejectWithValue(errorMessage);
    }
});

const DeleteRentalProductSlice = createSlice({
    name: "DeleteRentalProduct",
    initialState: {
        deleteRentalProductData: {},
        success: false,
        error: false,
        loading: false,
        errorMessage: "",
    },
    reducers: {
        ResetDeleteRentalProductData: (state, action) => {
            state.deleteRentalProductData = {};
            state.success = false;
            state.error = false;
            state.loading = false;
            state.errorMessage = "";
        },
    },
    extraReducers: {
        [DeleteRentalProductThunk.pending]: (state, action) => {
            state.loading = true;
            state.success = false;
            state.error = false;
        },
        [DeleteRentalProductThunk.fulfilled]: (state, action) => {
            state.deleteRentalProductData = action.payload;
            state.success = true;
            state.loading = false;
        },
        [DeleteRentalProductThunk.rejected]: (state, action) => {
            state.errorMessage = action.payload;
            state.error = true;
            state.loading = false;
            state.deleteRentalProductData = {};
        },
    },
});

export default DeleteRentalProductSlice.reducer;

export const { ResetDeleteRentalProductData } = DeleteRentalProductSlice.actions;

export { DeleteRentalProductThunk };
