import { combineReducers } from "@reduxjs/toolkit";
import ProductImageReOrderSlice from "./RentalProductImageReOrderSlice";
import GetAllRentalProductsSlice from "./GetAllRentalProductsSlice";
import AddRentalProductSlice from "./AddRentalProductSlice";
import UpdateRentalProductStatusSlice from "./UpdateRentalProductStatusSlice";
import RentalProductDetailSlice from "./RentalProductDetailSlice";
import UpdateRentalProductDetailSlice from "./UpdateRentalProductDetailSlice";
import DeleteRentalProductSlice from "./DeleteRentalProductSlice";


export const rentalProductSlice = combineReducers({
    getAllRentalProducts: GetAllRentalProductsSlice,
    rentalProductDetail: RentalProductDetailSlice,
    addRentalProduct: AddRentalProductSlice,
    deleteRentralProduct: DeleteRentalProductSlice,
    updateRentalProductStatus: UpdateRentalProductStatusSlice,
    updateRentalProductDetail: UpdateRentalProductDetailSlice,
    listingImagesReOrder: ProductImageReOrderSlice
});