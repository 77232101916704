import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiEndpoints } from "../../utils/ApiEndPoints";

const UpdateAdvertisementThunk = createAsyncThunk("UpdateAdvertisement/UpdateAdvertisementThunk", async ({ ad_link, web_page_id, image }, { rejectWithValue }) => {
    const formData = new FormData();
    formData.append("ad_link", ad_link);
    formData.append("id", web_page_id);
    for (let i = 0; i < image.length; i++) {
        formData.append("advertisement", image[i].file);
    }
    try {
        const response = await axios.put(ApiEndpoints.Advertisement.UpdateAdvertisement,
            formData,
            {
                headers: {
                    "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
                }
            });
        return response.data;
    } catch (error) {
        console.log('error', error);
        const errorMessage = error.response.data.message ? error.response.data.message : "Something Went Wrong!";
        return rejectWithValue(errorMessage);
    }
});

const UpdateAdvertisementSlice = createSlice({
    name: "UpdateAdvertisement",
    initialState: {
        updateAdvertisementData: {},
        success: false,
        error: false,
        loading: false,
        errorMessage: "",
    },
    reducers: {
        ResetUpdateAdvertisementData: (state, action) => {
            state.updateAdvertisementData = {};
            state.success = false;
            state.error = false;
            state.loading = false;
            state.errorMessage = "";
        },
    },
    extraReducers: {
        [UpdateAdvertisementThunk.pending]: (state, action) => {
            state.loading = true;
            state.success = false;
            state.error = false;
        },
        [UpdateAdvertisementThunk.fulfilled]: (state, action) => {
            state.updateAdvertisementData = action.payload;
            state.success = true;
            state.loading = false;
        },
        [UpdateAdvertisementThunk.rejected]: (state, action) => {
            state.errorMessage = action.payload;
            state.error = true;
            state.loading = false;
            state.updateAdvertisementData = {};
        },
    },
});

export default UpdateAdvertisementSlice.reducer;

export const { ResetUpdateAdvertisementData } = UpdateAdvertisementSlice.actions;

export { UpdateAdvertisementThunk };
