import React, { useState } from 'react'

import { useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import ReactImageUploading from 'react-images-uploading';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { Button, Col, Form, FormGroup, Modal, ModalBody, Row, Spinner } from 'reactstrap';
import { Icon } from '../../components/Component';
import { AddCategoryThunk, ResetAddCategoryData } from '../../redux/Category/AddCategorySlice';
import { GetAllCategoryThunk } from '../../redux/Category/GetAllCategorySlice';

const AddCategoryModal = ({ modal, setModal }) => {
    const dispatch = useDispatch();

    const [images, setImages] = useState();

    const { loading, success, error, errorMessage } = useSelector((state) => state.category.addCategory);

    const onChange = (imageList) => {
        setImages(imageList);
    };

    const handleAddPackageForm = ({ name, description }) => {
        dispatch(AddCategoryThunk({ name, description, images }))
    }

    if (success) {
        toast.success("Category has been added successfully.");
        dispatch(GetAllCategoryThunk());
        dispatch(ResetAddCategoryData());
        setModal();
    }

    if (error) {
        toast.error(errorMessage);
        dispatch(GetAllCategoryThunk());
        dispatch(ResetAddCategoryData());
        setModal();
    }

    const { errors, register, handleSubmit } = useForm();

    return (
        <React.Fragment>
            <Modal isOpen={modal.add} toggle={setModal} className="modal-dialog-centered" size="lg">
                <ModalBody>
                    <a
                        href="#cancel"
                        onClick={setModal}
                        className="close"
                    >
                        <Icon name="cross-sm"></Icon>
                    </a>
                    <div className="p-2">
                        <h5 className="title">Add Category</h5>
                        <div className="mt-4">
                            <Form className="row gy-4 mt-4" onSubmit={handleSubmit(handleAddPackageForm)}>

                                <Col md="12">
                                    <FormGroup>
                                        <label className="form-label">Image Gallery</label>
                                        <ReactImageUploading
                                            className="form-control"
                                            multiple
                                            value={images}
                                            onChange={onChange}
                                            maxNumber={10}
                                            dataURLKey="data_url"
                                        >
                                            {({ imageList, onImageUpload, onImageRemove, isDragging, dragProps }) => (
                                                <div className="upload__image-wrapper">
                                                    <Button
                                                        style={isDragging ? { color: "red" } : undefined}
                                                        onClick={onImageUpload}
                                                        color="primary"
                                                        className="form-bt2 color-black900"
                                                        {...dragProps}
                                                    >
                                                        Click or Drop here
                                                    </Button>
                                                    &nbsp;
                                                    <div className="row image-item">
                                                        <Row>
                                                            {imageList.map((image, index) => (
                                                                <Col className="m-2" md="3">
                                                                    <img src={image["data_url"]} alt="" width="120" height="100" />
                                                                    <Button
                                                                        className="mt-1"
                                                                        color='primary'
                                                                        onClick={() => onImageRemove(index)}
                                                                    >
                                                                        Remove
                                                                    </Button>
                                                                </Col>
                                                            ))}
                                                        </Row>
                                                    </div>
                                                </div>
                                            )}
                                        </ReactImageUploading>

                                        {errors.image_gallery && <span className="invalid">{errors.image_gallery.message}</span>}
                                    </FormGroup>
                                </Col>

                                <Col md="12">
                                    <FormGroup>
                                        <label className="form-label">Category Name</label>
                                        <input className="form-control" ref={register({ required: "This field is required" })} type="text" name="name" required
                                        />
                                        {errors.name && <span className="invalid">{errors.name.message}</span>}
                                    </FormGroup>
                                </Col>
                                <Col md="12">
                                    <FormGroup>
                                        <label className="form-label">Description</label>
                                        <textarea className="form-control" ref={register({ required: "This field is required" })} type="text" name="description" required
                                        />
                                        {errors.description && <span className="invalid">{errors.description.message}</span>}
                                    </FormGroup>
                                </Col>
                                <Col md="12">
                                    <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                                        <li>
                                            <Button color="primary" disabled={loading} size="md" type="submit">
                                                {loading ? <Spinner size="md" /> : "Add Category"}
                                            </Button>
                                        </li>
                                        <li>
                                            <a
                                                href="#cancel"
                                                onClick={() => setModal(false)}
                                                className="link link-light"
                                            >
                                                Cancel
                                            </a>
                                        </li>
                                    </ul>
                                </Col>
                            </Form>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </React.Fragment>
    )
}

export default AddCategoryModal;