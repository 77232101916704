import React, { useState, useEffect } from "react";

import { UncontrolledDropdown, DropdownMenu, DropdownToggle, Card, DropdownItem, Spinner } from "reactstrap";
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";

import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import { Button, Block, BlockBetween, BlockDes, BlockHead, BlockHeadContent, BlockTitle, Icon, UserAvatar } from "../../components/Component";
import { GetAllListingThunk } from "../../redux/listing/GetAllListingSlice";
import AddListingModal from "./AddListingModal";
import UpdateListingStatusModal from "./UpdateListingStatusModal";
import DeleteListingModal from "./DeleteListingModal";
import UpdateListingDetailModal from "./UpdateListingDetailModal";
import { findUpper } from "../../utils/Utils";
import ProductImageReOrderModal from "./ProductImageReOrderModal";

const AllListing = () => {
	const dispatch = useDispatch();
	const [onSearch, setonSearch] = useState(true);
	const [onSearchText, setSearchText] = useState("");
	const [selectedListingId, setSelectedListingId] = useState("");
	const [listingDetail, setListingDetail] = useState({});
	const [modal, setModal] = useState({
		add: false,
		updateListingDetail: false,
		changeListingStatus: false,
		deleteListing: false,
		productImagesReOrder: false
	});

	const { loading, success, error, errorMessage, allListingData } = useSelector((state) => state.listing.getAllListing)

	// ********************************** Search and Pagination start ***********************************//

	const listingsPerPage = 10;
	const [allListing, setListing] = useState([]);
	const [pageNumber, setPageNumber] = useState(0);
	const pagesVisited = pageNumber * listingsPerPage;
	const pageCount = Math.ceil(
		allListing?.filter((listing) => {
			if (onSearchText === "") {
				return listing;
			} else if (
				listing.make.toLowerCase().includes(onSearchText.toLowerCase()) ||
				listing.model.toLowerCase().includes(onSearchText.toLowerCase()) ||
				listing.price.toLowerCase().includes(onSearchText.toLowerCase())
			) {
				return listing;
			}
			return false;
		}).length / listingsPerPage
	);

	const displayListing = allListing
		?.filter((listing) => {
			if (onSearchText === "") {
				return listing;
			} else if (
				listing.make.toLowerCase().includes(onSearchText.toLowerCase()) ||
				listing.model.toLowerCase().includes(onSearchText.toLowerCase()) ||
				listing.price.toLowerCase().includes(onSearchText.toLowerCase())
			) {
				return listing;
			}
			return false;
		})
		.slice(pagesVisited, pagesVisited + listingsPerPage);

	useEffect(() => {
		setListing(allListingData?.data);
	}, [allListingData?.data]);
	const paginate = ({ selected }) => setPageNumber(selected);

	useEffect(() => {
		setPageNumber(0);
	}, [onSearchText]);

	// ********************************** Pagination End ***********************************//

	useEffect(() => {
		dispatch(GetAllListingThunk());
	}, [dispatch]);

	const toggle = () => setonSearch(!onSearch);

	return (
		<React.Fragment>
			<Head title="Listings - Yellowish Admin"></Head>
			<Content>
				<BlockHead size="sm">
					<BlockBetween>
						<BlockHeadContent>
							<BlockTitle page>Listing</BlockTitle>
							<BlockDes className="text-soft">
								<p>You have total {allListingData?.data?.length} listings.</p>
							</BlockDes>
						</BlockHeadContent>
						<BlockHeadContent>
							<Button color="primary" onClick={() => setModal({ add: true })}>
								<Icon name="plus" />
								<span>Add Listing</span>
							</Button>
						</BlockHeadContent>
					</BlockBetween>
				</BlockHead>
				<Block>
					{loading ?
						<div className="d-flex justify-content-center align-items-center w-100" style={{ height: "60vh" }}>
							<Spinner />
						</div>
						:
						<Card className="card-bordered card-stretch">
							<div className="card-inner-group">
								<div className="card-inner">
									<div className="card-title-group">
										<div className="card-title">
											<h5 className="title">All Listings</h5>
										</div>
										<div className="card-tools mr-n1">
											<ul className="btn-toolbar">
												<li>
													<Button onClick={toggle} className="btn-icon search-toggle toggle-search">
														<Icon name="search"></Icon>
													</Button>
												</li>
											</ul>
										</div>
										<div className={`card-search search-wrap ${!onSearch ? "active" : ""}`}>
											<div className="search-content">
												<Button className="search-back btn-icon toggle-search" onClick={() => { setSearchText(""); toggle(); }}>
													<Icon name="arrow-left"></Icon>
												</Button>
												<input type="text" className="form-control border-transparent form-focus-none" placeholder="Search" value={onSearchText} onChange={(e) => { e.preventDefault(); setSearchText(e.target.value); }}
												/>
												<Button className="search-submit btn-icon">
													<Icon name="search"></Icon>
												</Button>
											</div>
										</div>
									</div>
								</div>
								<div className="card-inner p-0">
									<table className="table table-tranx">
										<thead>
											<tr className="tb-tnx-head">
												<th className="tb-tnx-id">
													<span className="">#</span>
												</th>
												<th className="tb-tnx-info">
													<span className="tb-tnx-desc d-none d-sm-inline-block">
														<span>Product Image</span>
													</span>
													<span className="tb-tnx-date d-md-inline-block d-none">
														{/* <span className="d-md-none">Make</span> */}
														<span className="d-none d-md-block">
															<span>Phone Number</span>
															<span>Email</span>
														</span>
													</span>
												</th>
												<th className="tb-tnx-amount is-alt">
													<span className="tb-tnx-total">price</span>
													<span className="tb-tnx-status d-none d-md-inline-block">Status</span>
												</th>
												<th className="tb-tnx-action">
													<span>Action</span>
												</th>
											</tr>
										</thead>
										<tbody>
											{
												displayListing?.length === 0 ?
													<div className="text-center">
														<span className="text-silent">No data found</span>
													</div>
													: (
														displayListing?.map((listingData, index) => {
															return (
																<tr className="tb-tnx-item">
																	<td className="tb-tnx-id">
																		<a href="#ref" onClick={(ev) => { ev.preventDefault(); }} >
																			<span>{pageNumber * listingsPerPage + index + 1}</span>
																		</a>
																	</td>
																	<td className="tb-tnx-info">
																		<div className="tb-tnx-desc">
																			<img src={listingData?.main_image} alt="" width="190" height="120" />
																		</div>
																		<div className="tb-tnx-date">
																			<span className="date">{listingData?.User?.phone_number}</span>
																			<span className="date">{listingData?.User?.email}</span>
																		</div>
																	</td>
																	<td className="tb-tnx-amount is-alt">
																		<div className="tb-tnx-total">
																			<span className="amount">{listingData?.price}</span>
																		</div>
																		<div className="tb-tnx-status">
																			<span className={`badge badge-dot badge-${listingData?.approved === true ? "success" : "warning"}`}>
																				{listingData?.approved ? "Approved" : "Pending"}
																			</span>
																		</div>
																	</td>
																	<td className="tb-tnx-action">
																		<UncontrolledDropdown>
																			<DropdownToggle tag="a" className="text-soft dropdown-toggle btn btn-icon btn-trigger">
																				<Icon name="more-h"></Icon>
																			</DropdownToggle>
																			<DropdownMenu right>
																				<ul className="link-list-plain">
																					<li>
																						<DropdownItem tag="a" href="#view" onClick={(ev) => { ev.preventDefault(); setModal({ updateListingDetail: true }); setListingDetail(listingData); setSelectedListingId(listingData?.product_id) }}>
																							Edit Detail
																						</DropdownItem>
																					</li>
																					<li>
																						<DropdownItem tag="a" href="#view" onClick={(ev) => { ev.preventDefault(); setModal({ productImagesReOrder: true }); setListingDetail(listingData); setSelectedListingId(listingData?.product_id) }}>
																							Product Images ReOrder
																						</DropdownItem>
																					</li>
																					<li>
																						<DropdownItem tag="a" href="#view" onClick={(ev) => { ev.preventDefault(); setModal({ changeListingStatus: true }); setSelectedListingId(listingData?.product_id) }}>
																							Update Status
																						</DropdownItem>
																					</li>
																					<li>
																						<DropdownItem tag="a" href="#print" onClick={(ev) => { ev.preventDefault(); setModal({ deleteListing: true }); setSelectedListingId(listingData?.product_id) }}>
																							Delete Listing
																						</DropdownItem>
																					</li>
																				</ul>
																			</DropdownMenu>
																		</UncontrolledDropdown>
																	</td>
																</tr>
															)
														})
													)
											}
										</tbody>
									</table>
								</div>
								<div className="card-inner">
									<ReactPaginate
										breakLabel="..."
										breakClassName="pagination-btns"
										onPageChange={(number) => paginate(number)}
										// onPageChange={handlePageClick}
										pageRangeDisplayed={3}
										pageCount={pageCount}
										previousLabel="<<  Prev "
										renderOnZeroPageCount={null}
										nextLabel="Next >>"
										previousClassName="pagination-next"
										nextClassName="pagination-next"
										activeClassName="pagination-active"
										pageClassName="pagination mt-0"
										pageLinkClassName="pagination-btns"
										containerClassName="pagination justify-content-start"
									/>
									{/* <div className="text-center">
                                    <span className="text-silent">No data found</span>
                                </div> */}
								</div>
							</div>
						</Card>
					}
				</Block>
			</Content>
			{modal.add && <AddListingModal modal={modal} setModal={() => setModal({ add: false })} />}
			{modal.updateListingDetail && <UpdateListingDetailModal modal={modal} setModal={() => setModal({ updateListingDetail: false })} listingDetail={listingDetail} listingId={selectedListingId} />}
			{modal.productImagesReOrder && <ProductImageReOrderModal modal={modal} setModal={() => setModal({ productImagesReOrder: false })} listingDetail={listingDetail} productId={selectedListingId} productData={listingDetail} />}
			{modal.changeListingStatus && <UpdateListingStatusModal modal={modal} setModal={() => setModal({ changeListingStatus: false })} listingId={selectedListingId} />}
			{modal.deleteListing && <DeleteListingModal modal={modal} setModal={() => setModal({ deleteListing: false })} listingId={selectedListingId} />}
		</React.Fragment>
	);
};

export default AllListing;
