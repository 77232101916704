import { combineReducers } from "@reduxjs/toolkit";
import AddAdvertisementSlice from "./AddAdvertisementSlice";
import AdsImagesReOrderSlice from "./AdsImagesReOrderSlice";
import AdvertisementSlice from "./AdvertisementSlice";
import DeleteAdsSlice from "./DeleteAdsSlice";
import GetWebPagesSlice from "./GetWebPagesSlice";
import UpdateAdvertisementSlice from "./UpdateAdvertisementSlice";


export const AdsReducers = combineReducers({
    webPages: GetWebPagesSlice,
    ads: AdvertisementSlice,
    addAdvertisement: AddAdvertisementSlice,
    deleteAdvertisement: DeleteAdsSlice,
    adsImagesReOrder: AdsImagesReOrderSlice,
    updateAdvertisement: UpdateAdvertisementSlice
})