import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiEndpoints } from "../../utils/ApiEndPoints";

const DeleteListingThunk = createAsyncThunk("DeleteListing/DeleteListingThunk", async ({ listingId }, { rejectWithValue }) => {
    try {
        const response = await axios.delete(`${ApiEndpoints.Listing.DeleteListing}?product_id=${listingId}`,
            {
                headers: {
                    "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
                }
            });
        return response.data;
    } catch (error) {
        console.log("error", error);
        const errorMessage = error?.response?.data?.message || "Something went wrong!";
        return rejectWithValue(errorMessage);
    }
});

const DeleteListingSlice = createSlice({
    name: "DeleteListing",
    initialState: {
        deleteListingData: {},
        success: false,
        error: false,
        loading: false,
        errorMessage: "",
    },
    reducers: {
        ResetDeleteListingData: (state, action) => {
            state.deleteListingData = {};
            state.success = false;
            state.error = false;
            state.loading = false;
            state.errorMessage = "";
        },
    },
    extraReducers: {
        [DeleteListingThunk.pending]: (state, action) => {
            state.loading = true;
            state.success = false;
            state.error = false;
        },
        [DeleteListingThunk.fulfilled]: (state, action) => {
            state.deleteListingData = action.payload;
            state.success = true;
            state.loading = false;
        },
        [DeleteListingThunk.rejected]: (state, action) => {
            state.errorMessage = action.payload;
            state.error = true;
            state.loading = false;
            state.deleteListingData = {};
        },
    },
});

export default DeleteListingSlice.reducer;

export const { ResetDeleteListingData } = DeleteListingSlice.actions;

export { DeleteListingThunk };
