import React, { useState } from "react";

import { Card } from "reactstrap";

import ImageContainer from "../../components/partials/gallery/GalleryImage";
import { Button } from "../../components/Component";
import DeleteAdsModal from "./DeleteAdsModal";
import UpdateAdsModal from "./UpdateAdsModal";

const AdvertisementBannerCard = ({ img, imgLink, adsData }) => {
    const [modal, setModal] = useState({
        update: false,
        delete: false
    });

    return (
        <React.Fragment>
            <Card className="card-bordered gallery">
                <ImageContainer img={img} />
                <div className="gallery-body card-inner align-center justify-between flex-wrap g-2 row">
                    <div className="user-card col-12">
                        <a href={imgLink} target="_blank" className="user-info">
                            <span className="lead-text" color="blue" style={{ color: "blue", textDecoration: "underline" }} >{imgLink}</span>
                        </a>
                    </div>
                    <div className="col-12 d-flex justify-content-between">
                        <Button color="primary" onClick={(ev) => { ev.preventDefault(); setModal({ update: true }) }}>
                            <span>Update</span>
                        </Button>
                        <Button color="danger" onClick={(ev) => { ev.preventDefault(); setModal({ delete: true }) }}>
                            <span>Delete</span>
                        </Button>
                    </div>
                </div>
            </Card>
            {modal.delete && <DeleteAdsModal modal={modal} setModal={() => setModal({ delete: false })} data={adsData} />}
            {modal.update && <UpdateAdsModal modal={modal} setModal={() => setModal({ update: false })} data={adsData} />}
        </React.Fragment>

    );
};

export default AdvertisementBannerCard;