import React from "react";
import LogoLight2x from "../../images/logo2x.png";
import LogoDark2x from "../../images/logo-dark2x.png";
import LogoSmall from "../../images/logo-small.png";
import { Link } from "react-router-dom";

const Logo = () => {
  return (
    <Link to={`/all-listing`} className="logo-link">
      {/* <img className="logo-light logo-img" src={LogoLight2x} alt="logo" /> */}
      <img className="logo-dark " src={LogoDark2x} alt="logo"  style={{maxHeight: "150px", maxWidth:"200px"}}/>
      {/* <img className="logo-small logo-img logo-img-small" src={LogoSmall} alt="logo" /> */}
    </Link>
  );
};

export default Logo;
