import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { ApiEndpoints } from "../../utils/ApiEndPoints";

const RentalProductDetailThunk = createAsyncThunk("RentalProductDetail/RentalProductDetailThunk", async ({ rentalProductId }, { rejectWithValue }) => {
    try {
        const response = await axios.get(`${ApiEndpoints.RentalProduct.RentalProductDetail}?product_id=${rentalProductId}`,
            {
                headers: {
                    "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
                }
            });
        return response.data;
    } catch (error) {
        console.log("error", error);
        const errorMessage = error?.response?.data?.message || "Something went wrong!";
        return rejectWithValue(errorMessage);
    }
});

const RentalProductDetailSlice = createSlice({
    name: "RentalProductDetail",
    initialState: {
        rentalProductData: {},
        success: false,
        error: false,
        loading: false,
        errorMessage: "",
    },
    reducers: {
        ResetRentalProductData: (state, action) => {
            state.rentalProductData = {};
            state.success = false;
            state.error = false;
            state.loading = false;
            state.errorMessage = "";
        },
    },
    extraReducers: {
        [RentalProductDetailThunk.pending]: (state, action) => {
            state.loading = true;
            state.success = false;
            state.error = false;
        },
        [RentalProductDetailThunk.fulfilled]: (state, action) => {
            state.rentalProductData = action.payload;
            state.success = true;
            state.loading = false;
        },
        [RentalProductDetailThunk.rejected]: (state, action) => {
            state.errorMessage = action.payload;
            state.error = true;
            state.loading = false;
            state.rentalProductData = {};
        },
    },
});

export default RentalProductDetailSlice.reducer;

export const { ResetRentalProductData } = RentalProductDetailSlice.actions;

export { RentalProductDetailThunk };
