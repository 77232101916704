import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiEndpoints } from "../../utils/ApiEndPoints";

const AddCategoryThunk = createAsyncThunk("AddCategory/AddCategoryThunk", async ({ name, description, images }, { rejectWithValue }) => {
    const formData = new FormData();
    formData.append("name", name);
    formData.append("description", description);
    for (let i = 0; i < images.length; i++) {
        formData.append("category", images[i].file);
    }

    try {
        const response = await axios.post(ApiEndpoints.Category.AddCategory,
            formData,
            {
                headers: {
                    "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
                }
            });
        return response.data;
    } catch (error) {
        console.log("error", error);
        return rejectWithValue(error.response.data);
    }
});

const AddCategorySlice = createSlice({
    name: "AddCategory",
    initialState: {
        addCategoryData: {},
        success: false,
        error: false,
        loading: false,
        errorMessage: "",
    },
    reducers: {
        ResetAddCategoryData: (state, action) => {
            state.addCategoryData = {};
            state.success = false;
            state.error = false;
            state.loading = false;
            state.errorMessage = "";
        },
    },
    extraReducers: {
        [AddCategoryThunk.pending]: (state, action) => {
            state.loading = true;
            state.success = false;
            state.error = false;
        },
        [AddCategoryThunk.fulfilled]: (state, action) => {
            state.addCategoryData = action.payload;
            state.success = true;
            state.loading = false;
        },
        [AddCategoryThunk.rejected]: (state, action) => {
            state.errorMessage = action.payload.message;
            state.error = true;
            state.loading = false;
            state.addCategoryData = {};
        },
    },
});

export default AddCategorySlice.reducer;

export const { ResetAddCategoryData } = AddCategorySlice.actions;

export { AddCategoryThunk };
