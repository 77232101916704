import React, { useState } from "react";
import { Modal } from "reactstrap";

const ImageContainer = ({ img }) => {
  const [open, setOpen] = useState(false);
  const toggle = () => {
    setOpen(!open);
  };
  return (
    <React.Fragment>
      <img className="w-100 rounded-top" src={img} alt="" width="250" height="250" />
      <Modal isOpen={open} toggle={toggle} size="large">
        <button type="button" className="mfp-close" onClick={toggle}>
          ×
        </button>
        <img className="w-100 rounded-top" style={{ height: "100%" }} src={img} alt="" />
      </Modal>
    </React.Fragment>
  );
};

export default ImageContainer;
