import React from 'react'

import { Formik } from 'formik';
import { Button, Col, Form, Modal, ModalBody, Spinner } from 'reactstrap';

import { Icon } from '../../components/Component';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { toast } from 'react-hot-toast';
import { DeleteAdvertisementThunk, ResetDeleteAdvertisement } from '../../redux/advertisement/DeleteAdsSlice';
import { GetAllAdvertisementThunk } from '../../redux/advertisement/AdvertisementSlice';

const DeleteAdsModal = ({ modal, setModal, ad_id, data }) => {
    const dispatch = useDispatch();

    const { loading, success, error, errorMessage } = useSelector((state) => state.advertisement.deleteAdvertisement)

    const handleDeleteAd = (e) => {
        dispatch(DeleteAdvertisementThunk({ id: data?.id }))
    }

    if (success) {
        toast.success("Ad has been deleted successfullly.");
        dispatch(ResetDeleteAdvertisement());
        dispatch(GetAllAdvertisementThunk());
        setModal(false);
    }

    if (error) {
        toast.error(errorMessage);
        dispatch(ResetDeleteAdvertisement());
        setModal(false);
    }

    return (
        <React.Fragment>
            <Modal isOpen={modal.delete} toggle={() => setModal(false)} className="modal-dialog-centered" size="md">
                <ModalBody>
                    <div className="close cursor-pointer" onClick={(ev) => { ev.preventDefault(); setModal(false); }}>
                        <Icon name="cross-sm"></Icon>
                    </div>
                    <div className="p-2">
                        <h4 className="title">Delete Ad</h4>
                        <div className="mt-4">
                            <Formik>
                                <Form className="row gy-4 mt-4 mb-5">
                                    <Col md="12">
                                        <div>
                                            <h6>Are you sure you want to delete this ad?</h6>
                                        </div>
                                    </Col>
                                    <Col size="12">
                                        <ul className="d-flex justify-content-between flex-wrap flex-sm-nowrap gx-4 gy-2">
                                            <li>
                                                <Button disabled={loading} color="danger" size="md" type="submit" onClick={handleDeleteAd}>
                                                    Delete
                                                    <span>{loading && <Spinner size="sm" />} </span>
                                                </Button>
                                            </li>
                                            <li>
                                                <div className="link link-light cursor-pointer" onClick={(ev) => { ev.preventDefault(); setModal(false); }}>
                                                    Cancel
                                                </div>
                                            </li>
                                        </ul>
                                    </Col>
                                </Form>
                            </Formik>
                        </div>
                    </div>
                </ModalBody>
            </Modal>

        </React.Fragment>
    )
}

export default DeleteAdsModal;