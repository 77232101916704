import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiEndpoints } from "../../utils/ApiEndPoints";

const AddUserThunk = createAsyncThunk("AddUser/AddUserThunk", async ({ username, email, password, phoneNumber }, { rejectWithValue }) => {
    try {
        const response = await axios.post(ApiEndpoints.User.AddUser,
            { username, email, password, phone_number:phoneNumber },
            {
                headers: {
                    "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
                }
            });
        return response.data;
    } catch (error) {
        console.log("error", error);
        const errorMessage = error?.response?.data?.message || "Something went wrong!";
        return rejectWithValue(errorMessage);
    }
});

const AddUserSlice = createSlice({
    name: "AddUser",
    initialState: {
        addUserData: {},
        success: false,
        error: false,
        loading: false,
        errorMessage: "",
    },
    reducers: {
        ResetAddUserData: (state, action) => {
            state.addUserData = {};
            state.success = false;
            state.error = false;
            state.loading = false;
            state.errorMessage = "";
        },
    },
    extraReducers: {
        [AddUserThunk.pending]: (state, action) => {
            state.loading = true;
            state.success = false;
            state.error = false;
        },
        [AddUserThunk.fulfilled]: (state, action) => {
            state.addUserData = action.payload;
            state.success = true;
            state.loading = false;
        },
        [AddUserThunk.rejected]: (state, action) => {
            state.errorMessage = action.payload;
            state.error = true;
            state.loading = false;
            state.addUserData = {};
        },
    },
});

export default AddUserSlice.reducer;

export const { ResetAddUserData } = AddUserSlice.actions;

export { AddUserThunk };
