import React, { useState } from "react";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import {
    BlockBetween,
    BlockHeadContent,
    BlockHead,
    BlockTitle,
    Icon,
    Button,
} from "../../components/Component";
import FinanceQuoteAds from "./FinanceQuoteAds";
import TransportQuoteAds from "./TransportQuoteAds";
import InsuranceQuoteAds from "./InsuranceQuoteAds";
import HomePageAds from "./HomePageAds";
import AddAdsModal from "./AddAdsModal";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { GetAllAdvertisementThunk } from "../../redux/advertisement/AdvertisementSlice";
import { useSelector } from "react-redux";
import { Spinner } from "reactstrap";

const Advertisement = () => {
    const dispatch = useDispatch();
    const [modal, setModal] = useState({
        add: false
    });

    const { loading, advertisementData } = useSelector((state) => state.advertisement.ads);

    useEffect(() => {
        dispatch(GetAllAdvertisementThunk());
    }, []);

    return (
        <React.Fragment>
            <Head title="Gallery"></Head>
            <Content>
                <BlockHead size="sm">
                    <BlockBetween className="g-3">
                        <BlockHeadContent>
                            <BlockTitle page>Ads Banner</BlockTitle>
                        </BlockHeadContent>
                        <BlockHeadContent>
                            <Button color="primary" onClick={() => setModal({ add: true })}>
                                <Icon name="plus"></Icon>
                                <span>Add Ad</span>
                            </Button>
                        </BlockHeadContent>
                    </BlockBetween>
                </BlockHead>
                {loading ?
                    <div className="d-flex justify-content-center align-items-center w-100" style={{ height: "80vh" }}>
                        <Spinner />
                    </div>
                    : (
                        <React.Fragment>
                            <HomePageAds data={advertisementData?.data && advertisementData?.data["Home Page"]} />
                            <FinanceQuoteAds data={advertisementData?.data && advertisementData?.data["Finance Quote"]} />
                            <TransportQuoteAds data={advertisementData?.data && advertisementData?.data["Transport Quote"]} />
                            <InsuranceQuoteAds data={advertisementData?.data && advertisementData?.data["Insurance Quote"]} />
                        </React.Fragment>
                    )}
            </Content>
            {modal.add && <AddAdsModal modal={modal} setModal={() => setModal({ add: false })} />}
        </React.Fragment>
    )
}

export default Advertisement;