import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiEndpoints } from "../../utils/ApiEndPoints";

const UpdateRentalProductStatusThunk = createAsyncThunk("UpdateRentalProductStatus/UpdateRentalProductStatusThunk", async ({ rentalProductId }, { rejectWithValue }) => {
    try {
        const response = await axios.put(`${ApiEndpoints.RentalProduct.UpdateRentalProductStatus}?product_id=${rentalProductId}`, {},
            {
                headers: {
                    "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
                }
            });
        return response.data;
    } catch (error) {
        console.log("error", error);
        const errorMessage = error?.response?.data?.message || "Something went wrong!";
        return rejectWithValue(errorMessage);
    }
});

const UpdateRentalProductStatusSlice = createSlice({
    name: "UpdateRentalProductStatus",
    initialState: {
        UpdateRentalProductStatusData: {},
        success: false,
        error: false,
        loading: false,
        errorMessage: "",
    },
    reducers: {
        ResetUpdateRentalProductStatusData: (state, action) => {
            state.UpdateRentalProductStatusData = {};
            state.success = false;
            state.error = false;
            state.loading = false;
            state.errorMessage = "";
        },
    },
    extraReducers: {
        [UpdateRentalProductStatusThunk.pending]: (state, action) => {
            state.loading = true;
            state.success = false;
            state.error = false;
        },
        [UpdateRentalProductStatusThunk.fulfilled]: (state, action) => {
            state.UpdateRentalProductStatusData = action.payload;
            state.success = true;
            state.loading = false;
        },
        [UpdateRentalProductStatusThunk.rejected]: (state, action) => {
            state.errorMessage = action.payload;
            state.error = true;
            state.loading = false;
            state.UpdateRentalProductStatusData = {};
        },
    },
});

export default UpdateRentalProductStatusSlice.reducer;

export const { ResetUpdateRentalProductStatusData } = UpdateRentalProductStatusSlice.actions;

export { UpdateRentalProductStatusThunk };
