import { combineReducers } from "@reduxjs/toolkit";
import FinanceQuotesSlice from "./FinanceQuotesSlice";
import InsuranceQuotesSlice from "./InsuranceQuotesSlice";
import TransportQuotesSlice from "./TransportQuotesSlice";


export const quotesSlice = combineReducers({
    financeQuotes: FinanceQuotesSlice,
    transportQuotes: TransportQuotesSlice,
    insuranceQuotes: InsuranceQuotesSlice
})