import React, { useState, useEffect } from "react";

import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { UncontrolledDropdown, DropdownMenu, DropdownToggle, Card, DropdownItem, Spinner } from "reactstrap";

import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import { Button, Block, BlockBetween, BlockDes, BlockHead, BlockHeadContent, BlockTitle, Icon } from "../../components/Component";
import AddCategoryModal from "./AddRentalCategoryModal";
import UpdateCategoryDetailModal from "./UpdateRentalCategoryDetailModal";
import UpdateCategoryStatusModal from "./UpdateRentalCategoryStatusModal";
import { GetAllCategoryThunk } from "../../redux/Category/GetAllCategorySlice";
import { GetAllRentalCategoryThunk } from "../../redux/RentalCategory/GetAllRentalCategorySlice";

const RentalCategory = () => {
    const dispatch = useDispatch();
    const [isCategoryActive, setIsCategoryActive] = useState();
    const [selectedCategoryDetail, setSelectedCategoryDetail] = useState({});
    const [modal, setModal] = useState({
        add: false,
        updateRentalCategoryDetail: false,
        updateRentalCategoryStatus: false
    });

    const { loading, error, allRentalCategoryData } = useSelector((state) => state.rentalCategory.getAllRentalCategory);

    useEffect(() => {
        dispatch(GetAllRentalCategoryThunk());
    }, [dispatch]);

    return (

        <React.Fragment>
            <Head title="Category - Yellowish Admin"></Head>
            {loading ?
                <div className="d-flex justify-content-center align-items-center w-100" style={{ height: "80vh" }}>
                    <Spinner />
                </div>
                :  (
                        <Content>
                            <BlockHead size="sm">
                                <BlockBetween>
                                    <BlockHeadContent>
                                        <BlockTitle page>Rental Categories</BlockTitle>
                                        <BlockDes className="text-soft">
                                            <p>You have total {allRentalCategoryData?.data?.length} categories.</p>
                                        </BlockDes>
                                    </BlockHeadContent>
                                    <BlockHeadContent>
                                        <ul className="nk-block-tools g-3">
                                            <li>
                                                <Button color="primary" onClick={() => setModal({ add: true })}>
                                                    <Icon name="plus"></Icon>
                                                    <span>Add Rental Category</span>
                                                </Button>
                                            </li>
                                        </ul>
                                    </BlockHeadContent>
                                </BlockBetween>
                            </BlockHead>

                            <Block>
                                <Card className="card-bordered card-stretch">
                                    <div className="card-inner-group">
                                        <div className="card-inner p-0">
                                            <table className="table table-tranx">
                                                <thead>
                                                    <tr className="tb-tnx-head">
                                                        <th className="tb-tnx-id">
                                                            <span className="">#</span>
                                                        </th>
                                                        <th className="tb-tnx-info">
                                                            <span className="tb-tnx-desc d-none d-sm-inline-block">
                                                                <span>Category Name</span>
                                                            </span>
                                                            <span className="tb-tnx-date d-md-inline-block d-none">
                                                                <span className="d-none d-md-block">
                                                                    <span>Description</span>
                                                                </span>
                                                            </span>
                                                        </th>
                                                        <th className="tb-tnx-amount is-alt">
                                                            <span className="tb-tnx-status d-none d-md-inline-block">Status</span>
                                                        </th>
                                                        <th className="tb-tnx-action">
                                                            <span>Action</span>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                    error ?
                                                        <div className="text-center">
                                                            <span className="text-silent">No data found</span>
                                                        </div>
                                                    :
                                                    allRentalCategoryData?.data?.map((categoryData, index) => {
                                                        return (
                                                            <tr className="tb-tnx-item">
                                                                <td className="tb-tnx-id">
                                                                    <a href="#ref" onClick={(ev) => { ev.preventDefault(); }} >
                                                                        <span>{index + 1}</span>
                                                                    </a>
                                                                </td>
                                                                <td className="tb-tnx-info">
                                                                    <div className="tb-tnx-desc">
                                                                        <span className="title">{categoryData?.name}</span>
                                                                    </div>
                                                                    <div className="tb-tnx-date" >
                                                                        <span className="date">{categoryData?.description}</span>
                                                                    </div>
                                                                </td>
                                                                <td className="tb-tnx-amount is-alt">
                                                                    <div className="tb-tnx-status">
                                                                        <span className={`badge badge-dot badge-${categoryData?.active ? "success" : "warning"}`}>
                                                                            {categoryData?.active ? "Active" : "Inactive"}
                                                                        </span>
                                                                    </div>
                                                                </td>
                                                                <td className="tb-tnx-action">
                                                                    <UncontrolledDropdown>
                                                                        <DropdownToggle tag="a" className="text-soft dropdown-toggle btn btn-icon btn-trigger">
                                                                            <Icon name="more-h"></Icon>
                                                                        </DropdownToggle>
                                                                        <DropdownMenu right>
                                                                            <ul className="link-list-plain">
                                                                                <li>
                                                                                    <DropdownItem tag="a" href="#view" onClick={(ev) => { ev.preventDefault(); setModal({ updateRentalCategoryDetail: true }); setSelectedCategoryDetail(categoryData) }}>
                                                                                        Update Category Detail
                                                                                    </DropdownItem>
                                                                                </li>
                                                                            </ul>
                                                                        </DropdownMenu>
                                                                    </UncontrolledDropdown>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </Card>
                            </Block>
                        </Content>
                    )}

            {modal.add && <AddCategoryModal modal={modal} setModal={() => setModal({ add: false })} />}
            {modal.updateRentalCategoryDetail && <UpdateCategoryDetailModal modal={modal} setModal={() => setModal({ updateRentalCategoryDetail: false })} categoryDetail={selectedCategoryDetail} />}
            {modal.updateRentalCategoryStatus && <UpdateCategoryStatusModal modal={modal} setModal={() => setModal({ updateRentalCategoryStatus: false })} isActive={isCategoryActive} id={selectedCategoryDetail?.id} />}

        </React.Fragment>
    );
};

export default RentalCategory;
