import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiEndpoints } from "../../utils/ApiEndPoints";

const GetWebPagesThunk = createAsyncThunk("GetWebPages/GetWebPagesThunk", async (_, { rejectWithValue }) => {
    try {
        const response = await axios.get(ApiEndpoints.Advertisement.GetWebPages,
            {
                headers: {
                    "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
                }
            });
        return response.data;
    } catch (error) {
        console.log("error", error);
        return rejectWithValue(error.response.data);
    }
});

const GetWebPagesSlice = createSlice({
    name: "GetWebPages",
    initialState: {
        webPagesData: [],
        success: false,
        error: false,
        loading: false,
        errorMessage: "",
    },
    reducers: {
        ResetWebPagesData: (state, action) => {
            state.webPagesData = [];
            state.success = false;
            state.error = false;
            state.loading = false;
            state.errorMessage = "";
        },
    },
    extraReducers: {
        [GetWebPagesThunk.pending]: (state, action) => {
            state.loading = true;
            state.success = false;
            state.error = false;
        },
        [GetWebPagesThunk.fulfilled]: (state, action) => {
            state.webPagesData = action.payload;
            state.success = true;
            state.loading = false;
        },
        [GetWebPagesThunk.rejected]: (state, action) => {
            state.errorMessage = action.payload;
            state.error = true;
            state.loading = false;
            state.webPagesData = [];
        },
    },
});

export default GetWebPagesSlice.reducer;

export const { ResetWebPagesData } = GetWebPagesSlice.actions;

export { GetWebPagesThunk };
