import React from 'react'

import { Formik } from 'formik';
import { Button, Col, Form, Modal, ModalBody, Spinner } from 'reactstrap';

import { Icon } from '../../../components/Component';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { DeleteUserPackageThunk, ResetDeleteUserPackageData } from '../../../redux/user/DeleteUserPackageSlice';
import { GetUserPackageThunk } from '../../../redux/user/GetUserPackageSlice';

const DeleteUserPackageModal = ({ modal, setModal, user_id, user_package_id }) => {
    const dispatch = useDispatch();

    const { loading, success, error, errorMessage } = useSelector((state) => state.user.DeleteUserPackage)
    const handleDeleteUserPackage = (e) => {
        dispatch(DeleteUserPackageThunk({ user_package_id }))
    }

    if (success) {
        toast.success("User Package has been deleted successfullly.");
        dispatch(ResetDeleteUserPackageData());
        dispatch(GetUserPackageThunk({ id: user_id }))
        setModal(false);
    }

    if (error) {
        toast.error(errorMessage);
        dispatch(ResetDeleteUserPackageData());
        dispatch(GetUserPackageThunk({ id: user_id }))
        setModal(false);
    }

    return (
        <React.Fragment>
            <Modal
                isOpen={modal}
                toggle={() => setModal(false)}
                className="modal-dialog-centered"
                size="md"
            >
                <ModalBody>
                    <a href="#cancel" className="close" onClick={(ev) => { ev.preventDefault(); setModal(false); }}>
                        <Icon name="cross-sm"></Icon>
                    </a>
                    <div className="p-2">
                        <h4 className="title">Delete User Package</h4>
                        <div className="mt-4">
                            <Formik>
                                <Form className="row gy-4 mt-4 mb-5">
                                    <Col md="12">
                                        <div>
                                            {/* <p>If you deleted this package. All listing associated to this package will also be deleted.</p> */}
                                            <p> Are you sure you want to delete package?</p>
                                        </div>
                                    </Col>
                                    <Col size="12">
                                        <ul className="d-flex justify-content-between flex-wrap flex-sm-nowrap gx-4 gy-2">
                                            <li>
                                                <Button disabled={loading} color="danger" size="md" type="submit" onClick={handleDeleteUserPackage}>

                                                    <span>{loading ? <Spinner size="md" /> : "Delete user package"} </span>
                                                </Button>
                                            </li>
                                            <li>
                                                <div className="link link-light" onClick={(ev) => { ev.preventDefault(); setModal(false); }}>
                                                    Cancel
                                                </div>
                                            </li>
                                        </ul>
                                    </Col>
                                </Form>
                            </Formik>
                        </div>
                    </div>
                </ModalBody>
            </Modal>

        </React.Fragment>
    )
}

export default DeleteUserPackageModal