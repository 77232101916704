import React, { useState } from 'react';

import AdvertisementBannerCard from "../AdvertisementBannerCard";
import { BlockBetween, Block, BlockHeadContent, BlockHead, BlockTitle, Col, Row, Icon } from "../../../components/Component";
import Content from '../../../layout/content/Content';
import { Button } from 'reactstrap';
import AdsImagesReOrderModal from '../AdsImagesReOrderModal';

const TransportQuoteAds = ({ data }) => {
    const [modal, setModal] = useState({
        adsImagesReOrder: false
    });

    return (
        <React.Fragment>

            <Content>
                <BlockHead size="sm">
                    <BlockBetween className="g-3">
                        <BlockHeadContent>
                            <BlockTitle page>Transport Quote Ads</BlockTitle>
                        </BlockHeadContent>
                        <BlockHeadContent>
                            <Button color="primary" onClick={(e) => { setModal({ adsImagesReOrder: true }) }}>
                                <Icon name="plus" />
                                <span>Update Transport Ads Order</span>
                            </Button>
                        </BlockHeadContent>
                    </BlockBetween>
                </BlockHead>
                <Block>
                    <Row className="g-gs">
                        {!data || data === "undefined" || data?.Advertisements?.length === 0 ?
                            <div>
                                <span>No Ad Found</span>
                            </div>
                            : data?.Advertisements?.map((item) => {
                                return (
                                    <Col sm={6} lg={4} xxl={3} key={item.id}>
                                        <AdvertisementBannerCard
                                            img={item.AdvertisementImages[0]?.image}
                                            imgLink={item.AdvertisementImages[0]?.ad_link}
                                            adsData={item}
                                        />
                                    </Col>
                                );
                            })}

                    </Row>
                </Block>
            </Content>
            {modal.adsImagesReOrder && <AdsImagesReOrderModal modal={modal} setModal={() => setModal({ adsImagesReOrder: false })} adsImages={data?.Advertisements} page_id={data?.id} />}

        </React.Fragment>
    )
}

export default TransportQuoteAds;