import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiEndpoints } from "../../utils/ApiEndPoints";

const AddListingThunk = createAsyncThunk("AddListing/AddListingThunk", async ({ title, content, price, category, images, year, make, model, machine_type, hours, mileage, service_history, serial_number, seller_logo }, { rejectWithValue }) => {
    const formData = new FormData();
    formData.append("title", title);
    formData.append("description", content);
    formData.append("price", price ? price : "POA");
    formData.append("category", Number(category));
    formData.append("year", year);
    formData.append("make", make);
    formData.append("model", model);
    formData.append("machine_type", machine_type);
    formData.append("hours", hours);
    formData.append("mileage", mileage);
    formData.append("service_history", service_history);
    formData.append("serial_number", serial_number);

    for (let i = 0; i < images.length; i++) {
        formData.append("products", images[i].file);
    }
    for (let i = 0; i < seller_logo.length; i++) {
        formData.append("seller_logo", seller_logo[i].file);
    }

    try {
        const response = await axios.post(ApiEndpoints.Listing.AddListing,
            formData,
            {
                headers: {
                    "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
                }
            });
        return response.data;
    } catch (error) {
        console.log("error", error);
        const errorMessage = error?.response?.data?.message || "Something went wrong!";
        return rejectWithValue(errorMessage);
    }
});

const AddListingSlice = createSlice({
    name: "AddListing",
    initialState: {
        addListingData: {},
        success: false,
        error: false,
        loading: false,
        errorMessage: "",
    },
    reducers: {
        ResetAddListingData: (state, action) => {
            state.success = false;
            state.error = false;
            state.loading = false;
            state.errorMessage = "";
        },
    },
    extraReducers: {
        [AddListingThunk.pending]: (state, action) => {
            state.loading = true;
            state.success = false;
            state.error = false;
        },
        [AddListingThunk.fulfilled]: (state, action) => {
            state.addListingData = action.payload;
            state.success = true;
            state.loading = false;
        },
        [AddListingThunk.rejected]: (state, action) => {
            state.errorMessage = action.payload;
            state.error = true;
            state.loading = false;
            state.addListingData = {};
        },
    },
});

export default AddListingSlice.reducer;

export const { ResetAddListingData } = AddListingSlice.actions;

export { AddListingThunk };
