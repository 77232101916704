import React from 'react'

import { Formik } from 'formik';
import { Button, Col, Form, Modal, ModalBody, Spinner } from 'reactstrap';

import { Icon } from '../../components/Component';
import { ChangeListingStatusThunk, ResetChangeListingStatusData } from '../../redux/listing/ChangeListingStatusSlice';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { GetAllListingThunk } from '../../redux/listing/GetAllListingSlice';

const UpdateListingStatusModal = ({ modal, setModal, listingId }) => {
    const dispatch = useDispatch();

    const { loading, success, error, errorMessage, changeListingStatusData } = useSelector((state) => state.listing.changeListingStatus)
    const handleChangeListingStatus = (e) => {
        e.preventDefault();
        dispatch(ChangeListingStatusThunk({ listingId: listingId }));
    }

    if (success) {
        toast.success("Listing status has been updated successfully")
        dispatch(ResetChangeListingStatusData());
        dispatch(GetAllListingThunk())
        setModal(false);
    }

    if (error) {
        toast.error(errorMessage);
        dispatch(ResetChangeListingStatusData());
        setModal(false);
    }

    return (
        <React.Fragment>
            <Modal
                isOpen={modal.changeListingStatus}
                toggle={() => setModal(false)}
                className="modal-dialog-centered"
                size="md"
            >
                <ModalBody>
                    <a href="#cancel" className="close" onClick={(ev) => { ev.preventDefault(); setModal(false); }}>
                        <Icon name="cross-sm"></Icon>
                    </a>
                    <div className="p-2">
                        <h4 className="title">Update Listing Status</h4>
                        <div className="mt-4">
                            <Formik>
                                <Form className="row gy-4 mt-4 mb-5">
                                    <Col md="12">
                                        <div>
                                            <h6>Are you sure you want to update Listing status?</h6>
                                        </div>
                                    </Col>
                                    <Col size="12">
                                        <ul className="d-flex justify-content-between flex-wrap flex-sm-nowrap gx-4 gy-2">
                                            <li>
                                                <Button disabled={loading} onClick={handleChangeListingStatus} color="primary" size="md" type="submit">
                                                    Update
                                                    <span>{loading && <Spinner size="sm" />} </span>
                                                </Button>
                                            </li>
                                            <li>
                                                <a className="link link-light" onClick={(ev) => { ev.preventDefault(); setModal(false); }}
                                                >
                                                    Cancel
                                                </a>
                                            </li>
                                        </ul>
                                    </Col>
                                </Form>
                            </Formik>
                        </div>
                    </div>
                </ModalBody>
            </Modal>

        </React.Fragment>
    )
}

export default UpdateListingStatusModal;
