import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiEndpoints } from "../../utils/ApiEndPoints";

const AddUserListingThunk = createAsyncThunk("AddUserListing/AddUserListingThunk", async ({ user_id, package_id, title, content, price, category, images, year, make, model, machine_type, hours, mileage, service_history, serial_number }, { rejectWithValue }) => {
    const formData = new FormData();
    formData.append("title", title);
    formData.append("user_id", user_id);
    formData.append("description", content);
    formData.append("price", price ? price : "POA");
    formData.append("category", Number(category));
    formData.append("year", year);
    formData.append("make", make);
    formData.append("model", model);
    formData.append("machine_type", machine_type);
    formData.append("hours", hours);
    formData.append("mileage", mileage);
    formData.append("service_history", service_history);
    formData.append("serial_number", serial_number);
    // formData.append("fuel_type", fuel_type);
    // formData.append("engine_type", engine_type);
    // formData.append("engine_max_power", engine_max_power);
    // formData.append("engine_max_torque", engine_max_torque);
    formData.append("package_id", package_id);
    for (let i = 0; i < images.length; i++) {
        formData.append("products", images[i].file);
    }

    try {
        const response = await axios.post(ApiEndpoints.User.AddUserListing, formData,
            {
                headers: {
                    "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
                }
            });
        return response.data;
    } catch (error) {
        console.log("error", error);
        const errorMessage = error.response.data.message ? error.response.data.message : "Something Went Wrong!";
        return rejectWithValue(errorMessage);
    }
});

const AddUserListingSlice = createSlice({
    name: "AddUserListing",
    initialState: {
        addUserListingData: {},
        success: false,
        error: false,
        loading: false,
        errorMessage: "",
    },
    reducers: {
        ResetAddUserListingData: (state, action) => {
            state.addUserListingData = {};
            state.success = false;
            state.error = false;
            state.loading = false;
            state.errorMessage = "";
        },
    },
    extraReducers: {
        [AddUserListingThunk.pending]: (state, action) => {
            state.loading = true;
            state.success = false;
            state.error = false;
        },
        [AddUserListingThunk.fulfilled]: (state, action) => {
            state.addUserListingData = action.payload;
            state.success = true;
            state.loading = false;
        },
        [AddUserListingThunk.rejected]: (state, action) => {
            state.errorMessage = action.payload;
            state.error = true;
            state.loading = false;
            state.addUserListingData = {};
        },
    },
});

export default AddUserListingSlice.reducer;

export const { ResetAddUserListingData } = AddUserListingSlice.actions;

export { AddUserListingThunk };
