

import React, { useEffect, useState, useCallback } from 'react'
import { useForm } from 'react-hook-form';
import ReactImageUploading from 'react-images-uploading';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { toast } from 'react-hot-toast';
import { Button, Col, Form, FormGroup, Modal, ModalBody, Row, Spinner } from 'reactstrap';
import { Icon, RSelect } from '../../components/Component';
import { GetAllCategoryThunk } from '../../redux/Category/GetAllCategorySlice';
import { GetAllListingThunk } from '../../redux/listing/GetAllListingSlice';
import { ListingDetailThunk } from '../../redux/listing/ListingDetailSlice';
import { ResetUpdateListingData, UpdateListingThunk } from '../../redux/listing/UpdateListingDetailSlice';

const UpdateListingDetailModal = ({ modal, setModal, listingDetail, listingId }) => {
    const dispatch = useDispatch();

    const [formData, setFormData] = useState({});
    const [images, setImages] = useState([]);
    const [oldImages, setOldImages] = useState([]);
    const [dealerLogo, setDealerLogo] = useState([]);
    const [oldDealerLogo, setOldDealerLogo] = useState([]);
    const [isDealerLogoRemove, setIsDealerLogoRemove] = useState(0);
    const [categoryOption, setCategoryOption] = useState([])

    useEffect(() => {
        dispatch(ListingDetailThunk({ listingId }));
        dispatch(GetAllCategoryThunk());
    }, [dispatch]);


    const {
        allCategoryData
    } = useSelector((state) => state.category.getAllCategory)

    const {
        loading: ListingDetailLoading,
        listingData
    } = useSelector((state) => state.listing.listingDetail)

    const { loading, success, error, errorMessage } = useSelector((state) => state.listing.updateListingDetail);

    useEffect(() => {
        setFormData({
            title: listingData?.data?.title ? listingData?.data?.title : "",
            content: listingData?.data?.description ? listingData?.data?.description : "",
            price: listingData?.data?.price ? listingData?.data?.price : "",
            categoryLabel: listingData?.data?.Category ? listingData?.data?.Category?.name : "",
            categoryId: listingData?.data?.Category ? listingData?.data?.Category?.id : "",
            year: listingData?.data?.year ? listingData?.data?.year : "",
            make: listingData?.data?.make ? listingData?.data?.make : "",
            model: listingData?.data?.model ? listingData?.data?.model : "",
            machine_type: listingData?.data?.machine_type ? listingData?.data?.machine_type : "",
            hours: listingData?.data?.hours ? listingData?.data?.hours : "",
            mileage: listingData?.data?.mileage ? listingData?.data?.mileage : "",
            service_history: listingData?.data?.service_history ? listingData?.data?.service_history : "",
            serial_number: listingData?.data?.serial_number ? listingData?.data?.serial_number : "",
            // engine_max_torque: listingData?.data?.engine_max_torque ? listingData?.data?.engine_max_torque : "",
            // engine_max_power: listingData?.data?.engine_max_power ? listingData?.data?.engine_max_power : "",
            // engine_type: listingData?.data?.engine_type ? listingData?.data?.engine_type : "",
            // fuel_type: listingData?.data?.fuel_type ? listingData?.data?.fuel_type : "",
        });
        setOldImages(listingData?.data?.ProductImages);
        setOldDealerLogo(listingData?.data?.seller_logo ? [{ image: listingData?.data?.seller_logo }] : []);
    }, [listingData]);

    useEffect(() => {
        const list = allCategoryData?.data?.map((category) => {
            return { label: category?.name, value: category?.id };
        })
        setCategoryOption(list);
    }, [allCategoryData])

    const onChange = (imageList) => {
        setImages(imageList);
    };

    const onOldImageChange = (imageList) => {
        setOldImages(imageList);
    }

    const onDealerLogoChange = (imageList) => {
        imageList.length !== 0 && setIsDealerLogoRemove(0)
        setDealerLogo(imageList);
    };

    const onOldDealerLogoChange = (imageList) => {
        setIsDealerLogoRemove(1);
        setOldDealerLogo(imageList);
    }

    const onInputChange = (e) => {
        e.preventDefault();
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    const handleUpdateListingForm = () => {
        dispatch(UpdateListingThunk({ seller_logo: dealerLogo, oldImages, seller_logo_remove: isDealerLogoRemove, images, product_id: listingData?.data?.product_id, title: formData?.title, description: formData?.content, price: formData?.price, category: formData?.categoryId, year: formData?.year, make: formData?.make, model: formData?.model, machine_type: formData?.machine_type, hours: formData?.hours, mileage: formData?.mileage, service_history: formData?.service_history, serial_number: formData?.serial_number }))
    }

    if (success) {
        toast.success("Listing has been updated successfully.");
        dispatch(GetAllListingThunk());
        dispatch(ResetUpdateListingData());
        setModal(false);
    }

    if (error) {
        toast.error(errorMessage);
        dispatch(GetAllListingThunk());
        dispatch(ResetUpdateListingData());
        setModal(false);
    }

    const { errors, register, handleSubmit } = useForm();

    return (
        <React.Fragment>
            <Modal isOpen={modal.updateListingDetail} toggle={() => setModal(false)} className="modal-dialog-centered" size="lg">
                <ModalBody>
                    <div onClick={() => setModal(false)} className="close cursor-pointer">
                        <Icon name="cross-sm"></Icon>
                    </div>
                    <div className="p-2">
                        <h5 className="title">Edit Listing</h5>
                        <div className="mt-4">
                            {ListingDetailLoading ?
                                <div className="d-flex justify-content-center align-items-center w-100" style={{ height: "60vh" }}>
                                    <Spinner />
                                </div>
                                : (
                                    <Form className="row gy-4 mt-4" onSubmit={handleSubmit(handleUpdateListingForm)}>
                                        <Col md="12">
                                            <FormGroup>
                                                <label className="form-label">Dealer Logo <span className='cl-red'>*</span></label>
                                                {
                                                    <ReactImageUploading className="form-control" multiple value={dealerLogo} onChange={onDealerLogoChange} maxNumber={1} dataURLKey="data_url">
                                                        {({ imageList, onImageUpload, onImageRemove, isDragging, dragProps }) => (
                                                            <div className="upload__image-wrapper">
                                                                {(oldDealerLogo.length === 0 && dealerLogo.length === 0) &&
                                                                    <Button style={isDragging ? { color: "red" } : undefined} onClick={onImageUpload} color="primary" className="form-bt2 color-black900" {...dragProps}>
                                                                        Click or Drop here
                                                                    </Button>
                                                                }
                                                                &nbsp;
                                                                <div className="row image-item">
                                                                    <Row>
                                                                        {imageList.map((image, index) => (
                                                                            <Col className="m-2" sm="4">
                                                                                <img src={image["data_url"]} alt="" width="120" height="100" />
                                                                                <Button className="mt-1" color='primary' onClick={() => onImageRemove(index)}>
                                                                                    Remove
                                                                                </Button>
                                                                            </Col>
                                                                        ))}
                                                                    </Row>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </ReactImageUploading>
                                                }
                                                {errors.image_gallery && <span className="invalid">{errors.image_gallery.message}</span>}
                                            </FormGroup>
                                        </Col>
                                        <Col md="12">
                                            <FormGroup>
                                                <ReactImageUploading className="form-control" multiple value={oldDealerLogo} onChange={onOldDealerLogoChange} maxNumber={15} dataURLKey="data_url">
                                                    {({ imageList, onImageUpload, onImageRemove, isDragging, dragProps }) => (
                                                        <div className="upload__image-wrapper">
                                                            <div className="row image-item">
                                                                <Row>
                                                                    {imageList.map((image, index) => {
                                                                        return (
                                                                            <Col className="m-2" md="6">
                                                                                <img src={`${image.image}`} alt="" width="120" height="100" />
                                                                                <Button className="mt-1" color='primary' onClick={() => onImageRemove(index)}>
                                                                                    Remove
                                                                                </Button>
                                                                            </Col>
                                                                        )
                                                                    }
                                                                    )}
                                                                </Row>
                                                            </div>
                                                        </div>
                                                    )}
                                                </ReactImageUploading>

                                                {errors.image_gallery && <span className="invalid">{errors.image_gallery.message}</span>}
                                            </FormGroup>
                                        </Col>

                                        <Col md="6">
                                            <FormGroup>
                                                <label className="form-label">Title <span className='cl-red'>*</span></label>
                                                <input className="form-control" ref={register({ required: "This field is required" })} value={formData?.title} onChange={onInputChange} type="text" name="title"/>
                                                {errors.title && <span className="invalid">{errors.title.message}</span>}
                                            </FormGroup>
                                        </Col>
                                        <Col md="6">
                                            <FormGroup>
                                                <label className="form-label">Content <span className='cl-red'>*</span></label>
                                                <input className="form-control" ref={register({ required: "This field is required" })} value={formData?.content} onChange={onInputChange} type="text" name="content"/>
                                                {errors.content && <span className="invalid">{errors.content.message}</span>}
                                            </FormGroup>
                                        </Col>
                                        <Col md="6">
                                            <FormGroup>
                                                <label className="form-label">Price <span className='cl-red'>*</span></label>
                                                <input className="form-control" ref={register({ required: "This field is required" })} value={formData?.price} onChange={onInputChange} type="text" name="price"/>
                                                {errors.price && <span className="invalid">{errors.price.message}</span>}
                                            </FormGroup>
                                        </Col>
                                        <Col md="12">
                                            <FormGroup>
                                                <label className="form-label">Category <span className='cl-red'>*</span></label>
                                                <div className="form-control-wrap">
                                                    <RSelect options={categoryOption} defaultValue={{ value: listingData?.data?.Category?.id, label: listingData?.data?.Category?.name }} onChange={(e) => { setFormData({ ...formData, categoryLabel: e.name, categoryId: e.value }) }} />
                                                </div>
                                            </FormGroup>
                                        </Col>
                                        <Col md="12">
                                            <FormGroup>
                                                <label className="form-label">Image Gallery <span className='cl-red'>*</span></label>
                                                <ReactImageUploading className="form-control" multiple value={images} onChange={onChange} maxNumber={15} dataURLKey="data_url">
                                                    {({ imageList, onImageUpload, onImageRemove, isDragging, dragProps }) => (
                                                        <div className="upload__image-wrapper">
                                                            <Button style={isDragging ? { color: "red" } : undefined} onClick={onImageUpload} color="primary" className="form-bt2 color-black900" {...dragProps}>
                                                                Click or Drop here
                                                            </Button>
                                                            &nbsp;
                                                            <div className="row image-item">
                                                                <Row>
                                                                    {imageList.map((image, index) => (
                                                                        <Col className="m-2" sm="4">
                                                                            <img src={image["data_url"]} alt="" width="120" height="100" />
                                                                            <Button className="mt-1" color='primary' onClick={() => onImageRemove(index)}>
                                                                                Remove
                                                                            </Button>
                                                                        </Col>
                                                                    ))}
                                                                </Row>
                                                            </div>
                                                        </div>
                                                    )}
                                                </ReactImageUploading>

                                                {errors.image_gallery && <span className="invalid">{errors.image_gallery.message}</span>}
                                            </FormGroup>
                                        </Col>
                                        <Col md="12">
                                            <FormGroup>
                                                <ReactImageUploading className="form-control" multiple value={oldImages} onChange={onOldImageChange} maxNumber={15} dataURLKey="data_url">
                                                    {({ imageList, onImageUpload, onImageRemove, isDragging, dragProps }) => (
                                                        <div className="upload__image-wrapper">
                                                            <div className="row image-item">
                                                                <Row>
                                                                    {imageList.map((image, index) => {
                                                                        return (
                                                                            <Col className="m-2" md="3">
                                                                                <img src={`${image.image}`} alt="" width="120" height="100" />
                                                                                <Button className="mt-1" color='primary' onClick={() => onImageRemove(index)}>
                                                                                    Remove
                                                                                </Button>
                                                                            </Col>
                                                                        )
                                                                    }
                                                                    )}
                                                                </Row>
                                                            </div>
                                                        </div>
                                                    )}
                                                </ReactImageUploading>

                                                {errors.image_gallery && <span className="invalid">{errors.image_gallery.message}</span>}
                                            </FormGroup>
                                        </Col>

                                        <Col md="6">
                                            <FormGroup>
                                                <label className="form-label">Make <span className='cl-red'>*</span></label>
                                                <input className="form-control" ref={register({ required: "This field is required" })} value={formData?.make} onChange={onInputChange} type="text" name="make" required />
                                                {errors.make && <span className="invalid">{errors.make.message}</span>}
                                            </FormGroup>
                                        </Col>
                                        <Col md="6">
                                            <FormGroup>
                                                <label className="form-label">Model <span className='cl-red'>*</span></label>
                                                <input className="form-control" ref={register({ required: "This field is required" })} value={formData?.model} onChange={onInputChange} type="text" name="model" required />
                                                {errors.model && <span className="invalid">{errors.model.message}</span>}
                                            </FormGroup>
                                        </Col>
                                        <Col md="6">
                                            <FormGroup>
                                                <label className="form-label">Machine Type <span className='cl-red'>*</span></label>
                                                <input className="form-control" ref={register({ required: "This field is required" })} value={formData?.machine_type} onChange={onInputChange} type="text" name="machine_type" required />
                                                {errors.machine_type && <span className="invalid">{errors.machine_type.message}</span>}
                                            </FormGroup>
                                        </Col>
                                        <Col md="6">
                                            <FormGroup>
                                                <label className="form-label">Year</label>
                                                <input className="form-control" value={formData?.year} onChange={onInputChange} type="text" name="year" />
                                                {errors.year && <span className="invalid">{errors.year.message}</span>}
                                            </FormGroup>
                                        </Col>

                                        <Col md="6">
                                            <FormGroup>
                                                <label className="form-label">Hours</label>
                                                <input className="form-control" value={formData?.hours} onChange={onInputChange} type="text" name="hours" />
                                                {errors.hours && <span className="invalid">{errors.hours.message}</span>}
                                            </FormGroup>
                                        </Col>
                                        <Col md="6">
                                            <FormGroup>
                                                <label className="form-label">Mileage</label>
                                                <input className="form-control" value={formData?.mileage} onChange={onInputChange} type="text" name="mileage" />
                                                {errors.mileage && <span className="invalid">{errors.mileage.message}</span>}
                                            </FormGroup>
                                        </Col>
                                        <Col md="6">
                                            <FormGroup>
                                                <label className="form-label">Service History</label>
                                                <input className="form-control" value={formData?.service_history} onChange={onInputChange} type="text" name="service_history" />
                                                {errors.service_history && <span className="invalid">{errors.service_history.message}</span>}
                                            </FormGroup>
                                        </Col>
                                        <Col md="6">
                                            <FormGroup>
                                                <label className="form-label">Serial Number</label>
                                                <input className="form-control" value={formData?.serial_number} onChange={onInputChange} type="text" name="serial_number" />
                                                {errors.serial_number && <span className="invalid">{errors.serial_number.message}</span>}
                                            </FormGroup>
                                        </Col>
                                        {/* <Col md="6">
                                            <FormGroup>
                                                <label className="form-label">Fuel Type</label>
                                                <input className="form-control" value={formData?.fuel_type} onChange={onInputChange} type="text" name="fuel_type" />
                                                {errors.fuel_type && <span className="invalid">{errors.fuel_type.message}</span>}
                                            </FormGroup>
                                        </Col>
                                        <Col md="6">
                                            <FormGroup>
                                                <label className="form-label">Engine Type</label>
                                                <input className="form-control" value={formData?.engine_type} onChange={onInputChange} type="text" name="engine_type" />
                                                {errors.engine_type && <span className="invalid">{errors.engine_type.message}</span>}
                                            </FormGroup>
                                        </Col>
                                        <Col md="6">
                                            <FormGroup>
                                                <label className="form-label">Engine Max Power (km)</label>
                                                <input className="form-control" value={formData?.engine_max_power} onChange={onInputChange} type="text" name="engine_max_power" />
                                                {errors.engine_max_power && <span className="invalid">{errors.engine_max_power.message}</span>}
                                            </FormGroup>
                                        </Col>
                                        <Col md="6">
                                            <FormGroup>
                                                <label className="form-label">Engine Max Torgue (NM)</label>
                                                <input className="form-control" value={formData?.engine_max_torque} onChange={onInputChange} type="text" name="engine_max_torque" />
                                                {errors.engine_max_torque && <span className="invalid">{errors.engine_max_torque.message}</span>}
                                            </FormGroup>
                                        </Col> */}
                                        <Col size="12">
                                            <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                                                <li>
                                                    <Button color="primary" size="md" type="submit">
                                                        Update
                                                        <span>{loading && <Spinner size="sm" />} </span>
                                                    </Button>
                                                </li>
                                                <li>
                                                    <div onClick={() => setModal(false)} className="link link-light">
                                                        Cancel
                                                    </div>
                                                </li>
                                            </ul>
                                        </Col>
                                    </Form>
                                )
                            }
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </React.Fragment>
    )
}

export default UpdateListingDetailModal;