

import React from 'react'

import { useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { Button, Col, Form, FormGroup, Modal, ModalBody, Spinner } from 'reactstrap';

import { Icon } from '../../components/Component';
import { GetAllPackageThunk } from '../../redux/package/GetAllPackageSlice';
import { ResetUpdatePackageDetailData, UpdatePackageDetailThunk } from '../../redux/package/UpdatePackageDetailSlice';

const UpdatePackageDetailModal = ({ modal, setModal, packageDetail }) => {
    const dispatch = useDispatch();

    const { loading, success, error, errorMessage } = useSelector((state) => state.package.updatePackageDetail)
    const handleUpdatePackageDetailForm = ({ name, listings, validity, price }) => {
        dispatch(UpdatePackageDetailThunk({ name, listings, validity, price, isActive: packageDetail?.active, id: packageDetail?.id }))
    }

    if (success) {
        toast.success("Package has been updated successfully.");
        dispatch(ResetUpdatePackageDetailData());
        dispatch(GetAllPackageThunk());
        setModal();
    }

    if (error) {
        toast.error(errorMessage);
        dispatch(ResetUpdatePackageDetailData());
        dispatch(GetAllPackageThunk());
        setModal();
    }

    const { errors, register, handleSubmit } = useForm();

    return (
        <React.Fragment>
            <Modal isOpen={modal.updatePackageDetail} toggle={() => setModal(false)} className="modal-dialog-centered" size="lg">
                <ModalBody>
                    <a
                        href="#cancel"
                        onClick={() => setModal(false)}
                        className="close"
                    >
                        <Icon name="cross-sm"></Icon>
                    </a>
                    <div className="p-2">
                        <h5 className="title">Add Package</h5>
                        <div className="mt-4">
                            <Form className="row gy-4 mt-4" onSubmit={handleSubmit(handleUpdatePackageDetailForm)}>
                                <Col md="6">
                                    <FormGroup>
                                        <label className="form-label">Package Name</label>
                                        <input className="form-control" ref={register({ required: "This field is required" })} defaultValue={packageDetail?.name} type="text" name="name" required
                                        />
                                        {errors.name && <span className="invalid">{errors.name.message}</span>}
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup>
                                        <label className="form-label">No. of Listings</label>
                                        <input className="form-control" ref={register({ required: "This field is required" })} defaultValue={packageDetail?.listings} type="text" name="listings" required
                                        />
                                        {errors.listings && <span className="invalid">{errors.listings.message}</span>}
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup>
                                        <label className="form-label">Validity in Days</label>
                                        <input className="form-control" ref={register({ required: "This field is required" })} defaultValue={packageDetail?.validity} type="text" name="validity" required
                                        />
                                        {errors.validity && <span className="invalid">{errors.validity.message}</span>}
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup>
                                        <label className="form-label">Price</label>
                                        <input className="form-control" ref={register({ required: "This field is required" })} defaultValue={packageDetail?.price} type="text" name="price" required
                                        />
                                        {errors.price && <span className="invalid">{errors.price.message}</span>}
                                    </FormGroup>
                                </Col>
                                <Col md="12">
                                    <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                                        <li>
                                            <Button color="primary" disabled={loading} size="md" type="submit">
                                                {loading ? <Spinner size="md" /> : "Update Package"}
                                            </Button>
                                        </li>
                                        <li>
                                            <a
                                                href="#cancel"
                                                onClick={() => setModal(false)}
                                                className="link link-light"
                                            >
                                                Cancel
                                            </a>
                                        </li>
                                    </ul>
                                </Col>
                            </Form>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </React.Fragment>
    )
}

export default UpdatePackageDetailModal;