

import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Col, Form, FormGroup, Modal, ModalBody, Spinner } from 'reactstrap';

import { GetAllPackageThunk } from '../../../redux/package/GetAllPackageSlice';
import { Icon, RSelect } from '../../../components/Component';
import { AddUserPackageThunk, ResetAddUserPackageData } from '../../../redux/user/AddUserPackageSlice';
import { GetUserPackageThunk } from '../../../redux/user/GetUserPackageSlice';

const AddUserPackageModal = ({ modal, setModal, user_id }) => {
    const dispatch = useDispatch();

    const [packages, setPackage] = useState([]);
    const [selectedPackage, setSelectedPackage] = useState({
        label: "",
        id: undefined,
    })
    const { loading, success, error, errorMessage } = useSelector((state) => state.user.AddUserPackage);
    const { loading: allPackageLoading, error: allPackageError, allPackageData } = useSelector((state) => state.package.getAllPackage)

    const handleAddUserPackageForm = () => {
        dispatch(AddUserPackageThunk({
            user_id,
            package_id: selectedPackage.id
        }))
    }

    useEffect(() => {
        const list = allPackageData?.data?.map((packageData) => {
            return { label: `${packageData?.name} `, value: packageData?.id };
        });
        setPackage(list);
    }, [allPackageData]);

    useEffect(() => {
        dispatch(GetAllPackageThunk());
    }, [dispatch])

    if (success) {
        toast.success("User's Package has been added successfully.");
        dispatch(ResetAddUserPackageData());
        dispatch(GetUserPackageThunk({ id: user_id }));
        setModal(false);
    }

    if (error) {
        toast.error(errorMessage);
        dispatch(ResetAddUserPackageData());
        dispatch(GetUserPackageThunk({ id: user_id }));
        setModal(false);
    }

    const { handleSubmit } = useForm();

    return (
        <React.Fragment>
            <Modal isOpen={modal.add} toggle={() => setModal(false)} className="modal-dialog-centered" size="lg">
                <ModalBody>
                    <a
                        href="#cancel"
                        onClick={() => setModal(false)}
                        className="close"
                    >
                        <Icon name="cross-sm"></Icon>
                    </a>
                    <div className="p-2">
                        <h5 className="title">Add User Package</h5>
                        <div className="mt-4">
                            {allPackageLoading ?
                                <div className="d-flex justify-content-center align-items-center w-100" style={{ height: "40vh" }}>
                                    <Spinner />
                                </div>
                                : allPackageError || packages?.length === 0 ?
                                    <span>Please add Package from packages section. </span>
                                    : (
                                        <Form className="row gy-4 mt-4" onSubmit={handleSubmit(handleAddUserPackageForm)}>
                                            <Col md="12">
                                                <FormGroup>
                                                    <label className="form-label">Packages</label>
                                                    <div className="form-control-wrap">
                                                        <RSelect
                                                            options={packages}
                                                            //   defaultValue={event.type}
                                                            onChange={(e) => {
                                                                setSelectedPackage({ label: e.label, id: e.value })
                                                            }}
                                                            placeholder="Select Package which you want to add in user profile"
                                                        //ref={register({ required: true })}
                                                        />
                                                    </div>
                                                </FormGroup>
                                            </Col>
                                            <Col md="12">
                                                <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                                                    <li>
                                                        <Button disabled={loading || selectedPackage.id === undefined} color="primary" size="md" type="submit">
                                                            {loading ? <Spinner /> : "Add User Package"}
                                                        </Button>
                                                    </li>
                                                    <li>
                                                        <a
                                                            href="#cancel"
                                                            onClick={() => setModal(false)}
                                                            className="link link-light"
                                                        >
                                                            Cancel
                                                        </a>
                                                    </li>
                                                </ul>
                                            </Col>
                                        </Form>
                                    )}
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </React.Fragment>
    )
}

export default AddUserPackageModal;