import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiEndpoints } from "../../utils/ApiEndPoints";

const UpdateRentalProductDetailThunk = createAsyncThunk("UpdateRentalProductDetail/UpdateRentalProductDetailThunk", async ({ oldImages: old_images, seller_logo_remove, product_id, fuel_type, engine_type, engine_max_power,machine_location,  engine_max_torque, listing_package, title, description, price, category, images: products, year, make, model, machine_type, hours, mileage, service_history, serial_number, seller_logo }, { rejectWithValue }) => {
    const formData = new FormData();
    formData.append("title", title);
    formData.append("seller_logo_removed", seller_logo_remove);
    formData.append("product_id", product_id);
    formData.append("description", description);
    formData.append("renting_price", price ? price : "POA");
    formData.append("year", year);
    formData.append("old_images", JSON.stringify(old_images));
    formData.append("make", make);
    formData.append("model", model);
    formData.append("machine_type", machine_type);
    formData.append("machine_location", machine_location);
    formData.append("hours", hours);
    formData.append("mileage", mileage);
    formData.append("service_history", service_history);
    formData.append("serial_number", serial_number);
    // formData.append("fuel_type", fuel_type);
    // formData.append("engine_type", engine_type);
    // formData.append("engine_max_power", engine_max_power);
    // formData.append("engine_max_torque", engine_max_torque);
    for (let i = 0; i < products?.length; i++) {
        formData.append("products", products[i].file);
    }
    for (let i = 0; i < seller_logo?.length; i++) {
        formData.append("seller_logo", seller_logo[i].file);
    }

    try {
        const response = await axios.put(ApiEndpoints.RentalProduct.UpdateRentalProductDetail,
            formData, {
            headers: {
                "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
            }
        });
        return response.data;
    } catch (error) {
        console.log("error", error);
        const errorMessage = error?.response?.data?.message || "Something went wrong!";
        return rejectWithValue(errorMessage);
    }
});


const UpdateRentalProductDetailSlice = createSlice({
    name: "UpdateRentalProductDetail",
    initialState: {
        UpdateRentalProductDetailData: {},
        success: false,
        error: false,
        loading: false,
        errorMessage: "",
    },
    reducers: {
        ResetUpdateRentalProductDetailData: (state, action) => {
            state.success = false;
            state.error = false;
            state.loading = false;
            state.errorMessage = "";
            state.UpdateRentalProductDetailData = {};
        },
    },
    extraReducers: {
        [UpdateRentalProductDetailThunk.pending]: (state, action) => {
            state.loading = true;
            state.success = false;
            state.error = false;
        },
        [UpdateRentalProductDetailThunk.fulfilled]: (state, action) => {
            state.UpdateRentalProductDetailData = action.payload;
            state.success = true;
            state.loading = false;
        },
        [UpdateRentalProductDetailThunk.rejected]: (state, action) => {
            state.errorMessage = action.payload;
            state.error = true;
            state.loading = false;
            state.UpdateRentalProductDetailData = {};
        },
    },
});

export default UpdateRentalProductDetailSlice.reducer;

export const { ResetUpdateRentalProductDetailData } = UpdateRentalProductDetailSlice.actions;

export { UpdateRentalProductDetailThunk };
