import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiEndpoints } from "../../utils/ApiEndPoints";

const AdsImageReOrderThunk = createAsyncThunk("AdsImageReOrder/AdsImageReOrderThunk", async ({ page_id, advertisements }, { rejectWithValue }) => {
    try {
        const response = await axios.put(ApiEndpoints.Advertisement.AdsImagesReOrder,
            { page_id, advertisements },
            {
                headers: {
                    "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
                }
            });
        return response.data;
    } catch (error) {
        console.log("error", error);
        return rejectWithValue(error.response.data);
    }
});

const AdsImageReOrderSlice = createSlice({
    name: "AdsImageReOrder",
    initialState: {
        AdsImageReOrderData: {},
        success: false,
        error: false,
        loading: false,
        errorMessage: "",
    },
    reducers: {
        ResetAdsImageReOrderData: (state, action) => {
            state.AdsImageReOrderData = {};
            state.success = false;
            state.error = false;
            state.loading = false;
            state.errorMessage = "";
        },
    },
    extraReducers: {
        [AdsImageReOrderThunk.pending]: (state, action) => {
            state.loading = true;
            state.success = false;
            state.error = false;
        },
        [AdsImageReOrderThunk.fulfilled]: (state, action) => {
            state.AdsImageReOrderData = action.payload;
            state.success = true;
            state.loading = false;
        },
        [AdsImageReOrderThunk.rejected]: (state, action) => {
            state.errorMessage = action.payload.message;
            state.error = true;
            state.loading = false;
            state.AdsImageReOrderData = {};
        },
    },
});

export default AdsImageReOrderSlice.reducer;

export const { ResetAdsImageReOrderData } = AdsImageReOrderSlice.actions;

export { AdsImageReOrderThunk };
