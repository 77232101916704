import React, { useEffect, useState, useCallback } from 'react';
import { Button, Col, Form, Modal, ModalBody, Spinner } from 'reactstrap';

import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-hot-toast';
import { SortableContainer, SortableElement, arrayMove } from 'react-sortable-hoc';

import { Icon } from '../../components/Component';
import { GetAllListingThunk } from '../../redux/listing/GetAllListingSlice';
import { ListingDetailThunk } from '../../redux/listing/ListingDetailSlice';
import { ProductImageReOrderThunk, ResetProductImageReOrderData } from '../../redux/listing/ProductImageReOrderSlice';

const ProductImageReOrderModal = ({ modal, setModal, productId, productData }) => {
    const dispatch = useDispatch();

    const [images, setImages] = useState([]);
    const { loading, success, error, errorMessage } = useSelector((state) => state.listing.listingImagesReOrder);
    const {
        loading: ListingDetailLoading,
        listingData
    } = useSelector((state) => state.listing.listingDetail);

    useEffect(() => {
        dispatch(ListingDetailThunk({ listingId: productId }));
    }, [dispatch, productId]);

    useEffect(() => {
        setImages(listingData?.data?.ProductImages);
    }, [listingData?.data]);

    const onSortEnd = useCallback(({ oldIndex, newIndex }) => {
        setImages(arrayMove(images, oldIndex, newIndex));
    }, [images]);

    const SortableList = SortableContainer(({ images }) => {
        return (
            <div>
                <span> Drag Images up & down to update order </span>
                &nbsp;
                <br />
                <br />
                {images?.map((value, index) => {
                    return (
                        <>
                            <span>order no. {index + 1}</span>
                            <SortableItem key={`item-${index}`} index={index} value={value} />
                        </>
                    )
                })}
            </div>
        );
    });

    const SortableItem = SortableElement(({ value }) => {
        return (
            <div className='p-2'>
                <img src={value.image} width="200" height="150" alt="" />
            </div>
        )
    });

    const handleProductImagesReOrder = (e) => {
        e.preventDefault();
        dispatch(ProductImageReOrderThunk({ product_id: listingData?.data?.product_id, images: images }))
    }

    if (success) {
        toast.success("Images has been re-ordered successfullly.");
        dispatch(ResetProductImageReOrderData());
        dispatch(GetAllListingThunk());
        setModal(false);
    }

    if (error) {
        toast.error(errorMessage);
        dispatch(ResetProductImageReOrderData());
        dispatch(GetAllListingThunk())
        setModal(false);
    }

    return (
        <React.Fragment>
            <Modal isOpen={modal.productImagesReOrder} toggle={() => setModal(false)} className="modal-dialog-centered" size="sm">
                <ModalBody>
                    <div className="close" onClick={(ev) => { ev.preventDefault(); setModal(false); }}>
                        <Icon name="cross-sm"></Icon>
                    </div>
                    <div className="p-2">
                        <h4 className="title">Product Images Re-Order</h4>
                        <div className="mt-4">
                            {ListingDetailLoading ?
                                <div className="d-flex justify-content-center align-items-center w-100" style={{ height: "60vh" }}>
                                    <Spinner />
                                </div>
                                : (
                                    <Form className="row gy-4 mt-4 mb-5">
                                        <Col md="12">
                                            <SortableList images={images} onSortEnd={onSortEnd} />
                                        </Col>
                                        <Col size="12">
                                            <ul className="d-flex justify-content-between flex-wrap flex-sm-nowrap gx-4 gy-2">
                                                <li>
                                                    <Button disabled={loading} color="primary" size="md" type="submit" onClick={handleProductImagesReOrder}>
                                                        <span>{loading ? <Spinner size="md" /> : "Update"} </span>
                                                    </Button>
                                                </li>
                                                <li>
                                                    <div className="link link-light" onClick={(ev) => { ev.preventDefault(); setModal(false); }}>
                                                        Cancel
                                                    </div>
                                                </li>
                                            </ul>
                                        </Col>
                                    </Form>
                                )}
                        </div>
                    </div>
                </ModalBody>
            </Modal>

        </React.Fragment>
    )
};

export default ProductImageReOrderModal;