import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiEndpoints } from "../../utils/ApiEndPoints";



const GetUserProfileThunk = createAsyncThunk("UserProfile/GetUserProfileThunk", async (_, { rejectWithValue, fulfillWithValue }) => {
    try {
        const response = await axios.get(ApiEndpoints.User.GetAllUser,
            {
                headers: {
                    "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
                }
            }
        )

        return fulfillWithValue(response.data);
    } catch (error) {
        const errorMessage = error?.response?.data?.message || "Something went wrong!";
        return rejectWithValue(errorMessage);
    }
})

const AddUserProfileThunk = createAsyncThunk("UserProfile/AddUserProfileThunk", async (_, { rejectWithValue }) => {
    try {
        const response = await axios.post(ApiEndpoints.User.AddAllUser,
            {
                headers: `Bearer ${localStorage.getItem("accessToken")}`
            })
        return response.data.data;
    } catch (error) {
        const errorMessage = error?.response?.data?.message || "Something went wrong!";
        return rejectWithValue(errorMessage);
    }
});


const handlePendingRejected = (state, action) => {
    state.loading = "idle";
    if (action.status === "rejected") {
        state.error = action.error.message;
    }
};

const initialState = {
    loading: false,
    success: false,
    error: false,
    errorMessage: "",
    usersProfileData: [],
}


const UserProfileSlice = createSlice({
    name: "UserProfile",
    initialState,
    extraReducers: (builder) => {
        builder
            .addMatcher(
                (action) => action.type.endsWith(".pending") || action.type.endsWith(".rejected"),
                handlePendingRejected
            )
            .addMatcher(
                (action) => action.type === GetUserProfileThunk.fulfilled,
                (state, action) => {
                    state.usersProfileData = action.payload;
                }
            )
            .addMatcher(
                (action) => action.type === AddUserProfileThunk.fulfilled,
                (state, action) => {
                    state.usersProfileData = action.payload;
                }
            )
        // Add matcher for other async thunk functions
    },

})


export { GetUserProfileThunk };

export default UserProfileSlice.reducer;
