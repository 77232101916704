import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiEndpoints } from "../../utils/ApiEndPoints";

const DeleteUserPackageThunk = createAsyncThunk("DeleteUserPackage/DeleteUserPackageThunk", async ({ user_package_id }, { rejectWithValue }) => {
    try {
        const response = await axios.delete(`${ApiEndpoints.User.DeleteUserPackage}?package_id=${user_package_id}`,
            {
                headers: {
                    "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
                }
            });
        return response.data;
    } catch (error) {
        console.log("error", error);
        const errorMessage = error?.response?.data?.message || "Something went wrong!";
        return rejectWithValue(errorMessage);
    }
});

const DeleteUserPackageSlice = createSlice({
    name: "DeleteUserPackage",
    initialState: {
        deleteUserPackageData: {},
        success: false,
        error: false,
        loading: false,
        errorMessage: "",
    },
    reducers: {
        ResetDeleteUserPackageData: (state, action) => {
            state.deleteUserPackageData = {};
            state.success = false;
            state.error = false;
            state.loading = false;
            state.errorMessage = "";
        },
    },
    extraReducers: {
        [DeleteUserPackageThunk.pending]: (state, action) => {
            state.loading = true;
            state.success = false;
            state.error = false;
        },
        [DeleteUserPackageThunk.fulfilled]: (state, action) => {
            state.deleteUserPackageData = action.payload;
            state.success = true;
            state.loading = false;
        },
        [DeleteUserPackageThunk.rejected]: (state, action) => {
            state.errorMessage = action.payload;
            state.error = true;
            state.loading = false;
            state.deleteUserPackageData = {};
        },
    },
});

export default DeleteUserPackageSlice.reducer;

export const { ResetDeleteUserPackageData } = DeleteUserPackageSlice.actions;

export { DeleteUserPackageThunk };
