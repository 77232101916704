import { configureStore } from "@reduxjs/toolkit";
import { AdsReducers } from "./advertisement";
import { AuthSlice } from "./auth";
import { categorySlice } from "./Category";
import { contactUsReducers } from "./ContactUs";
import { listingSlice } from "./listing";
import { packageSlice } from "./package";
import { quotesSlice } from "./quotes";
import { subscribedEmail } from "./SubscribedEmail";
import { userSlice } from "./user";
import { rentalCategoryReducers } from "./RentalCategory";
import { rentalProductSlice } from "./RentalProduct";

export const store = configureStore({
    reducer: {
        auth: AuthSlice,
        listing: listingSlice,
        user: userSlice,
        category: categorySlice,
        rentalCategory: rentalCategoryReducers,
        package: packageSlice,
        quote: quotesSlice,
        contactUs: contactUsReducers,
        subscribedEmail: subscribedEmail,
        advertisement: AdsReducers,
        rentalProducts: rentalProductSlice
    }
})
