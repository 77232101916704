import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiEndpoints } from "../../utils/ApiEndPoints";

const UpdatePackageDetailThunk = createAsyncThunk("UpdatePackageDetail/UpdatePackageDetailThunk", async ({ name, listings, validity, price, isActive, id }, { rejectWithValue }) => {
    try {
        const response = await axios.put(ApiEndpoints.Package.UpdatePackageDetail,
            {
                ...(name && { name: name }),
                ...(id && { id: id }),
                ...(listings && { listings: listings }),
                ...(validity && { validity: validity }),
                ...(price && { price: price }),
                active: isActive,
            },
            {
                headers: {
                    "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
                }
            });
        return response.data;
    } catch (error) {
        console.log("error", error);
        return rejectWithValue(error.response.data);
    }
});

const UpdatePackageDetailSlice = createSlice({
    name: "UpdatePackageDetail",
    initialState: {
        updatePackageDetailData: {},
        success: false,
        error: false,
        loading: false,
        errorMessage: "",
    },
    reducers: {
        ResetUpdatePackageDetailData: (state, action) => {
            state.updatePackageDetailData = {};
            state.success = false;
            state.error = false;
            state.loading = false;
            state.errorMessage = "";
        },
    },
    extraReducers: {
        [UpdatePackageDetailThunk.pending]: (state, action) => {
            state.loading = true;
            state.success = false;
            state.error = false;
        },
        [UpdatePackageDetailThunk.fulfilled]: (state, action) => {
            state.updatePackageDetailData = action.payload;
            state.success = true;
            state.loading = false;
        },
        [UpdatePackageDetailThunk.rejected]: (state, action) => {
            state.errorMessage = action.payload.message;
            state.error = true;
            state.loading = false;
            state.updatePackageDetailData = {};
        },
    },
});

export default UpdatePackageDetailSlice.reducer;

export const { ResetUpdatePackageDetailData } = UpdatePackageDetailSlice.actions;

export { UpdatePackageDetailThunk };
