import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiEndpoints } from "../../utils/ApiEndPoints";

const GetAllRentalProductsThunk = createAsyncThunk("GetAllRentalProducts/GetAllRentalProductsThunk", async (_, { rejectWithValue }) => {
    try {
        const response = await axios.get(ApiEndpoints.RentalProduct.GetAllRentalProducts,
            {
                headers: {
                    "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
                }
            });
        return response.data.data;
    } catch (error) {
        console.log("error", error);
        const errorMessage = error?.response?.data?.message || "Something went wrong!";
        return rejectWithValue(errorMessage);
    }
});

const GetAllRentalProductsSlice = createSlice({
    name: "GetAllRentalProducts",
    initialState: {
        allRentalProductsData: [],
        success: false,
        error: false,
        loading: false,
        errorMessage: "",
    },
    reducers: {
        ResetGetAllRentalProductsData: (state, action) => {
            state.allRentalProductsData = [];
            state.success = false;
            state.error = false;
            state.loading = false;
            state.errorMessage = "";
        },
    },
    extraReducers: {
        [GetAllRentalProductsThunk.pending]: (state, action) => {
            state.loading = true;
            state.success = false;
            state.error = false;
        },
        [GetAllRentalProductsThunk.fulfilled]: (state, action) => {
            state.allRentalProductsData = action.payload;
            state.success = true;
            state.loading = false;
        },
        [GetAllRentalProductsThunk.rejected]: (state, action) => {
            state.errorMessage = action.payload;
            state.error = true;
            state.loading = false;
            state.allRentalProductsData = [];
        },
    },
});

export default GetAllRentalProductsSlice.reducer;

export const { ResetGetAllRentalProductsData } = GetAllRentalProductsSlice.actions;

export { GetAllRentalProductsThunk };
