import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiEndpoints } from "../../utils/ApiEndPoints";

const AddRentalProductThunk = createAsyncThunk("AddRentalProduct/AddRentalProductThunk", async ({ title, content, price, images, year, make, model, machine_type, machine_location, hours, mileage, service_history, serial_number, seller_logo }, { rejectWithValue }) => {
    const formData = new FormData();
    formData.append("title", title);
    formData.append("description", content);
    formData.append("renting_price", price ? price : "POA");
    formData.append("year", year);
    formData.append("make", make);
    formData.append("model", model);
    formData.append("machine_type", machine_type);
    formData.append("machine_location", machine_location);
    formData.append("hours", hours);
    formData.append("mileage", mileage);
    formData.append("service_history", service_history);
    formData.append("serial_number", serial_number);

    for (let i = 0; i < images.length; i++) {
        formData.append("products", images[i].file);
    }
    for (let i = 0; i < seller_logo.length; i++) {
        formData.append("seller_logo", seller_logo[i].file);
    }

    try {
        const response = await axios.post(ApiEndpoints.RentalProduct.AddRentalProduct,
            formData,
            {
                headers: {
                    "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
                }
            });
        return response.data;
    } catch (error) {
        console.log("error", error);
        const errorMessage = error?.response?.data?.message || "Something went wrong!";
        return rejectWithValue(errorMessage);
    }
});

const AddRentalProductSlice = createSlice({
    name: "AddRentalProduct",
    initialState: {
        AddRentalProductData: {},
        success: false,
        error: false,
        loading: false,
        errorMessage: "",
    },
    reducers: {
        ResetAddRentalProductData: (state, action) => {
            state.success = false;
            state.error = false;
            state.loading = false;
            state.errorMessage = "";
        },
    },
    extraReducers: {
        [AddRentalProductThunk.pending]: (state, action) => {
            state.loading = true;
            state.success = false;
            state.error = false;
        },
        [AddRentalProductThunk.fulfilled]: (state, action) => {
            state.AddRentalProductData = action.payload;
            state.success = true;
            state.loading = false;
        },
        [AddRentalProductThunk.rejected]: (state, action) => {
            state.errorMessage = action.payload;
            state.error = true;
            state.loading = false;
            state.AddRentalProductData = {};
        },
    },
});

export default AddRentalProductSlice.reducer;

export const { ResetAddRentalProductData } = AddRentalProductSlice.actions;

export { AddRentalProductThunk };
