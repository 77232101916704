import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiEndpoints } from "../../utils/ApiEndPoints";

const AddRentalCategoryThunk = createAsyncThunk("AddRentalCategory/AddRentalCategoryThunk", async ({ name, description, images }, { rejectWithValue }) => {
    const formData = new FormData();
    formData.append("name", name);
    formData.append("description", description);
    for (let i = 0; i < images.length; i++) {
        formData.append("rentingCategory", images[i].file);
    }

    try {
        const response = await axios.post(ApiEndpoints.RentalCategory.AddRentalCategory,
            formData,
            {
                headers: {
                    "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
                }
            });
        return response.data;
    } catch (error) {
        console.log("error", error);
        return rejectWithValue(error.response.data);
    }
});

const AddRentalCategorySlice = createSlice({
    name: "AddRentalCategory",
    initialState: {
        addRentalCategoryData: {},
        success: false,
        error: false,
        loading: false,
        errorMessage: "",
    },
    reducers: {
        ResetAddRentalCategoryData: (state, action) => {
            state.addRentalCategoryData = {};
            state.success = false;
            state.error = false;
            state.loading = false;
            state.errorMessage = "";
        },
    },
    extraReducers: {
        [AddRentalCategoryThunk.pending]: (state, action) => {
            state.loading = true;
            state.success = false;
            state.error = false;
        },
        [AddRentalCategoryThunk.fulfilled]: (state, action) => {
            state.addRentalCategoryData = action.payload;
            state.success = true;
            state.loading = false;
        },
        [AddRentalCategoryThunk.rejected]: (state, action) => {
            state.errorMessage = action.payload.message;
            state.error = true;
            state.loading = false;
            state.addRentalCategoryData = {};
        },
    },
});

export default AddRentalCategorySlice.reducer;

export const { ResetAddRentalCategoryData } = AddRentalCategorySlice.actions;

export { AddRentalCategoryThunk };
