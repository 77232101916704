import React from 'react'

import { Formik } from 'formik';
import { Button, Col, Form, Modal, ModalBody, Spinner } from 'reactstrap';

import { Icon } from '../../components/Component';
import { useDispatch } from 'react-redux';
import { DeleteListingThunk, ResetDeleteListingData } from '../../redux/listing/DeleteListingSlice';
import { useSelector } from 'react-redux';
import { toast } from 'react-hot-toast';
import { GetAllListingThunk } from '../../redux/listing/GetAllListingSlice';

const DeleteListingModal = ({ modal, setModal, listingId }) => {
    const dispatch = useDispatch();

    const { loading, success, error, errorMessage } = useSelector((state) => state.listing.deleteListing)
    const handleDeleteListing = (e) => {
        dispatch(DeleteListingThunk({ listingId }))
    }

    if (success) {
        toast.success("Listing has been deleted successfullly.");
        dispatch(ResetDeleteListingData({ listingId }));
        dispatch(GetAllListingThunk())
        setModal(false);
    }

    if (error) {
        toast.error(errorMessage);
        dispatch(ResetDeleteListingData());
        setModal(false);
    }

    return (
        <React.Fragment>
            <Modal
                isOpen={modal.deleteListing}
                toggle={() => setModal(false)}
                className="modal-dialog-centered"
                size="md"
            >
                <ModalBody>
                    <a href="#cancel" className="close" onClick={(ev) => { ev.preventDefault(); setModal(false); }}>
                        <Icon name="cross-sm"></Icon>
                    </a>
                    <div className="p-2">
                        <h4 className="title">Delete Listing</h4>
                        <div className="mt-4">
                            <Formik>
                                <Form className="row gy-4 mt-4 mb-5">
                                    <Col md="12">
                                        <div>
                                            <h6>Are you sure you want to delete listing?</h6>
                                        </div>
                                    </Col>
                                    <Col size="12">
                                        <ul className="d-flex justify-content-between flex-wrap flex-sm-nowrap gx-4 gy-2">
                                            <li>
                                                <Button disabled={loading} color="danger" size="md" type="submit" onClick={handleDeleteListing}>
                                                    Delete
                                                    <span>{loading && <Spinner size="sm" />} </span>
                                                </Button>
                                            </li>
                                            <li>
                                                <div className="link link-light" onClick={(ev) => { ev.preventDefault(); setModal(false); }}>
                                                    Cancel
                                                </div>
                                            </li>
                                        </ul>
                                    </Col>
                                </Form>
                            </Formik>
                        </div>
                    </div>
                </ModalBody>
            </Modal>

        </React.Fragment>
    )
}

export default DeleteListingModal