import React from 'react'

import { Formik } from 'formik';
import { Button, Col, Form, Modal, ModalBody, Spinner } from 'reactstrap';

import { Icon } from '../../../components/Component';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { ResetResubscribeUserPackageData, ResubscribeUserPackageThunk } from '../../../redux/user/ResubscribeUserPackageSlice';
import { GetUserPackageThunk } from '../../../redux/user/GetUserPackageSlice';
import { toast } from 'react-hot-toast';

const ResubscribeUserPackageModal = ({ modal, setModal, user_package_id, user_id }) => {
    const dispatch = useDispatch();

    const { loading, success, error, errorMessage } = useSelector((state) => state.user.ResubscribeUserPackage)
    const handleReSubscribePackage = (e) => {
        dispatch(ResubscribeUserPackageThunk({ package_id: user_package_id }))
    }

    if (success) {
        toast.success("Package has been re-subscribed successfullly.");
        dispatch(ResetResubscribeUserPackageData());
        dispatch(GetUserPackageThunk({ id: user_id }));
        setModal(false);
    }

    if (error) {
        toast.error(errorMessage);
        dispatch(ResetResubscribeUserPackageData());
        dispatch(GetUserPackageThunk({ id: user_id }));
        setModal(false);
    }

    return (
        <React.Fragment>
            <Modal
                isOpen={modal}
                toggle={() => setModal(false)}
                className="modal-dialog-centered"
                size="md"
            >
                <ModalBody>
                    <div className="close" onClick={(ev) => { ev.preventDefault(); setModal(false); }}>
                        <Icon name="cross-sm"></Icon>
                    </div>
                    <div className="p-2">
                        <h4 className="title">Re-Subscribe User Package</h4>
                        <div className="mt-4">
                            <Formik>
                                <Form className="row gy-4 mt-4 mb-5">
                                    <Col md="12">
                                        <div>
                                            <p>Are you sure you want to re-subscribe user package?</p>
                                        </div>
                                    </Col>
                                    <Col size="12">
                                        <ul className="d-flex justify-content-between flex-wrap flex-sm-nowrap gx-4 gy-2">
                                            <li>
                                                <Button disabled={loading} color="primary" size="md" type="submit" onClick={handleReSubscribePackage}>
                                                    <span>{loading ? <Spinner size="md" /> : "Re-Subscribe user package"} </span>
                                                </Button>
                                            </li>
                                            <li>
                                                <div className="link link-light" onClick={(ev) => { ev.preventDefault(); setModal(false); }}>
                                                    Cancel
                                                </div>
                                            </li>
                                        </ul>
                                    </Col>
                                </Form>
                            </Formik>
                        </div>
                    </div>
                </ModalBody>
            </Modal>

        </React.Fragment>
    )
}

export default ResubscribeUserPackageModal;