import React, { useState } from 'react'

import { UncontrolledDropdown, DropdownMenu, DropdownToggle, Card, DropdownItem, Spinner } from "reactstrap";

import Content from "../../../../layout/content/Content";
import { Button, Block, BlockBetween, BlockDes, BlockHead, BlockHeadContent, BlockTitle, Icon } from "../../../../components/Component";
import { useParams } from 'react-router';
import { useEffect } from 'react';
import { GetUserListingThunk } from '../../../../redux/user/GetUserListingSlice';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import ReactPaginate from 'react-paginate';
import AddUserRentalProductModal from './AddUserRentalProductModal';
import ViewUserRentalDetailModal from './ViewUserRentalDetailModal';
import { GetUserRentalProductThunk } from '../../../../redux/user/GetUserRentalProductSlice';

const UserRentalProduct = () => {
    const { id } = useParams();
    const dispatch = useDispatch();

    const { loading, success, error, errorMessage, userRentalProductData } = useSelector((state) => state.user.GetUserRentalProduct)
    const [selectedListingId, setSelectedListingId] = useState("");
    const [onSearchText, setonSearchText] = useState("");
    const [onSearch, setonSearch] = useState(true);

    const [listingDetail, setListingDetail] = useState({});
    const [modal, setModal] = useState({
        viewDetail: false,
        add: false
    });
    // ********************************** Search and Pagination start ***********************************//

    const UsersPerPage = 5;
    const [allUser, setAllUserListing] = useState([]);
    const [pageNumber, setPageNumber] = useState(0);

    const pagesVisited = pageNumber * UsersPerPage;
    const pageCount = Math.ceil(
        allUser?.filter((userData) => {
            if (onSearchText === "") {
                return userData;
            } else if (
                userData.username.toLowerCase().includes(onSearchText.toLowerCase()) ||
                userData.email.toLowerCase().includes(onSearchText.toLowerCase())
            ) {
                return userData;
            }
            return false;
        }).length / UsersPerPage
    );

    const displayUserListings = allUser
        ?.filter((userData) => {
            if (onSearchText === "") {
                return userData;
            } else if (
                userData.username.toLowerCase().includes(onSearchText.toLowerCase()) ||
                userData.email.toLowerCase().includes(onSearchText.toLowerCase())
            ) {
                return userData;
            }
            return false;
        })
        .slice(pagesVisited, pagesVisited + UsersPerPage);

    useEffect(() => {
        setAllUserListing(userRentalProductData?.products);
    }, [userRentalProductData]);
    const paginate = ({ selected }) => setPageNumber(selected);

    useEffect(() => {
        setPageNumber(0);
    }, [onSearchText]);

    // ********************************** Pagination End ***********************************//


    useEffect(() => {
        dispatch(GetUserRentalProductThunk({ id }));
    }, [dispatch]);

    const toggle = () => setonSearch(!onSearch);

    return (
        <React.Fragment>
            <Content>
                <BlockHead size="sm">
                    <BlockBetween>
                        <BlockHeadContent>
                            <BlockTitle page>User Rental Products</BlockTitle>
                            <BlockDes className="text-soft">
                                <p>You have total {userRentalProductData?.products_no} products.</p>
                            </BlockDes>
                        </BlockHeadContent>
                        <BlockHeadContent>
                            <ul className="nk-block-tools g-3">
                                <li>
                                    <Button color="primary" onClick={() => setModal({ add: true })}>
                                        <Icon name="plus"></Icon>
                                        <span>Add Rental Product</span>
                                    </Button>
                                </li>
                            </ul>
                        </BlockHeadContent>
                    </BlockBetween>
                </BlockHead>

                <Block>
                    <Card className="card-bordered card-stretch">
                        <div className="card-inner-group">
                            <div className="card-inner">
                                <div className="card-title-group">
                                    <div className="card-title">
                                        {/* <h5 className="title">All User Rental Products</h5> */}
                                    </div>
                                    <div className="card-tools mr-n1">
                                        <ul className="btn-toolbar">
                                            <li>
                                                <Button onClick={toggle} className="btn-icon search-toggle toggle-search">
                                                    <Icon name="search"></Icon>
                                                </Button>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className={`card-search search-wrap ${!onSearch ? "active" : ""}`}>
                                        <div className="search-content">
                                            <Button className="search-back btn-icon toggle-search" onClick={() => { setonSearchText(""); toggle(); }}>
                                                <Icon name="arrow-left"></Icon>
                                            </Button>
                                            <input type="text" className="form-control border-transparent form-focus-none" onChange={(e) => setonSearchText(e.target.value)} placeholder="Search" value={onSearchText}
                                            />
                                            <Button className="search-submit btn-icon">
                                                <Icon name="search"></Icon>
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-inner p-0">
                                {
                                    loading ?
                                        <div className="d-flex justify-content-center align-items-center w-100" style={{ height: "30vh" }}>
                                            <Spinner />
                                        </div>
                                        : (
                                            <table className="table table-tranx">
                                                <thead>
                                                    <tr className="tb-tnx-head">
                                                        <th className="tb-tnx-id">
                                                            <span className="">#</span>
                                                        </th>
                                                        <th className="tb-tnx-info">
                                                            <span className="tb-tnx-desc d-none d-sm-inline-block">
                                                                <span>Product Id</span>
                                                            </span>
                                                            <span className="tb-tnx-date d-md-inline-block d-none">
                                                                {/* <span className="d-md-none">Make</span> */}
                                                                <span className="d-none d-md-block">
                                                                    <span>Make</span>
                                                                    <span>Model</span>
                                                                </span>
                                                            </span>
                                                        </th>
                                                        <th className="tb-tnx-amount is-alt">
                                                            <span className="tb-tnx-total">price</span>
                                                            <span className="tb-tnx-status d-none d-md-inline-block">Status</span>
                                                        </th>
                                                        <th className="tb-tnx-action">
                                                            <span>Action</span>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {error || userRentalProductData?.products_no === 0 ?
                                                        <div className="text-center">
                                                            <span className="text-silent">No data found</span>
                                                        </div>
                                                        :
                                                        displayUserListings?.map((userListing, index) => {
                                                            return (
                                                                <tr className="tb-tnx-item">
                                                                    <td className="tb-tnx-id">
                                                                        <a href="#ref" onClick={(ev) => { ev.preventDefault(); }} >
                                                                            {pageNumber * UsersPerPage + index + 1}
                                                                        </a>
                                                                    </td>
                                                                    <td className="tb-tnx-info">
                                                                        <div className="tb-tnx-desc">
                                                                            <span className="title">{userListing?.product_id}</span>
                                                                        </div>
                                                                        <div className="tb-tnx-date">
                                                                            <span className="date">{userListing?.make}</span>
                                                                            <span className="date">{userListing?.model}</span>
                                                                        </div>
                                                                    </td>
                                                                    <td className="tb-tnx-amount is-alt">
                                                                        <div className="tb-tnx-total">
                                                                            <span className="amount">{userListing?.price}</span>
                                                                        </div>
                                                                        <div className="tb-tnx-status">
                                                                            <span className={`badge badge-dot badge-${userListing?.approved === true ? "success" : "warning"}`}>
                                                                                {userListing?.approved === true ? "Approved" : "Pending"}
                                                                            </span>
                                                                        </div>
                                                                    </td>
                                                                    <td className="tb-tnx-action">
                                                                        <UncontrolledDropdown>
                                                                            <DropdownToggle tag="a" className="text-soft dropdown-toggle btn btn-icon btn-trigger">
                                                                                <Icon name="more-h"></Icon>
                                                                            </DropdownToggle>
                                                                            <DropdownMenu right>
                                                                                <ul className="link-list-plain">
                                                                                    <li>
                                                                                        <DropdownItem tag="a" href="#view" onClick={(ev) => { ev.preventDefault(); setModal({ viewDetail: true }); setSelectedListingId(userListing?.product_id) }}>
                                                                                            View Detail
                                                                                        </DropdownItem>
                                                                                    </li>
                                                                                    {/* <li>
                                                                                        <DropdownItem tag="a" href="#view" onClick={(ev) => { ev.preventDefault(); setModal({ changeListingStatus: true }); setSelectedListingId() }}>
                                                                                            Update Status
                                                                                        </DropdownItem>
                                                                                    </li>
                                                                                    <li>
                                                                                        <DropdownItem tag="a" href="#print" onClick={(ev) => { ev.preventDefault(); setModal({ deleteListing: true }); setSelectedListingId() }}>
                                                                                            Delete Listing
                                                                                        </DropdownItem>
                                                                                    </li> */}
                                                                                </ul>
                                                                            </DropdownMenu>
                                                                        </UncontrolledDropdown>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                        )
                                }
                            </div>
                            <div className="card-inner">
                                <ReactPaginate
                                    breakLabel="..."
                                    breakClassName="pagination-btns"
                                    onPageChange={(number) => paginate(number)}
                                    // onPageChange={handlePageClick}
                                    pageRangeDisplayed={3}
                                    pageCount={pageCount}
                                    previousLabel="<<  Prev "
                                    renderOnZeroPageCount={null}
                                    nextLabel="Next >>"
                                    previousClassName="pagination-next"
                                    nextClassName="pagination-next"
                                    activeClassName="pagination-active"
                                    pageClassName="pagination mt-0"
                                    pageLinkClassName="pagination-btns"
                                    containerClassName="pagination justify-content-start"
                                />
                            </div>
                        </div>
                    </Card>
                </Block>
            </Content>
            {modal.viewDetail && <ViewUserRentalDetailModal openModal={modal.viewDetail} setOpenModal={() => setModal({ viewDetail: false })} product_id={selectedListingId} />}
            {modal.add && <AddUserRentalProductModal modal={modal.add} setModal={() => setModal({ add: false })} product_id={selectedListingId} user_id={id} />}
        </React.Fragment>
    )
}

export default UserRentalProduct;