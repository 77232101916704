import React, { useEffect, useState } from 'react'

import { useForm } from 'react-hook-form';
import ReactImageUploading from 'react-images-uploading';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-hot-toast';
import { Button, Col, Form, FormGroup, Modal, ModalBody, Spinner } from 'reactstrap';

import { Icon } from '../../components/Component';
import { GetAllAdvertisementThunk } from '../../redux/advertisement/AdvertisementSlice';
import { ResetUpdateAdvertisementData, UpdateAdvertisementThunk } from '../../redux/advertisement/UpdateAdvertisementSlice';

const UpdateAdsModal = ({ modal, setModal, data }) => {
    const dispatch = useDispatch();

    const [image, setImage] = useState([]);


    const [formData, setFormData] = useState({
        ad_link: data?.AdvertisementImages[0]?.ad_link,
        web_page_id: 1,
    })

    const { loading, success, error, errorMessage } = useSelector((state) => state.advertisement.updateAdvertisement);

    const onChange = (imageList) => {
        setImage(imageList);
    };

    const onInputChange = (e) => {
        e.preventDefault();
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    const handleUpdateAdForm = () => {
        dispatch(UpdateAdvertisementThunk({ ad_link: formData?.ad_link, image, web_page_id: data?.id }));
    }

    if (success) {
        toast.success("Ad has been updated successfully.");
        dispatch(ResetUpdateAdvertisementData());
        dispatch(GetAllAdvertisementThunk());
        setModal(false);
    }

    if (error) {
        toast.error(errorMessage);
        dispatch(ResetUpdateAdvertisementData());
        dispatch(GetAllAdvertisementThunk());
        setModal(false);
    }

    const { errors, register, handleSubmit } = useForm();

    return (
        <React.Fragment>
            <Modal isOpen={modal.update} toggle={() => setModal(false)} className="modal-dialog-centered" size="lg">
                <ModalBody>
                    <div onClick={() => setModal(false)} className="close cursor-pointer">
                        <Icon name="cross-sm"></Icon>
                    </div>
                    <div className="p-2">
                        <h5 className="title">Update Ad</h5>
                        <div className="mt-4">
                            <Form className="row gy-4 mt-4" >
                                <Col md="12">
                                    <FormGroup>
                                        <ReactImageUploading className="form-control" multiple value={image} onChange={onChange} maxNumber={1} dataURLKey="data_url">
                                            {({ imageList, onImageUpload, onImageRemove, isDragging, dragProps }) => (
                                                <div className="upload__image-wrapper">
                                                    {image.length === 0 &&
                                                        <Button style={isDragging ? { color: "red" } : undefined} onClick={onImageUpload} className="btn-primary" {...dragProps}>
                                                            Update Ad Image
                                                        </Button>
                                                    }
                                                    &nbsp;
                                                    <div className="row image-item">
                                                        {image.length !== 0 ?
                                                            imageList.map((image, index) => (
                                                                <div>
                                                                    <img src={image["data_url"]} alt="" width="120" height="100" />
                                                                    <div className="image-item__btn-wrapper">
                                                                        <Button className="btn-primary mt-1" onClick={() => onImageRemove(index)}>
                                                                            Remove
                                                                        </Button>
                                                                    </div>
                                                                </div>
                                                            )) : (
                                                                <div className='p-3'>
                                                                    <img src={data?.AdvertisementImages[0]?.image} alt="" width="120" height="100" />
                                                                    <div className="image-item__btn-wrapper">
                                                                    </div>
                                                                </div>
                                                            )}
                                                    </div>
                                                </div>
                                            )}
                                        </ReactImageUploading>
                                    </FormGroup>
                                </Col>
                                <Col md="12">
                                    <FormGroup>
                                        <label className="form-label">Ad Link</label>
                                        <input className="form-control" ref={register({ required: "This field is required" })} value={formData?.ad_link} onChange={onInputChange} type="text" name="ad_link"
                                        />
                                        {errors.ad_link && <span className="invalid">{errors.ad_link.message}</span>}
                                    </FormGroup>
                                </Col>
                                <Col md="12">
                                    <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                                        <li>
                                            <Button onClick={(e) => { e.preventDefault(); handleUpdateAdForm() }} disabled={loading} color="primary" size="lg" type="submit">
                                                <span>{loading ? <Spinner size="sm" /> : "Update Ad"} </span>
                                            </Button>
                                        </li>
                                        <li>
                                            <div onClick={() => setModal(false)} className="link link-light cursor-pointer">
                                                Cancel
                                            </div>
                                        </li>
                                    </ul>
                                </Col>
                            </Form>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </React.Fragment>
    )
}

export default UpdateAdsModal;