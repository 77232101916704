import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiEndpoints } from "../../utils/ApiEndPoints";

const DeleteAdvertisementThunk = createAsyncThunk("DeleteAdvertisement/DeleteAdvertisementThunk", async ({ id }, { rejectWithValue }) => {
    try {
        const response = await axios.delete(`${ApiEndpoints.Advertisement.DeleteAdvertisement}?id=${id}`,
            {
                headers: {
                    "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
                }
            });
        return response.data;
    } catch (error) {
        console.log('error', error);
        const errorMessage = error.response.data.message ? error.response.data.message : "Something Went Wrong!";
        return rejectWithValue(errorMessage);
    }
});

const DeleteAdvertisementSlice = createSlice({
    name: "DeleteAdvertisement",
    initialState: {
        deleteAdvertisementData: {},
        success: false,
        error: false,
        loading: false,
        errorMessage: "",
    },
    reducers: {
        ResetDeleteAdvertisement: (state, action) => {
            state.deleteAdvertisementData = {};
            state.success = false;
            state.error = false;
            state.loading = false;
            state.errorMessage = "";
        },
    },
    extraReducers: {
        [DeleteAdvertisementThunk.pending]: (state, action) => {
            state.loading = true;
            state.success = false;
            state.error = false;
        },
        [DeleteAdvertisementThunk.fulfilled]: (state, action) => {
            state.deleteAdvertisementData = action.payload;
            state.success = true;
            state.loading = false;
        },
        [DeleteAdvertisementThunk.rejected]: (state, action) => {
            state.errorMessage = action.payload;
            state.error = true;
            state.loading = false;
            state.deleteAdvertisementData = {};
        },
    },
});

export default DeleteAdvertisementSlice.reducer;

export const { ResetDeleteAdvertisement } = DeleteAdvertisementSlice.actions;

export { DeleteAdvertisementThunk };
