import { combineReducers } from "@reduxjs/toolkit";
import AddListingSlice from "./AddListingSlice";
import ChangeListingStatusSlice from "./ChangeListingStatusSlice";
import DeleteListingSlice from "./DeleteListingSlice";
import GetAllListingSlice from "./GetAllListingSlice";
import ListingDetailSlice from "./ListingDetailSlice";
import ProductImageReOrderSlice from "./ProductImageReOrderSlice";
import UpdateListingDetailSlice from "./UpdateListingDetailSlice";


export const listingSlice = combineReducers({
    getAllListing: GetAllListingSlice,
    listingDetail: ListingDetailSlice,
    addListing: AddListingSlice,
    deleteListing: DeleteListingSlice,
    changeListingStatus: ChangeListingStatusSlice,
    updateListingDetail: UpdateListingDetailSlice,
    listingImagesReOrder: ProductImageReOrderSlice
})