import React, { useState, useEffect } from "react";

import { UncontrolledDropdown, DropdownMenu, DropdownToggle, Card, DropdownItem, Spinner } from "reactstrap";
import ReactPaginate from "react-paginate";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import { Button, Block, BlockBetween, BlockDes, BlockHead, BlockHeadContent, BlockTitle, Icon } from "../../components/Component";
import { TransportQuotesThunk } from "../../redux/quotes/TransportQuotesSlice";
import TransprotQuoteDetailModal from "./TransportQuoteModal";

const TransportQuote = () => {
    const dispatch = useDispatch();
    const [onSearch, setonSearch] = useState(true);
    const [onSearchText, setSearchText] = useState("");
    const [selectedTransportQuoteData, setSelectedTransportQuote] = useState({});
    const [modal, setModal] = useState({
        quoteDetail: false,
    });

    const { loading, transportQuotesData } = useSelector((state) => state.quote.transportQuotes)

    // ********************************** Search and Pagination start ***********************************//

    const transportQuotesPerPage = 10;
    const [allTransportQuotes, setTransportQuotes] = useState([]);
    const [pageNumber, setPageNumber] = useState(0);
    const pagesVisited = pageNumber * transportQuotesPerPage;
    const pageCount = Math.ceil(
        allTransportQuotes.filter((transportQuote) => {
            if (onSearchText === "") {
                return transportQuote;
            } else if (
                transportQuote.name.toLowerCase().includes(onSearchText.toLowerCase()) ||
                transportQuote.email.toLowerCase().includes(onSearchText.toLowerCase())
            ) {
                return transportQuote;
            }
            return false;
        }).length / transportQuotesPerPage
    );

    const displayTransportQuotes = allTransportQuotes
        .filter((transportQuote) => {
            if (onSearchText === "") {
                return transportQuote;
            } else if (
                transportQuote.name.toLowerCase().includes(onSearchText.toLowerCase()) ||
                transportQuote.email.toLowerCase().includes(onSearchText.toLowerCase())
            ) {
                return transportQuote;
            }
            return false;
        })
        .slice(pagesVisited, pagesVisited + transportQuotesPerPage);

    useEffect(() => {
        setTransportQuotes(transportQuotesData);
    }, [transportQuotesData]);

    const paginate = ({ selected }) => setPageNumber(selected);

    useEffect(() => {
        setPageNumber(0);
    }, [onSearchText]);

    // ********************************** Pagination End ***********************************//

    useEffect(() => {
        dispatch(TransportQuotesThunk());
    }, [dispatch]);

    const toggle = () => setonSearch(!onSearch);

    return (
        <React.Fragment>
            <Head title="Transport Quote - Yellowish Admin"></Head>
            <Content>
                <BlockHead size="sm">
                    <BlockBetween>
                        <BlockHeadContent>
                            <BlockTitle page>Transport Quotes</BlockTitle>
                            <BlockDes className="text-soft">
                                <p>You have total {transportQuotesData?.length} transport quotes.</p>
                            </BlockDes>
                        </BlockHeadContent>
                    </BlockBetween>
                </BlockHead>

                <Block>
                    {loading ?
                        <div className="d-flex justify-content-center align-items-center w-100" style={{ height: "80vh" }}>
                            <Spinner />
                        </div>
                        : (
                            <Card className="card-bordered card-stretch">
                                <div className="card-inner-group">
                                    <div className="card-inner">
                                        <div className="card-title-group">
                                            <div className="card-title">
                                                <h5 className="title">Transport Quotes</h5>
                                            </div>
                                            <div className="card-tools mr-n1">
                                                <ul className="btn-toolbar">
                                                    <li>
                                                        <Button onClick={toggle} className="btn-icon search-toggle toggle-search">
                                                            <Icon name="search"></Icon>
                                                        </Button>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className={`card-search search-wrap ${!onSearch ? "active" : ""}`}>
                                                <div className="search-content">
                                                    <Button className="search-back btn-icon toggle-search" onClick={() => { setSearchText(""); toggle(); }}>
                                                        <Icon name="arrow-left"></Icon>
                                                    </Button>
                                                    <input type="text" className="form-control border-transparent form-focus-none" placeholder="Search" value={onSearchText}
                                                    />
                                                    <Button className="search-submit btn-icon">
                                                        <Icon name="search"></Icon>
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-inner p-0">
                                        <table className="table table-tranx">
                                            <thead>
                                                <tr className="tb-tnx-head">
                                                    <th className="tb-tnx-id">
                                                        <span className="">Transportation Quote Id</span>
                                                    </th>
                                                    <th className="tb-tnx-info">
                                                        <span className="tb-tnx-desc d-none d-sm-inline-block">
                                                            <span>Name</span>
                                                        </span>

                                                        <span className="tb-tnx-date d-md-inline-block d-none">
                                                            <span className="d-none d-md-block">
                                                                <span>Email</span>
                                                            </span>
                                                        </span>
                                                    </th>
                                                    <th className="tb-tnx-amount is-alt">
                                                        <span className="tb-tnx-total">Delivery point</span>
                                                    </th>
                                                    {/* <th className="tb-tnx-action">
                                                        <span>Action</span>
                                                    </th> */}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {displayTransportQuotes?.length === 0 ?
                                                    <div className="text-center">
                                                        <span className="text-silent">No data found</span>
                                                    </div>
                                                    : displayTransportQuotes?.map((transportQuote, index) => {
                                                        return (
                                                            <tr className="tb-tnx-item">
                                                                <td className="tb-tnx-id">
                                                                    <div >
                                                                        <span>{transportQuote?.transportation_quote_id
                                                                        }</span>
                                                                    </div>
                                                                </td>
                                                                <td className="tb-tnx-info">
                                                                    <div className="tb-tnx-desc">
                                                                        <span className="title">{transportQuote?.name}</span>
                                                                    </div>
                                                                    <div className="tb-tnx-desc">
                                                                        <span className="date">{transportQuote?.email}</span>
                                                                    </div>
                                                                </td>
                                                                <td className="tb-tnx-amount is-alt">
                                                                    <div className="tb-tnx-total">
                                                                        <span className="amount">{transportQuote?.delivery_point}</span>
                                                                    </div>
                                                                </td>
                                                                {/* <td className="tb-tnx-action">
                                                                    <UncontrolledDropdown>
                                                                        <DropdownToggle tag="a" className="text-soft dropdown-toggle btn btn-icon btn-trigger">
                                                                            <Icon name="more-h"></Icon>
                                                                        </DropdownToggle>
                                                                        <DropdownMenu right>
                                                                            <ul className="link-list-plain">
                                                                                <li>
                                                                                    <DropdownItem onClick={() => { setSelectedTransportQuote(transportQuote); setModal({ quoteDetail: true }) }}  >
                                                                                        View Detail
                                                                                    </DropdownItem>
                                                                                </li>
                                                                            </ul>
                                                                        </DropdownMenu>
                                                                    </UncontrolledDropdown>
                                                                </td> */}
                                                            </tr>
                                                        )
                                                    })
                                                }

                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="card-inner">
                                        <ReactPaginate
                                            breakLabel="..."
                                            breakClassName="pagination-btns"
                                            onPageChange={(number) => paginate(number)}
                                            // onPageChange={handlePageClick}
                                            pageRangeDisplayed={3}
                                            pageCount={pageCount}
                                            previousLabel="<<  Prev "
                                            renderOnZeroPageCount={null}
                                            nextLabel="Next >>"
                                            previousClassName="pagination-next"
                                            nextClassName="pagination-next"
                                            activeClassName="pagination-active"
                                            pageClassName="pagination mt-0"
                                            pageLinkClassName="pagination-btns"
                                            containerClassName="pagination justify-content-start"
                                        />
                                        {/* <div className="text-center">
                                    <span className="text-silent">No data found</span>
                                </div> */}
                                    </div>
                                </div>
                            </Card>
                        )}
                </Block>
            </Content>
            {modal.quoteDetail && <TransprotQuoteDetailModal openModal={modal.quoteDetail} setOpenModal={() => setModal({ quoteDetail: false })} transportQuoteDetail={selectedTransportQuoteData} />}
        </React.Fragment>
    );
}

export default TransportQuote;