import React from 'react'
import { Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';

const TransprotQuoteDetailModal = ({ openModal, setOpenModal, transportQuoteDetail }) => {

    return (
        <React.Fragment>
            <Modal isOpen={openModal} toggle={() => setOpenModal({ LessonDetail: false })} className="modal-md">
                <ModalHeader
                    className={`${transportQuoteDetail?.status &&
                        (transportQuoteDetail?.approved !== true
                            ? "fc-event-success"
                            : "fc-event-primary")
                        }`}
                    toggle={() => setOpenModal({ LessonDetail: false })}
                >
                    Listing Detail
                </ModalHeader>
                <ModalBody>
                    <Row className="gy-3 py-1">
                        {transportQuoteDetail?.transportation_quote_id &&
                            <Col sm="12">
                                <h6 className="overline-title">Transport Quote Id</h6>
                                <p id="preview-event-start">
                                    {transportQuoteDetail?.transportation_quote_id}
                                </p>
                            </Col>
                        }
                        {transportQuoteDetail?.name &&
                            <Col sm="6" id="preview-event-end-check">
                                <h6 className="overline-title">Name</h6>
                                <p id="preview-event-end">
                                    {transportQuoteDetail?.name}
                                </p>
                            </Col>
                        }
                        {transportQuoteDetail?.email &&
                            <Col sm="6" id="preview-event-description-check">
                                <h6 className="overline-title">email</h6>
                                <p id="preview-event-description">
                                    {transportQuoteDetail?.email}
                                </p>
                            </Col>
                        }
                        <Col sm="6" id="preview-event-description-check">
                            <h6 className="overline-title">machine running status</h6>
                            <p id="preview-event-description">
                                {transportQuoteDetail?.machine_running_status ? "True" : "False"}
                            </p>
                        </Col>

                        {transportQuoteDetail?.createdAt &&
                            <Col sm="6" id="preview-event-description-check">
                                <h6 className="overline-title">createdAt</h6>
                                <p id="preview-event-description">
                                    {new Date(transportQuoteDetail?.createdAt).toUTCString()}
                                </p>
                            </Col>
                        }

                        {transportQuoteDetail?.delivery_point &&
                            <Col sm="12" id="preview-event-description-check">
                                <h6 className="overline-title">delivery point</h6>
                                <p id="preview-event-description">
                                    {transportQuoteDetail?.delivery_point}
                                </p>
                            </Col>
                        }
                        {transportQuoteDetail?.additional_detail &&
                            <Col sm="12" id="preview-event-description-check">
                                <h6 className="overline-title">Addition Detail</h6>
                                <p id="preview-event-description">
                                    {transportQuoteDetail?.additional_detail}
                                </p>
                            </Col>
                        }
                        {transportQuoteDetail?.description &&
                            <Col sm="12" id="preview-event-description-check">
                                <h6 className="overline-title">description</h6>
                                <p id="preview-event-description">
                                    {transportQuoteDetail?.description}
                                </p>
                            </Col>
                        }

                    </Row>
                </ModalBody>
            </Modal>

        </React.Fragment>
    )
}

export default TransprotQuoteDetailModal;