import React from 'react'

import { Formik } from 'formik';
import { toast } from 'react-hot-toast';
import { Button, Col, Form, Modal, ModalBody, Spinner } from 'reactstrap';
import { useSelector,useDispatch } from 'react-redux';

import { Icon } from '../../components/Component';
import { ResetUpdateRentalProductStatusData, UpdateRentalProductStatusThunk } from '../../redux/RentalProduct/UpdateRentalProductStatusSlice';
import { GetAllRentalProductsThunk } from '../../redux/RentalProduct/GetAllRentalProductsSlice';

const UpdateRentalProductStatusModal = ({ modal, setModal, rentalProductId }) => {
    const dispatch = useDispatch();

    const { loading, success, error, errorMessage } = useSelector((state) => state.rentalProducts.updateRentalProductStatus)
    const handleChangeListingStatus = (e) => {
        e.preventDefault();
        dispatch(UpdateRentalProductStatusThunk({ rentalProductId }));
    }

    if (success) {
        toast.success("Rental Product status has been updated successfully")
        dispatch(ResetUpdateRentalProductStatusData());
        dispatch(GetAllRentalProductsThunk())
        setModal(false);
    }

    if (error) {
        toast.error(errorMessage);
        dispatch(ResetUpdateRentalProductStatusData());
        setModal(false);
    }

    return (
        <React.Fragment>
            <Modal
                isOpen={modal.changeRentalProductStatus}
                toggle={() => setModal(false)}
                className="modal-dialog-centered"
                size="md"
            >
                <ModalBody>
                    <div className="close cursor-pointer" onClick={(ev) => { ev.preventDefault(); setModal(false); }}>
                        <Icon name="cross-sm"></Icon>
                    </div>
                    <div className="p-2">
                        <h4 className="title">Update Rental Product Status</h4>
                        <div className="mt-4">
                            <Formik>
                                <Form className="row gy-4 mt-4 mb-5">
                                    <Col md="12">
                                        <div>
                                            <p>Are you sure you want to update rental product status?</p>
                                        </div>
                                    </Col>
                                    <Col size="12">
                                        <ul className="d-flex justify-content-start flex-wrap flex-sm-nowrap gx-4 gy-2">
                                            <li>
                                                <Button disabled={loading} onClick={handleChangeListingStatus} color="primary" size="md" type="submit">
                                                <span>{loading ? <Spinner size="sm" /> : "Update"} </span>
                                                </Button>
                                            </li>
                                            <li className='d-flex align-items-center'>
                                                <div className=" link link-light cursor-pointer" onClick={(ev) => { ev.preventDefault(); setModal(false); }}>
                                                    Cancel
                                                </div>
                                            </li>
                                        </ul>
                                    </Col>
                                </Form>
                            </Formik>
                        </div>
                    </div>
                </ModalBody>
            </Modal>

        </React.Fragment>
    )
}

export default UpdateRentalProductStatusModal;
