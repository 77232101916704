import React, { useEffect, useState } from 'react'

import { useForm } from 'react-hook-form';
import ReactImageUploading from 'react-images-uploading';
import { useSelector , useDispatch} from 'react-redux';
import { toast } from 'react-hot-toast';
import { Button, Col, Form, FormGroup, Modal, ModalBody, Row, Spinner } from 'reactstrap';
import { Icon, RSelect } from '../../components/Component';
import { AddRentalProductThunk, ResetAddRentalProductData } from '../../redux/RentalProduct/AddRentalProductSlice';
import { GetAllRentalProductsThunk } from '../../redux/RentalProduct/GetAllRentalProductsSlice';

const AddRentalProductModal = ({ modal, setModal }) => {
    const dispatch = useDispatch();

    const [productImages, setProductImages] = useState();
    const [dealerImage, setDealerImage] = useState([]);
    const [locationOption, setLocationOption] =  useState([
		{  label:"Western cape",   value: 1},
		{  label:"Eastern cape",  value: 2},
		{  label:"Kwa Zulu Natal",  value: 3},
		{  label:"Mpumala",  value: 4},
		{  label:"Free State",  value: 5},
		{  label:"Gauteng",  value: 6},
		{  label:"Limpopo",  value: 7},
		{  label:"North West",  value: 8},
		{  label:"Northern cape",  value: 9}
	]);

    const [formData, setFormData] = useState({
        title: "",
        content: "",
        price: "",
        year: "",
        make: '',
        model: '',
        machine_type: "",
        machine_location: "",
        hours: "",
        mileage: "",
        service_history: "",
        serial_number: "",
    });

    const { loading, success, error, errorMessage } = useSelector((state) => state.rentalProducts.addRentalProduct);

    const onProductImageChange = (imageList) => {
        setProductImages(imageList);
    };

    const onDealerImageChange = (imageList) => {
        setDealerImage(imageList);
    };

    const onInputChange = (e) => {
        e.preventDefault();
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    const handleAddRentalProductForm = () => {
        dispatch(AddRentalProductThunk({
            ...formData,
            images: productImages, seller_logo: dealerImage
        }))
    }

    if (success) {
        toast.success("Rental Product has been added successfully.");
        dispatch(GetAllRentalProductsThunk());
        dispatch(ResetAddRentalProductData());
        setModal(false);
    }

    if (error) {
        toast.error(errorMessage);
        dispatch(GetAllRentalProductsThunk());
        dispatch(ResetAddRentalProductData());
        setModal(false);
    }

    const { errors, register, handleSubmit } = useForm();

    return (
        <React.Fragment>
            <Modal isOpen={modal.add} toggle={() => setModal(false)} className="modal-dialog-centered" size="lg">
                <ModalBody>
                    <div onClick={() => setModal(false)} className="close cursor-pointer">
                        <Icon name="cross-sm"></Icon>
                    </div>
                    <div className="p-2">
                        <h5 className="title">Add Rental Product</h5>
                        <div className="mt-4">
                            <Form className="row gy-4 mt-4" onSubmit={handleSubmit(handleAddRentalProductForm)}>
                                <Col md="12">
                                    <FormGroup>
                                        <label className="form-label">Dealer Logo <span className='cl-red'>*</span></label>
                                        <ReactImageUploading className="form-control" multiple value={dealerImage} onChange={onDealerImageChange} maxNumber={1} dataURLKey="data_url">
                                            {({ imageList, onImageUpload, onImageRemove, isDragging, dragProps }) => (
                                                <div className="upload__image-wrapper">
                                                    {dealerImage.length === 0 && (
                                                        <Button style={isDragging ? { color: "red" } : undefined} onClick={onImageUpload} color="primary" className="form-bt2 color-black900" {...dragProps}>
                                                            Click or Drop here
                                                        </Button>
                                                    )
                                                    }
                                                    &nbsp;
                                                    <div className="row image-item">
                                                        <Row>
                                                            {imageList.map((image, index) => (
                                                                <Col className="m-2" md="4">
                                                                    <img src={image["data_url"]} alt="" width="120" height="100" />
                                                                    <Button className="mt-1" color='primary' onClick={() => onImageRemove(index)}>
                                                                        Remove
                                                                    </Button>
                                                                </Col>
                                                            ))}
                                                        </Row>
                                                    </div>
                                                </div>
                                            )}
                                        </ReactImageUploading>
                                        {errors.image_gallery && <span className="invalid">{errors.image_gallery.message}</span>}
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup>
                                        <label className="form-label">Title <span className='cl-red'>*</span></label>
                                        <input className="form-control" ref={register({ required: "This field is required" })} onChange={onInputChange} type="text" name="title" />
                                        {errors.title && <span className="invalid">{errors.title.message}</span>}
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup>
                                        <label className="form-label">Content <span className='cl-red'>*</span></label>
                                        <input className="form-control" ref={register({ required: "This field is required" })} onChange={onInputChange} type="text" name="content" />
                                        {errors.content && <span className="invalid">{errors.content.message}</span>}
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup>
                                        <label className="form-label">Price <span className='cl-red'>*</span></label>
                                        <input className="form-control" ref={register({ required: "This field is required" })} onChange={onInputChange} type="text" name="price" />
                                        {errors.price && <span className="invalid">{errors.price.message}</span>}
                                    </FormGroup>
                                </Col>
                                <Col md="12">
                                    <FormGroup>
                                        <label className="form-label">Image Gallery <span className='cl-red'>*</span></label>
                                        <ReactImageUploading className="form-control" multiple value={productImages} onChange={onProductImageChange} maxNumber={10} dataURLKey="data_url"
                                        >
                                            {({ imageList, onImageUpload, onImageRemove, isDragging, dragProps }) => (
                                                <div className="upload__image-wrapper">
                                                    <Button style={isDragging ? { color: "red" } : undefined} onClick={onImageUpload} color="primary" className="form-bt2 color-black900" {...dragProps}>
                                                        Click or Drop here
                                                    </Button>
                                                    &nbsp;
                                                    <div className="row image-item">
                                                        <Row>
                                                            {imageList.map((image, index) => (
                                                                <Col className="m-2" md="4">
                                                                    <img src={image["data_url"]} alt="" width="120" height="100" />
                                                                    <Button className="mt-1" color='primary' onClick={() => onImageRemove(index)}>
                                                                        Remove
                                                                    </Button>
                                                                </Col>
                                                            ))}
                                                        </Row>
                                                    </div>
                                                </div>
                                            )}
                                        </ReactImageUploading>
                                        {errors.image_gallery && <span className="invalid">{errors.image_gallery.message}</span>}
                                    </FormGroup>
                                </Col>
                                <Col md="12">
                                    <FormGroup>
                                        <label className="form-label">Rental Location <span className='cl-red'>*</span></label>
                                        <div className="form-control-wrap">
                                            <RSelect
                                                options={locationOption}
                                                // defaultValue={{ value: "Paid", label: "Paid" }}
                                                onChange={(e) => setFormData({...formData, machine_location: e.value })}
                                            />
                                        </div>
                                    </FormGroup>
                                </Col>

                                <Col md="6">
                                    <FormGroup>
                                        <label className="form-label">Make <span className='cl-red'>*</span></label>
                                        <input className="form-control" ref={register({ required: "This field is required" })} onChange={onInputChange} type="text" name="make" required />
                                        {errors.make && <span className="invalid">{errors.make.message}</span>}
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup>
                                        <label className="form-label">Model <span className='cl-red'>*</span></label>
                                        <input className="form-control" ref={register({ required: "This field is required" })} onChange={onInputChange} type="text" name="model" required />
                                        {errors.model && <span className="invalid">{errors.model.message}</span>}
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup>
                                        <label className="form-label">Machine Type <span className='cl-red'>*</span></label>
                                        <input className="form-control" ref={register({ required: "This field is required" })} onChange={onInputChange} type="text" name="machine_type" required />
                                        {errors.machine_type && <span className="invalid">{errors.machine_type.message}</span>}
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup>
                                        <label className="form-label">Year</label>
                                        <input className="form-control" onChange={onInputChange} type="text" name="year" />
                                        {errors.year && <span className="invalid">{errors.year.message}</span>}
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup>
                                        <label className="form-label">Hours</label>
                                        <input className="form-control" onChange={onInputChange} type="number" name="hours" />
                                        {errors.hours && <span className="invalid">{errors.hours.message}</span>}
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup>
                                        <label className="form-label">Mileage</label>
                                        <input className="form-control" onChange={onInputChange} type="number" name="mileage" />
                                        {errors.mileage && <span className="invalid">{errors.mileage.message}</span>}
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup>
                                        <label className="form-label">Service History</label>
                                        <input className="form-control" onChange={onInputChange} type="text" name="service_history" />
                                        {errors.service_history && <span className="invalid">{errors.service_history.message}</span>}
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup>
                                        <label className="form-label">Serial Number</label>
                                        <input className="form-control" onChange={onInputChange} type="text" name="serial_number" />
                                        {errors.serial_number && <span className="invalid">{errors.serial_number.message}</span>}
                                    </FormGroup>
                                </Col>
                                <Col size="12">
                                    <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                                        <li>
                                            <Button disabled={loading} color="primary" size="md" type="submit">
                                                <span>{loading ? <Spinner size="sm" /> : "Add Rental Product"} </span>
                                            </Button>
                                        </li>
                                        <li>
                                            <div className="link link-light" onClick={() => setModal(false)}>
                                                Cancel
                                            </div>
                                        </li>
                                    </ul>
                                </Col>
                            </Form>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </React.Fragment>
    )
}

export default AddRentalProductModal;