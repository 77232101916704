import React from 'react'

import { Formik } from 'formik';
import { Button, Col, Form, Modal, ModalBody, Spinner } from 'reactstrap';

import { Icon } from '../../components/Component';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { ResetUpdatePackageDetailData, UpdatePackageDetailThunk } from '../../redux/package/UpdatePackageDetailSlice';
import { GetAllPackageThunk } from '../../redux/package/GetAllPackageSlice';

const UpdatePackageStatusModal = ({ modal, setModal, isActive, id }) => {
    const dispatch = useDispatch();
    const { loading, success, error, errorMessage } = useSelector((state) => state.package.updatePackageDetail);

    const handleUpdatePackageStatusForm = () => {
        dispatch(UpdatePackageDetailThunk({ isActive: !isActive, id: id }))
    }

    if (success) {
        toast.success("Package has been updated successfully.");
        dispatch(ResetUpdatePackageDetailData());
        dispatch(GetAllPackageThunk());
        setModal();
    }

    if (error) {
        toast.error(errorMessage);
        dispatch(ResetUpdatePackageDetailData());
        dispatch(GetAllPackageThunk());
        setModal();
    }

    return (
        <React.Fragment>
            <Modal isOpen={modal.updatePackageStatus} toggle={setModal} className="modal-dialog-centered" size="md">
                <ModalBody>
                    <a href="#cancel" className="close" onClick={(ev) => { ev.preventDefault(); setModal(); }}>
                        <Icon name="cross-sm"></Icon>
                    </a>
                    <div className="p-2">
                        <h4 className="title">Update Package Status</h4>
                        <div className="mt-4">
                            <Formik>
                                <Form className="row gy-4 mt-4 mb-5">
                                    <Col md="12">
                                        <div>
                                            <h6>Are you sure you want to update Package status?</h6>
                                        </div>
                                    </Col>
                                    <Col size="12">
                                        <ul className="d-flex justify-content-between flex-wrap flex-sm-nowrap gx-4 gy-2">
                                            <li>
                                                <Button disabled={loading} onClick={handleUpdatePackageStatusForm} color="primary" size="md" type="submit">
                                                    Update
                                                    <span>{loading && <Spinner size="sm" />} </span>
                                                </Button>
                                            </li>
                                            <li>
                                                <a className="link link-light" onClick={(ev) => { ev.preventDefault(); setModal(false); }}
                                                >
                                                    Cancel
                                                </a>
                                            </li>
                                        </ul>
                                    </Col>
                                </Form>
                            </Formik>
                        </div>
                    </div>
                </ModalBody>
            </Modal>

        </React.Fragment>

    )
}

export default UpdatePackageStatusModal