import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiEndpoints } from "../../utils/ApiEndPoints";

const GetAllAdvertisementThunk = createAsyncThunk("GetAllAdvertisement/GetAllAdvertisementThunk", async (_, { rejectWithValue }) => {
    try {
        const response = await axios.get(ApiEndpoints.Advertisement.GetAllAdvertisement,
            {
                headers: {
                    "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
                }
            });
        return response.data;
    } catch (error) {
        console.log('error', error);
        const errorMessage = error.response.data.message ? error.response.data.message : "Something Went Wrong!";
        return rejectWithValue(errorMessage);
    }
});

// const AddAdvertisementThunk = createAsyncThunk("AddAdvertisement/AddAdvertisementThunk", async ({ image, ad_link, web_page_id, order }, { rejectWithValue }) => {
//     const formData = new FormData();
//     formData.append("ad_link", ad_link);
//     formData.append("order", order);
//     formData.append("page_id", web_page_id);
//     for (let i = 0; i < image.length; i++) {
//         formData.append("advertisement", image[i].file);
//     }

//     try {
//         const response = await axios.post(ApiEndpoints.Advertisement.AddAdvertisement,
//             {
//                 headers: {
//                     "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
//                 }
//             });
//         return response.data;
//     } catch (error) {
//         console.log('error', error);
//         const errorMessage = error.response.data.message ? error.response.data.message : "Something Went Wrong!";
//         return rejectWithValue(errorMessage);
//     }
// });

// const UpdateAdvertisementThunk = createAsyncThunk("UpdateAdvertisement/UpdateAdvertisementThunk", async ({ ad_link, web_page_id, image }, { rejectWithValue }) => {
//     console.log('ad_link, web_page_id, image', ad_link, web_page_id, image)
//     const formData = new FormData();
//     formData.append("ad_link", ad_link);
//     formData.append("id", web_page_id);
//     for (let i = 0; i < image.length; i++) {
//         formData.append("products", image[i].file);
//     }
//     try {
//         const response = await axios.put(ApiEndpoints.Advertisement.UpdateAdvertisement,
//             formData,
//             {
//                 headers: {
//                     "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
//                 }
//             });
//         return response.data;
//     } catch (error) {
//         console.log('error', error);
//         const errorMessage = error.response.data.message ? error.response.data.message : "Something Went Wrong!";
//         return rejectWithValue(errorMessage);
//     }
// });


// const DeleteAdvertisementThunk = createAsyncThunk("DeleteAdvertisement/DeleteAdvertisementThunk", async (_, { rejectWithValue }) => {
//     try {
//         const response = await axios.get(ApiEndpoints.Advertisement.GetAllAdvertisement,
//             {
//                 headers: {
//                     "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
//                 }
//             });
//         return response.data;
//     } catch (error) {
//         console.log('error', error);
//         const errorMessage = error.response.data.message ? error.response.data.message : "Something Went Wrong!";
//         return rejectWithValue(errorMessage);
//     }
// });


const AdvertisementSlice = createSlice({
    name: "GetAllAdvertisement",
    initialState: {
        advertisementData: [],
        success: false,
        error: false,
        loading: false,
        errorMessage: "",
    },
    reducers: {
        ResetAdvertisementData: (state) => {
            state.advertisementData = [];
            state.loading = false;
            state.success = false;
            state.error = false;
            state.errorMessage = "";
        },
        ResetAdsTriggers: (state) => {
            state.success = false;
            state.error = false;
            state.errorMessage = "";
        }
    },
    extraReducers: {
        [GetAllAdvertisementThunk.pending]: (state, action) => {
            state.loading = true;
            state.success = false;
            state.error = false;
        },
        [GetAllAdvertisementThunk.fulfilled]: (state, action) => {
            state.advertisementData = action.payload;
            state.success = true;
            state.loading = false;
        },
        [GetAllAdvertisementThunk.rejected]: (state, action) => {
            state.errorMessage = action.payload;
            state.error = true;
            state.loading = false;
            state.advertisementData = [];
        },
    },

});

export default AdvertisementSlice.reducer;

export const { ResetAdvertisementData, ResetAdsTriggers } = AdvertisementSlice.actions;

export { GetAllAdvertisementThunk };
