import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiEndpoints } from "../../utils/ApiEndPoints";

const GetAllRentalCategoryThunk = createAsyncThunk("GetAllRentalCategory/GetAllRentalCategoryThunk", async (_, { rejectWithValue }) => {
    try {
        const response = await axios.get(ApiEndpoints.RentalCategory.GetAllRentalCategory,
            {
                headers: {
                    "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
                }
            });
        return response.data;
    } catch (error) {
        console.log("error", error);
        return rejectWithValue(error.response.data);
    }
});

const GetAllRentalCategorySlice = createSlice({
    name: "GetAllRentalCategory",
    initialState: {
        allRentalCategoryData: [],
        success: false,
        error: false,
        loading: false,
        errorMessage: "",
    },
    reducers: {
        ResetAllRentalCategoryData: (state, action) => {
            state.allRentalCategoryData = [];
            state.success = false;
            state.error = false;
            state.loading = false;
            state.errorMessage = "";
        },
    },
    extraReducers: {
        [GetAllRentalCategoryThunk.pending]: (state, action) => {
            state.loading = true;
            state.success = false;
            state.error = false;
        },
        [GetAllRentalCategoryThunk.fulfilled]: (state, action) => {
            state.allRentalCategoryData = action.payload;
            state.success = true;
            state.loading = false;
        },
        [GetAllRentalCategoryThunk.rejected]: (state, action) => {
            state.errorMessage = action.payload.message;
            state.error = true;
            state.loading = false;
            state.allRentalCategoryData = [];
        },
    },
});

export default GetAllRentalCategorySlice.reducer;

export const { ResetAllRentalCategoryData } = GetAllRentalCategorySlice.actions;

export { GetAllRentalCategoryThunk };
